import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    total: 0,
    page: 1,
    maxPage: 1,
    lineItemsBasedOnStatus: [],
};

const lineItemsBasedOnStatusSlice = createSlice({
    name: 'lineItemsBasedOnStatus',
    initialState: initialState,
    reducers: {
        setLineItemsBasedOnStatus: (state, action) => action.payload,
        reset: (state, action) => initialState,
    },
});

export const { setLineItemsBasedOnStatus, reset } = lineItemsBasedOnStatusSlice.actions;

export default lineItemsBasedOnStatusSlice.reducer;