import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SnackBar from "../../../../components/SnackBar";
import { TextField, Autocomplete } from "@mui/material";
import axiosInstance from "../../../../utility/axios-instance";
import Classes from "../../customers/css/Modal.module.css";
import Spinner from "../../../../components/spinner";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditCompany = (props) => {
  const {
    open,
    closeDialog,
    setReRender,
    companiesDetails,
    setCompaniesDetails,
    companyId,
    reRender,
  } = props;

  const [loading, setLoading] = useState(false);
  const [pincode, setPincode] = useState("");
  const [num, setNum] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = {
      companyName: event?.target?.companyName?.value,
      companyNameInTally: event?.target?.companyNameInTally?.value,
      gstNumber: event.target.gstNumber.value,
      addressLine1: event.target.addressLine1.value,
      addressLine2: event?.target?.addressLine2?.value,
      city: event.target.city.value,
      pincode: event.target.pincode.value,
      state: event.target.state.value,
      country: event?.target?.country?.value,
      phoneNumber: event.target.phoneNumber.value,
      email: event.target.email.value,
      bankAccountName: event.target.bankAccountName.value,
      bankAccountNumber: event.target.bankAccountNumber.value,
      bankName: event.target.bankName.value,
      bankAccountIfsc: event.target.bankAccountIfsc.value,
      pan: event.target.pan.value,
      invoicePrefix: event.target.invoicePrefix.value,
      invoiceInfix: event.target.invoiceInfix.value,
      invoiceNumber: event.target.invoiceNumber.value,
      cin: event?.target?.cin?.value ? event?.target?.cin?.value : undefined,
    };

    axiosInstance
      .post(`/adminRoutes/companies/${companyId}`, { ...data })
      .then((res) => {
        const form = document.getElementById("form");
        form.reset();
        handleClose();
        setReRender(!reRender);
        setLoading(false);
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
      })
      .catch((err) => {
        setLoading(false);
        setSnackBar({
          display: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  const handleClose = () => {
    setNum("");
    setPincode("");
    setGstNo("");
    
    document.getElementById("isValidGstUpdate").style.display = "none";
    document.getElementById("pinErrMsgUpdate").style.display = "none";
    document.getElementById("phoneErrMsgUpdate").style.display = "none";
    document.getElementById("isValidPANUpdate").style.display = "none";
    // document.getElementById('isValidCINUpdate').style.display = 'none';
    const form = document.getElementById('form');
    form.reset();
    closeDialog();
  };

  const handleValidGst = (event) => {
    let regex = new RegExp(
      /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/,
    );

    if (event.target.value == null) {
      return "false";
    }

    if (regex.test(event.target.value) === true) {
      document.getElementById("updateBtn").disabled = false;
      document.getElementById("isValidGstUpdate").style.display = "none";
    } else {
      if (event.target.value === "") {
        document.getElementById("updateBtn").disabled = false;
        document.getElementById("isValidGstUpdate").style.display = "none";
      } else {
        document.getElementById("updateBtn").disabled = true;
        document.getElementById("isValidGstUpdate").style.display = "block";
      }
    }
  };

  const handleValidPAN = (event) => {
    let regex = new RegExp(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/);

    if (event.target.value == null) {
      return "false";
    }

    if (regex.test(event.target.value) === true) {
      document.getElementById("updateBtn").disabled = false;
      document.getElementById("isValidPANUpdate").style.display = "none";
    } else {
      if (event.target.value === "") {
        document.getElementById("updateBtn").disabled = false;
        document.getElementById("isValidPANUpdate").style.display = "none";
      } else {
        document.getElementById("updateBtn").disabled = true;
        document.getElementById("isValidPANUpdate").style.display = "block";
      }
    }
  };

  const preventNegativeValues = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleMinLength = (event) => {
    if (event.target.value) {
      const phoneno = /^\d{10}$/;
      if (event.target.value.match(phoneno)) {
        document.getElementById("phoneErrMsgUpdate").style.display = "none";
        document.getElementById("updateBtn").disabled = false;
      } else {
        document.getElementById("updateBtn").disabled = true;
        document.getElementById("phoneErrMsgUpdate").style.display = "block";
      }
    } else {
      document.getElementById("phoneErrMsgUpdate").style.display = "none";
      document.getElementById("updateBtn").disabled = false;
    }
  };

  const handleMinLengthPinCode = (event) => {
    if (event.target.value) {
      const pincode = /^\d{6}$/;
      if (event.target.value.match(pincode)) {
        document.getElementById("pinErrMsgUpdate").style.display = "none";
        document.getElementById("updateBtn").disabled = false;
      } else {
        document.getElementById("updateBtn").disabled = true;
        document.getElementById("pinErrMsgUpdate").style.display = "block";
      }
    } else {
      document.getElementById("pinErrMsgUpdate").style.display = "none";
      document.getElementById("updateBtn").disabled = false;
    }
  };

  useEffect(() => {
    axiosInstance.get("/pincode/getAllStates").then((response) => {
      setStates(response.data.data);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get("/pincode/getAllCities").then((response) => {
      setCities(response.data.data);
    });
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        sx={{ "& .MuiDialog-paper": { maxWidth: "775px" } }}
      >
        <DialogTitle>Update Company</DialogTitle>
        <form method="post" onSubmit={handleSubmit} id="form">
          <div style={{ padding: 20 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "32%" }}>
                <TextField
                label={"Company Name"}
                placeholder={"Enter company name"}
                required={true}
                id="companyName"
                name={"companyName"}
                key={companiesDetails?.company_name}
                defaultValue={companiesDetails?.company_name}
                autoComplete="off"
                size="small"
                sx={{ width: "100%" }}
              />
              </div>
              <div style={{ width: "32%" }}>
                
                  <TextField
                  required={true}
                  label={"Company Name In Tally"}
                  placeholder={"Enter company Company name"}
                  id="companyNameInTally"
                  name={"companyNameInTally"}
                  autoComplete="off"
                  size="small"
                  sx={{ width: "100%" }}
                  key={companiesDetails?.company_name_in_tally}
                  defaultValue={companiesDetails?.company_name_in_tally}
                />
                </div>
              
              <div style={{ width: "32%" }}>
                
                  <TextField
                  label={"GST"}
                  placeholder={"Enter company GST"}
                  required={true}
                  id="gstNumber"
                  name={"gstNumber"}
                  minLength={15}
                  maxLength={15}
                  inputProps={{ maxLength: 15 }}
                  onBlur={handleValidGst}
                  onChange={(event) =>
                    setCompaniesDetails((prevFormData) => {
                      return {
                        ...prevFormData,
                        gst: event.target.value,
                      };
                    })
                  }
                  value={
                    companiesDetails?.gst_number
                      ? companiesDetails?.gst_number
                      : ""
                  }
                  autoComplete="off"
                  autoFocus="off"
                  sx={{ width: "100%" }}
                  size="small"
                />
                </div>

                <div id="isValidGstUpdate" className={Classes.IsValidGst}>
                  <p>Please fill valid Gst Number</p>
                </div>
            </div>

            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "32%" }}> 
                <TextField
                label={"Email"}
                type="email"
                placeholder={"Enter company email"}
                defaultValue={companiesDetails?.email}
                key={companiesDetails?.email}
                required={true}
                id="email"
                name={"email"}
                autoComplete="off"
                sx={{ width: "100%" }}
                size="small"
              />
              </div>

              <div style={{ width: "32%" }}>
                
                  <TextField
                  label={"PAN No."}
                  placeholder={"Enter company PAN No."}
                  required={true}
                  id="pan"
                  name={"pan"}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  key={companiesDetails?.pan}
                  defaultValue={companiesDetails?.pan}
                  onBlur={(e) => handleValidPAN(e)}
                  minLength={10}
                  maxLength={10}
                  inputProps={{ maxLength: 10 }}
                  size="small"
                />
                
                <div id="isValidPANUpdate" className={Classes.IsValidGst}>
                  <p>Please fill valid PAN Number</p>
                </div>
              </div>

              <div style={{ width: "32%" }}>
                
                  <TextField
                  label={"CIN No."}
                  placeholder={"Enter company CIN No."}
                  defaultValue={companiesDetails?.cin}
                  key={companiesDetails?.cin}
                  id="cin"
                  name={"cin"}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  size="small"
                  minLength={21}
                  maxLength={21}
                  inputProps={{ maxLength: 21 }}
                />
                
              </div>
            </div>

            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >

              <div style={{ width: "32%" }}>
                <TextField
                label={"Address Line 1"}
                placeholder={"Enter company address"}
                required={true}
                id="addressLine1"
                name={"addressLine2"}
                autoComplete="off"
                sx={{ width: "100%" }}
                key={companiesDetails?.address_line1}
                defaultValue={companiesDetails?.address_line1}
                size="small"
              />
              </div>
              <div style={{ width: "32%" }}>
                <TextField
                label={"Address Line 2"}
                placeholder={"Enter company address"}
                required={false}
                id="addressLine2"
                name={"addressLine2"}
                autoComplete="off"
                sx={{ width: "100%" }}
                key={companiesDetails?.address_line2}
                defaultValue={companiesDetails?.address_line2}
                size="small"
              />
              </div>
              <div style={{ width: "32%" }}>
                
                  <TextField
                  type="number"
                  label={"Pincode"}
                  placeholder={"Enter pincode"}
                  required={true}
                  id="pincode"
                  name={"pincode"}
                  value={
                    companiesDetails?.pincode ? companiesDetails?.pincode : ""
                  }
                  onBlur={(e) => handleMinLengthPinCode(e)}
                  onChange={(event) =>
                    setCompaniesDetails((prevFormData) => {
                      return {
                        ...prevFormData,
                        pincode: event.target.value.slice(0, 6),
                      };
                    })
                  }
                  onKeyDown={preventNegativeValues}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  size="small"
                />
                

                <div
                  className={Classes.PhoneErrorMsg}
                  id="pinErrMsgUpdate"
                  style={{ fontSize: 14 }}
                >
                  <p>Please enter a valid 6 digit pincode</p>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >


              <div style={{ width: "32%" }}>
                <Autocomplete
                // freeSolo
                required={true}
                disablePortal
                id="city"
                name="city"
                options={cities}
                key={companiesDetails?.city}
                defaultValue={companiesDetails?.city}
                ListboxProps={{ sx: { fontSize: 14 } }}
                size="small"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="City" required />
                )}
              />
              </div>
              <div style={{ width: "32%" }}>
                <Autocomplete
                // freeSolo
                required={true}
                disablePortal
                id="state"
                name="state"
                options={states}
                key={companiesDetails?.state}
                defaultValue={companiesDetails?.state}
                ListboxProps={{ sx: { fontSize: 14 } }}
                size="small"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="States" required />
                )}
              />
              </div>
              <div style={{ width: "32%" }}>
                
                  <TextField
                  label={"Country"}
                  placeholder={"Enter company country"}
                  required={true}
                  id="country"
                  name={"country"}
                  key={companiesDetails?.country}
                  defaultValue={companiesDetails?.country}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  size="small"
                />
               
              </div>
            </div>
            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div style={{ width: "32%" }}>
                
                  <TextField
                  type="number"
                  label={"Phone Number"}
                  placeholder={"Enter company phone"}
                  required={true}
                  id="phoneNumber"
                  name={"phoneNumber"}
                  maxLength={10}
                  onChange={(event) =>
                    setCompaniesDetails((prevFormData) => {
                      return {
                        ...prevFormData,
                        phone: event.target.value.slice(0, 10),
                      };
                    })
                  }
                  onBlur={(e) => handleMinLength(e)}
                  onKeyDown={preventNegativeValues}
                  value={
                    companiesDetails?.phone_number
                      ? companiesDetails?.phone_number
                      : ""
                  }
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  size="small"
                />
               

                <div className={Classes.PhoneErrorMsg} id="phoneErrMsgUpdate">
                  <p>Please enter valid 10 digit number</p>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 10 }}>
              <p>Bank Details</p>
              <div
                style={{
                  marginTop: 5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "32%" }}>
                  <TextField
                  label={"A/C Holder Name"}
                  placeholder={"Enter A/C Holder Name"}
                  required={true}
                  id="bankAccountName"
                  name={"bankAccountName"}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  size="small"
                  defaultValue={companiesDetails?.bank_account_name}
                  key={companiesDetails?.bank_account_name}
                />
                </div>

                <div style={{ width: "32%" }}>
                  <TextField
                  label={"A/C Number"}
                  placeholder={"Enter A/C Number"}
                  required={true}
                  type="number"
                  id="bankAccountNumber"
                  name={"bankAccountNumber"}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  onKeyDown={preventNegativeValues}
                  size="small"
                  defaultValue={companiesDetails?.bank_account_number}
                  key={companiesDetails?.bank_account_number}
                />
                </div>

                <div style={{ width: "32%" }}>
                  <TextField
                  label={"IFSC Code"}
                  placeholder={"Enter IFSC Code"}
                  required={true}
                  id="bankAccountIfsc"
                  name={"bankAccountIfsc"}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  size="small"
                  defaultValue={companiesDetails?.bank_account_ifsc}
                  key={companiesDetails?.bank_account_ifsc}
                />
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "32%" }}>
                
                  <TextField
                  label={"Bank Name"}
                  placeholder={"Enter Bank Name"}
                  required={true}
                  id="bankName"
                  name={"bankName"}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  size="small"
                  defaultValue={companiesDetails?.bank_name}
                  key={companiesDetails?.bank_name}
                />
                
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <p>Invoice Details</p>
              <span style={{ fontSize: "12px", fontWeight: 500 }}>
                Example : <i>HM-STO</i> (prefix) / <i>24-25</i> (infix) / XXXX
              </span>
              <div
                style={{
                  marginTop: 5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "32%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <TextField
                    label={"Invoice Prefix"}
                    placeholder={"Set Invoice Prefix"}
                    required={true}
                    id="invoicePrefix"
                    name={"invoicePrefix"}
                    autoComplete="off"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={companiesDetails?.invoice_prefix}
                    key={companiesDetails?.invoice_prefix}
                  />
                  </div>
                  <div
                    style={{
                      fontSize: "small",
                      color: "gray",
                      textAlign: "center",
                    }}
                  >
                    <span>
                      <b>Company-PREFIX</b>
                    </span>
                    <span>/</span>
                    <span>Company-INFIX</span>
                    <span>/</span>
                    <span>12</span>
                  </div>
                </div>
                <div
                  style={{
                    width: "32%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <TextField
                    label={"Invoice Infix"}
                    placeholder={"Set Invoice Infix"}
                    required={true}
                    id="invoiceInfix"
                    name={"invoiceInfix"}
                    autoComplete="off"
                    sx={{ width: "100%" }}
                    size="small"
                    defaultValue={companiesDetails?.invoice_infix}
                    key={companiesDetails?.invoice_infix}
                  />
                  </div>
                  <div
                    style={{
                      fontSize: "small",
                      color: "gray",
                      textAlign: "center",
                    }}
                  >
                    <span>Company-PREFIX</span>
                    <span>/</span>
                    <span>
                      <b>Company-INFIX</b>
                    </span>
                    <span>/</span>
                    <span>12</span>
                  </div>
                </div>
                <div
                  style={{
                    width: "32%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <TextField
                    label={"Invoice Number"}
                    required={true}
                    placeholder={"Set invoice number"}
                    id="invoiceNumber"
                    name={"invoiceNumber"}
                    autoComplete="off"
                    sx={{ width: "100%" }}
                    size="small"
                    key={companiesDetails?.invoice_number}
                    defaultValue={companiesDetails?.invoice_number}
                  />
                  </div>
                  <div
                    style={{
                      fontSize: "small",
                      color: "gray",
                      textAlign: "center",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <DialogActions>
            <Button onClick={handleClose}>CLOSE</Button>
            <Button type="submit" id="updateBtn">
              UPDATE
            </Button>
          </DialogActions>
        </form>

        {loading ? <Spinner /> : null}
      </Dialog>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </div>
  );
};

export default EditCompany;
