import React from 'react';

interface SerialNumberProps {
    serialNumber: number;
}

const SerialNumber: React.FC<SerialNumberProps> = ({ serialNumber }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: '#f8f9fa',
                color: '#000',
                border: '1px solid #ced4da',
                fontSize: '0.8rem',
            }}
        >
            {serialNumber}
        </div>
    );
};

export default SerialNumber;
