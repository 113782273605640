import React, { Fragment, useState } from 'react';
import Table from '../../../components/table/table';
import SnackBar from '../../../components/SnackBar';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import Classes2 from '../dashboard/css/dashboard.module.css'

const InventoryAnalytics = () => {
    const getFormattedDate = (date) => `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    const [startDate, setStartDate] = useState(() => getFormattedDate(new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)));// set last 15 days date by default
    const [endDate, setEndDate] = useState(() => getFormattedDate(new Date()));
    const [data, setData] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [reRender] = useState(false);

    const columns = [
        {
            field: 'sku_code',
            headerName: 'SKU Code',
            flex:1.5,
            headerClassName: 'super-app-theme--header',
            filterable : true,
        },
        {
            field: 'product_name',
            headerName: 'Product Name',
            flex:2,
            headerClassName: 'super-app-theme--header',
            filterable : true,
        },
        { 
            field: 'brand_name', 
            headerName: 'Brand Name', 
            flex:1,
            headerClassName: 'super-app-theme--header',
            filterable : true,
            type: 'enum',
            tableName: 'product_collections',
            columnName: 'brand_name',
        },
        { 
            field: 'locationCode', 
            headerName: 'Location Code', 
            flex:1,
            headerClassName: 'super-app-theme--header',
            filterable : true,
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
        },
        { 
            field: 'quantity', 
            headerName: 'Quantity', 
            flex:1,
            headerClassName: 'super-app-theme--header',
            filterable : false,
        },
        { 
            field: 'avgSellingPrice', 
            headerName: 'Average Selling Price', 
            flex:1,
            headerClassName: 'super-app-theme--header',
            filterable : false,
            renderCell: (params) => {
                return '₹' + (+params.value).toFixed(2)
            }
        },
        { 
            field: 'avgPurchasePrice', 
            headerName: 'Average Purchase Price', 
            flex:1,
            headerClassName: 'super-app-theme--header',
            filterable : false,
            renderCell: (params) => {
                return '₹' + (+params.value).toFixed(2)
            }
        }
    ];

    function DateRange () {
        return (
            <div className={Classes2.DatePickerContainer} style={{ justifyContent:'normal', gap:'10px', width:'auto' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Start Date" 
                        sx={{ 
                                "& .MuiOutlinedInput-input": {padding: "9.5px 8px",width: '105px',fontSize:'14px'},
                                "& .MuiSvgIcon-root": { fontSize:"18px" },
                                "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                                "& .Mui-error": { color:'#00000099 !important' }
                        }}
                        inputFormat="YYYY-MM-DD"
                        size="small"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(startDate)}
                        value={dayjs(startDate)}
                        onChange={(newValue) => {
                            setStartDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        }} 
                    />
                    <DatePicker 
                        label="End Date"
                        sx={{ 
                            "& .MuiOutlinedInput-input": {padding: "9.5px 8px",width: '105px',fontSize:'14px'},
                            "& .MuiSvgIcon-root": { fontSize:"18px" },
                            "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                            "& .Mui-error": { color:'#00000099 !important' }
                        }}
                        inputFormat="YYYY-MM-DD"
                        size="small"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(endDate)}
                        value={dayjs(endDate)}
                        onChange={(newValue) => {
                            setEndDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        }} 
                    />
                </LocalizationProvider>
            </div>
        )
    }

    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '8px', marginTop:-30 }}>
                {DateRange()}
            </div>

            <div>
                <Table 
                    columns={columns} 
                    dataPosition={"inventories"} 
                    endpoint={`/inventory/getInventoriesAnalytics`} 
                    rowId={'inventory_id'}
                    checkBoxSelection={false}
                    tabName={'Inventory Analytics'}
                    setData={setData}
                    data={data}
                    createdByFilter={false}
                    isReactDispatch={true}
                    reRender={reRender}
                    startDateProp={startDate}
                    endDateProp={endDate}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/fetchInventoryAnalytics'}
                    exportFileName={`Inventory Analytics Report- ${new Date()}`}
                />
            </div>
        </Fragment>
    )
}
export default InventoryAnalytics;