import { useState } from 'react';
import Table from '../../../../components/table/table'
import { useSelector } from 'react-redux'
import axiosInstance from '../../../../utility/axios-instance'
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { setNotifications } from '../../../../store/myNotificationSlice';
import socket from '../../../../socket';
import SnackBar from '../../../../components/SnackBar';

function MyNotification() {
    const auth = useSelector((state) => state.auth);
    const notifications = useSelector((state) => state.myNotifications);

    const user = auth.user;
    const navigate = useNavigate();
    const columnVisibilityModel = {
        seen: false,
        created_by: false,
        type: false,
    }

    const handleRedirect = async (redirectTab,id) => {
        try{
            navigate(redirectTab);
            await axiosInstance.put(`/adminNotifications/${id}`);
        }
        catch(err){
            console.log("Error in updating notification", err);
        }
        finally{
            socket?.emit("New Notification", user);
        }
    }

    const columns = [
        {
            field: `id`,
            headerName: `Status`,
            flex: .3,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell : (params) => <div>
                    {params?.row?.isRead ? <DraftsOutlinedIcon style={{color:'lightgrey'}}/> :<MarkEmailUnreadOutlinedIcon style={{color: 'gray'}}/> }
                </div>
        },
        {
            field: `content`,
            headerName: `Content`,
            flex: 3,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell : (params) => <div>
                <div style={{fontWeight : params?.row?.isRead ? '200' : '700', fontSize: '14px', color : params?.row?.isRead ? 'lightgrey' :'grey' }}>{params?.row?.content}</div>
            </div>
        },
        {
            field: `sub_content`,
            headerName: `Sub Content`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell : (params) => <div style={{fontWeight : '600', fontSize: '14px', color : params?.row?.isRead ? 'lightgrey' : 'grey'}}>{params?.row?.sub_content}</div>
        },
        {
            field: `location_code`,
            headerName: `Location Code`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `type`,
            headerName: `TYPE`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
            enumValues: ['Estimate', 'Stock Adjustment IN'],
        },
        {
            field: `redirection_link`,
            headerName: `Redirect`,
            flex: .4,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
            renderCell : (params) => <div >
                <Button onClick={() => handleRedirect(params?.row?.redirection_link, params?.row?.id)} startIcon={<LaunchIcon />}></Button>
            </div>
        },
        {
            field: `seen`,
            headerName: `Seen Status`,
            flex: .6,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            enumValues: ['true', 'false'],
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `created_by`,
            headerName: `Created By`,
            flex: .8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: `created_at`,
            headerName: `Created At`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: false,
            filterable: false,
        },
    ];

    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });

    return (
        <div style={{marginTop: '20px'}}>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

            {user && <div>
                <Table
                    rowId={'id'} 
                    columns={columns} 
                    dataPosition={"notifications"} 
                    endpoint={`/adminNotifications`}
                    checkBoxSelection={false} 
                    service={'myNotification'}
                    tabName={'My Notification'}
                    setData={setNotifications}
                    data={notifications}
                    columnVisibilityModel={columnVisibilityModel}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/fetchNotifications'}
                    exportFileName={`Notifications Report - ${new Date()}`}
                />
            </div>}
        </div>
    )
}

export default MyNotification