import { useEffect, useState } from 'react';
import axiosInstance from '../../utility/axios-instance';
import SnackBar from '../SnackBar';
import Classes from '../mobileResponsive/style.module.css';
import Spinner from '../spinner';
import NewPagination from '../../components/newPagination';
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';
import { downloadCSV } from '../../utility/downloadCSV';
import FilterListIcon from '@mui/icons-material/FilterList';
import DialogSlide from './mobileResponsiveFilter';
import Checkbox from '@mui/material/Checkbox';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
)

const SearchInput = ({ searchFor, handleSearch }) => {
    return (
        <div style={{ width: '100%' }}>
            <TextField
                id="search"
                type="search"
                label="Search"
                value={searchFor}
                autoFocus={true}
                onChange={(e) => handleSearch(e)}
                autoComplete='off'
                sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

const nestedFieldName = ['createdBy', 'preSalesUser', 'salesUser'];
const dateColumns = ["created_at", "createdAt", "updatedAt", "updated_at"];

const columnData = (row, RenderCell)=> {
    return (
        <RenderCell {...row}/>
    )
}

const RenderData = (row, column) => {
    return (
        <>
            <p style={{marginRight:5,color:'#6c757d'}}>{column?.headerName}:</p>
            <p style={{lineBreak:'anywhere'}}>
                {column?.customRenderCell ? columnData(row, column?.customRenderCell) :
                dateColumns.includes(column?.field) ?  
                moment(row[column?.field]).format('MMM DD, YYYY h:mm:ss A') : 
                column?.field === 'total' ? `₹${row[column?.field]}`: 
                row[column?.field] ? row[nestedFieldName.includes(column?.field)? 
                column?.field : column?.field === 'services' ? 
                row[column?.field]?.map((service) => (service?.service)) : column?.field] : ''}
            </p>
        </>
    )
};

const DocsHandler = ({selectedOrderName, exportDocType,  handleSelectDocType, handleDownloadBulkDocs}) => {
    return (
        <div style={{display:'flex',marginTop: 10}}>
            <Autocomplete
                id="multiple-limit-tags"
                size="small"
                disablePortal
                options={["PI", "OrderReceipt", "Picklist", "Shipment", "Fulfillment Invoice", "All Docs"]}
                onChange={(e, newValue) => handleSelectDocType(newValue)}
                value={exportDocType}
                disabled={selectedOrderName?.length === 0 ? true : false}
                sx={{width:'225px'}}
                renderInput={(params) => (
                    <TextField {...params} label="Document Type" variant="outlined" />
                )}
            />
           <Button
                type="submit"
                id='deleteBtn'
                onClick={handleDownloadBulkDocs}
                disabled={selectedOrderName?.length === 0 && !exportDocType ? true : false}
                variant="outlined"
                size='small'
                style={{
                    textTransform:'capitalize', margin:'auto', marginRight: 10, 
                    border: selectedOrderName?.length !== 0 && exportDocType ? "1px solid #ed1c24" : "1px solid #0000001f",
                    boxShadow: selectedOrderName?.length !== 0 && exportDocType ? "0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)" : '',
                    padding: '0px 8px', height:'40px', width:'122px' 
                }}
                sx={{ "& .MuiButton-startIcon>*:nth-of-type(1)":{fontSize:"15px",marginTop:"-3px" }}}
            >
                <SimCardDownloadIcon style={{color: selectedOrderName?.length !== 0 && exportDocType ? '#ED1C24' : '#00000042'}} />
                <span style={{
                    color: selectedOrderName?.length !== 0 && exportDocType ? '#ED1C24' : '#00000042', marginTop:4, marginLeft:5 
                }}>Export Docs</span>
            </Button>
        </div>
    )
};

const MobileResponsiveTable = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: true, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const [open, setOpen] = useState(false);
    const [applyFilter,setApplyFilter] = useState(false);
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [selectedOrderName, setSelectedOrderName] = useState([]);
    const [exportDocType, setExportDocType] = useState('');

    const navigate = useNavigate();
    const {
      dataPosition,
      endpoint,
      rowId,
      columns,
      clickable,
      tab,
      exportEndpoint,
      exportFileName,
      delayedExport,
      redirectUrlOnSnackBarClick,
      startDateProp,
      endDateProp,
      initialFilterState,
      tabName
    } = props;

    const [multiFilters, setMultiFilters] = useState(initialFilterState?.items || [{
        field: '', operator: 'contains', value: ''
    }]);

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleApplyFilter = () => {
        setApplyFilter(true);
        setOpen(false);
        fetchData();
    }
  
    const handleRemoveAllFilter = () => {
        multiFilters?.splice(0, multiFilters?.length);
        setMultiFilters([{ field: '', operator: 'contains', value: '' }])
        setApplyFilter(false);
        setOpen(false);
        fetchData();
    }

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const handleFilteredExport = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if(!delayedExport){
            setLoading(true);
        }

        const params = {
            searchFor: searchFor || '',
        }
        if (startDateProp) {
            params.startDate = startDateProp
        }

        if (endDateProp) {
            params.endDate = endDateProp
        }

        let multiFiltersArray = multiFilters?.filter((item) => item?.value)
        let endDateData = endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : new Date().toJSON().slice(0,10).replace(/-/g,'-');
        if (multiFiltersArray?.some((item) => item.operator === 'between' && startDate && endDateData)) {
            let currentIndex = multiFiltersArray.findIndex((item) => item.operator === 'between');
            multiFiltersArray[currentIndex] = {
                ...multiFiltersArray[currentIndex],
                startDate: `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`,
                endDate: endDateData,
                value: ''
            }
        }

         if (multiFiltersArray?.[0]?.value === 0 && multiFiltersArray?.[0]?.field) {
            params.filter = multiFiltersArray
        }

        axiosInstance
            .get(exportEndpoint, {
                params
            })
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data?.exportedData
                const responseMessage = result?.data?.message
                downloadCSV(arrayOfRowObjects, exportFileName || `Export - ${new Date()}`);
                const successMessage = responseMessage || "Exported Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
        setSnackBar((prevSnackBar) => {
            return {
                ...prevSnackBar,
                display: true,
                message:
                `${exportFileName} Report is being generated. Click here to check it's status.`,
                type: "success",
                redirectUrl: redirectUrlOnSnackBarClick,
            };
        });
    };

    const fetchData = async (page) => {
        setLoading(true)
        
        let params = { searchFor, page, text: searchFor };
        if (startDateProp) {
            params.startDate = startDateProp
        }

        if (endDateProp) {
            params.endDate = endDateProp
        }

        let multiFiltersArray = multiFilters?.filter((item) => item?.value);
        let endDateData = endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : new Date().toJSON().slice(0,10).replace(/-/g,'-');
        if (multiFiltersArray?.some((item) => item.operator === 'between' && startDate && endDateData)) {
            let currentIndex = multiFiltersArray.findIndex((item) => item.operator === 'between');
            multiFiltersArray[currentIndex] = {
                ...multiFiltersArray[currentIndex],
                startDate: `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`,
                endDate: endDateData,
                value: ''
            }
        }

        params.filter = multiFiltersArray

        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data;
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor]);

    const handleAddFilter = () => {
        setMultiFilters([...multiFilters, {field: '', value: '', operator: 'contains'} ])
    }

    const handleRemoveFilter = (e, index) => {
        if (multiFilters?.length > 1) {
            const filters = multiFilters?.filter((item) => item?.value);
            const removeFilterColumn = multiFilters?.filter((item) => item?.field !== filters[index]?.field);
            setMultiFilters([...removeFilterColumn, {field: '', value: '', operator: 'contains'}]);
        }
    }

    const handleSelectDocType = (value) => {
        setExportDocType(value)
    }

    const handleCheckBox = (orderName) => {
        setSelectedOrderName([...selectedOrderName, orderName])
    }

    const handleDownloadBulkDocs = (event) => {
        setLoading(true);
        event.preventDefault();
        const currentTimeStamp = () => moment(new Date()).format('YYYY-MM-DD_HH:MM:SSS');
        const newReportName = 'Order Pdf Documents';
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        let params = {
            uploadFileName : fileName,
            reportName : newReportName,
            docType: exportDocType,
            searchFor: searchFor ? searchFor?.trim() : '',
            filter: multiFilters?.length !== 0 ? multiFilters : undefined,
        }


        params['filterName'] = multiFilters?.[0]?.value ? multiFilters : undefined;
        params['selectedOrderName'] = selectedOrderName?.length !== 10 ? selectedOrderName : [];

        axiosInstance
            .get('/pdf/orders/docsList', { params })
            .then((response) => {
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: response?.data?.message, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
    }

    return (
        <div>
            <div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    {
                        exportEndpoint &&
                        <Button
                            color='primary'
                            size='small'
                            startIcon={<ExportIcon />}
                            onClick={(event) => handleFilteredExport(event)}
                            sx={{ marginBottom: '1em' }}
                        >
                            Filtered Export
                        </Button>
                    }
                    <Button
                        color='primary'
                        size='small'
                        sx={{ marginBottom: '1em' }}
                        onClick={handleClickOpen}
                    >
                        {applyFilter && (
                            <span 
                                style={{ 
                                    display:'flex', position:'absolute', height:20, zIndex:1,
                                    backgroundColor: "rgb(25, 118, 210)", color: "rgb(255, 255, 255)",
                                    top: 8, right: 0, transform: "scale(1) translate(50%, -50%)",
                                    transformOrigin: "100% 0%", padding: "0px 6px",
                                    borderRadius: 10, transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    fontSize: '12px', marginRight: 75
                                }}
                            >{multiFilters?.filter((item) => item?.value)?.length}</span>
                        )}
                        <FilterListIcon style={{ marginRight:'8px'}} /> Filter
                    </Button>
                </div>
                
                <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

                {tabName === 'Docs List' && (
                    <DocsHandler 
                        selectedOrderName={selectedOrderName} 
                        exportDocType={exportDocType} 
                        handleSelectDocType={handleSelectDocType} 
                        handleDownloadBulkDocs={handleDownloadBulkDocs}
                    />
                )}


                {data?.length !== 0 && data?.map((row) => (
                    <div key={row[rowId]} className={Classes.Box} onClick={() => clickable &&  navigate(`${tab}/${row[rowId]}`)}>
                        {columns?.length !== 0 && columns?.map((column,index) => (
                            <div style={{display:'flex', marginBottom:5}} key={index}>
                                {tabName === 'Docs List' && column?.headerName === 'ORDER' ? (
                                    <div style={{display:'flex', justifyContent: 'space-between', width:'100%'}}>
                                        <div style={{display:'flex'}}>{RenderData(row, column)}</div>
                                        <div><Checkbox style={{padding:0}} onChange={() => handleCheckBox(row[column?.field])} /></div>
                                    </div>
                                ) : (
                                    <>{RenderData(row, column)}</>
                                )}
                                
                            </div>
                        ))}
                    </div>
                ))}

                <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                    <NewPagination
                        totalPage={paginationData}
                        fetchData={fetchData}
                    />
                </div>

                {loading && <Spinner />}

                <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
            </div>

            <DialogSlide 
                open={open}
                handleRemoveAllFilter={handleRemoveAllFilter}
                columns={columns}
                multiFilters={multiFilters} 
                filters={multiFilters?.[multiFilters?.length - 1]}
                setMultiFilters={setMultiFilters}
                handleApplyFilter={handleApplyFilter}
                handleAddFilter={handleAddFilter}
                handleRemoveFilter={handleRemoveFilter}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
        </div>
    )
};

export default MobileResponsiveTable;