import React, { Fragment } from 'react';
import Table from '../../../components/table/table';
import Classes from '../customers/css/customer.module.css';
import { useSelector } from 'react-redux';
import { setOrderAllocations } from '../../../store/orderAllocationsSlice';
import { Tooltip } from '@mui/material';

const columnVisibilityModel = {
    order_id: false,
    // created_at: false,
    updated_at: false,
}

const colorizeRowData = (data, condition) => (
    <Tooltip title={condition ? "Child LineItem" : ""} placement="top">
        <div style={{color: condition === true ? "#ff6200" : '#000'}}>{data}</div>
    </Tooltip>)
;
const columns = [
    {
        field: 'order_id', 
        headerName: 'Order Id', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => colorizeRowData(params?.row?.order_id, params?.row?.parentSKU !== null)
    },
    {
        field: 'order_name', 
        headerName: 'Order Name', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.order_name, params?.row?.parentSKU !== null)
    },
    {
        field: 'sku',
        headerName: 'SKU',
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.sku, params?.row?.parentSKU !== null)
    },
    { 
        field: 'bin_code', 
        headerName: 'Bin Code', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.bin_code, params?.row?.parentSKU !== null)
    },
    {
        field: 'location_code',
        headerName: 'Location Code',
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.location_code, params?.row?.parentSKU !== null)
    },
    {
        field: 'mode',
        headerName: 'Mode',
        flex:0.6,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.mode, params?.row?.parentSKU !== null)
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.created_by, params?.row?.parentSKU !== null)
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        flex:1.4,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.created_at, params?.row?.parentSKU !== null)
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.updated_at, params?.row?.parentSKU !== null)
    },
    {
        field: 'quantity',
        headerName: 'Quantity',
        type : 'number',
        flex:1,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.quantity, params?.row?.parentSKU !== null)
    }
];

const OrderAllocated = (props) => {
    const orderAllocations = useSelector((state) => state.orderAllocations);

    return (
        <Fragment>
            <div className={Classes.MuiTable}>
                <Table 
                    columns={columns} 
                    dataPosition={"fetchedOrderAllocations"} 
                    endpoint={`/orderAllocation/fetchOrderAllocations`} 
                    rowId={'id'}
                    checkBoxSelection={false}
                    version={"version-2"}
                    columnVisibilityModel={columnVisibilityModel}
                    service={'orderAllocations'}
                    tabName={'Order Allocations'}
                    setData={setOrderAllocations}
                    data={orderAllocations}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/fetchOrderAllocations'}
                    exportFileName={`Order Allocations Report- ${new Date()}`}
                />
            </div>
        </Fragment>
    )
}
export default OrderAllocated;