import axiosInstance from '../../utility/axios-instance';

// show TaxRate Popup
export const handleTaxRatesHelper = (setTaxRates) => {
    setTaxRates(true);
};

// hide TaxRate Popup
export const hideTaxPopupHelper = (setTaxRates) => {
    setTaxRates(false);
};

// show discount popup
export const showDiscountPopupHelper = (setDiscountModal) => {
    setDiscountModal(true);
};

// hide Discount popup
export const hideDiscountPopupHelper = (setDiscountModal) => {
    setDiscountModal(false);
};

export const shippingPopupHelper = (event,setShippingModal) => {
    setShippingModal(true);
};

export const hideShippingPopupHelper = (setPreviousTarget,setShippingModal) => {
    setPreviousTarget([])
    setShippingModal(false);
};

export const handleOutlineHelper = () => {
    document.getElementById('outline').style.border = '2px solid #458fff';
};

// remove product from array
export const updateArrHelper = (
    Id,productDetails,notExistProductShopifyId,setProductNotExistShopifyId,
    setProductNotExistShopify,setProductId,setProductDetails,setAddToggle,
    setVariantRole,variantRole,setProduct,setOverAllDiscount,setShippingPrice,
    setPreviousTarget
) => {
    let arr = productDetails;
    let notExistProductOnShopify = notExistProductShopifyId;
    let storeArray = notExistProductOnShopify.filter((ele) => ele.Id !== Id);
    setProductNotExistShopifyId(storeArray)
    if (storeArray.length === 0) {
        setProductNotExistShopify(false);
    }
    let store = arr.filter((ele) => ele.variantId !== Id);
    if (store.length !== 0) {
        setProductId(store);
        setProductDetails(store);
        setAddToggle(true)
        setVariantRole(variantRole.filter((role) => role.id !== Id));
    } else {
        setProductId(store);
        setProductDetails(store);
        setProduct(store);
        handleDisableSelectHelper();
        setOverAllDiscount([]);
        setShippingPrice([]);
        setPreviousTarget([]);
        setVariantRole(variantRole.filter((role) => role.id !== Id));
    }
};

export const updateArrHelperPo = (
    Id,productDetails,notExistProductShopifyId,setProductNotExistShopifyId,
    setProductNotExistShopify,setProductId,setProductDetails,setAddToggle,
    setVariantRole,variantRole,setProduct,setOverAllDiscount,setShippingPrice,
    setPreviousTarget
) => {
    let arr = productDetails;
    let notExistProductOnShopify = notExistProductShopifyId;
    let storeArray = notExistProductOnShopify.filter((ele) => ele.Id !== Id);
    setProductNotExistShopifyId(storeArray)
    if (storeArray.length === 0) {
        setProductNotExistShopify(false);
    }
    let store = arr.filter((ele) => ele.variantId !== Id);
    if (store.length !== 0) {
        setProductId(store);
        setProductDetails(store);
        setAddToggle(true)
        setVariantRole(variantRole.filter((role) => role.id !== Id));
    } else {
        setProductId(store);
        setProductDetails(store);
        setProduct(store);
        handleDisableSelectHelper();
        setOverAllDiscount([]);
        setShippingPrice([]);
        setPreviousTarget([]);
        setVariantRole(variantRole.filter((role) => role.id !== Id));
    }
};

// increase qty from product
export const incQtyHelper = (
    event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,
    setProductId,setProductDetails
) => {
    const draftOrder = document.getElementById("draftOrderBtn");
    if (draftOrder !== null) {
      draftOrder.disabled = false;
    }
    const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
    if (interWhDraftOrder !== null) {
        interWhDraftOrder.disabled = false;
    }
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.variantId === Id) {
            let variant = document.getElementById(ele.variantId)
            variant.children[0].children[1].lastChild.style.display = 'none';
            if ((ele.PCS + 1) === parseInt(bulkPacking1)) {
                ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 + 1 : 1;
                ele.PCS = 0;
            } else if ((ele.PCS + 1) === parseInt(bulkPacking2)) {
                ele.bulkPackingValue2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 + 1 : 1;
                ele.PCS = 0;
            } else {
                ele.PCS = ele.PCS + 1;
            }   
            ele.quantity = ele.quantity + 1;
        }
    }
    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

export const incQtyHelperPoEdit = (
    event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,
    setProductId,setProductDetails
) => {
    const draftOrder = document.getElementById("draftOrderBtn");
    if (draftOrder !== null) {
      draftOrder.disabled = false;
    }
    const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
    if (interWhDraftOrder !== null) {
        interWhDraftOrder.disabled = false;
    }
    for (let productDetail of productDetails) {
        const baseUnit = "PCS";
        if (productDetail.productId === Id) {
            let variant = document.getElementById(productDetail?.productId)
            variant.children[0].children[1].lastChild.style.display = 'none';
            productDetail.quantity = productDetail.quantity + 1;
        }
    }
    setAddToggle(true)
    setProductId(productDetails);
    setProductDetails([...productDetails]);
};


// decrease qty from product
export const decQtyHelper = (
    event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,setProductId,
    setProductDetails
) => {
    for (let productDetail of productDetails) {
        if (productDetail?.productId === Id) {
            productDetail.quantity = productDetail?.quantity - 1;
        }
    }
    setAddToggle(true)
    setProductId(productDetails);
    setProductDetails([...productDetails]);
};

export const decQtyHelperPoEdit = (
    event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,setProductId,
    setProductDetails
) => {
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.productId === Id) {
            ele.quantity = ele.quantity - 1;
        }
    }
    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

// Handle Manually Percentage
export const handleEditPercentageHelper = (event) => {
    if (event.target.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
        return;
    }
};

// Handle Blur Percentage
export const handleBlurPercentageHelper = (
    event, Id, MRP, GST, originalUnitPrice,productDetails,
    updateDiscountPercent,setUpdateDiscountPercent,setAddToggle,setProductId,
    setProductDetails
) => {
    const value = parseInt(event.target.value, 10);
    // If the value is negative
    if (value < 0) {
        alert('Please enter valid percentage');
        event.target.value = ''
        return;
    }
    
    const limitDiscount = event.target.parentNode.parentNode.parentNode.lastChild;
    if (event.target.value) {
        if (event.target.value <= 100) {
            // document.getElementById('discountPercentRestrict').style.display = 'none';
            const discountPercent = limitDiscount.lastChild.children;
            const percentage = parseFloat(event.target.value);
            if (parseFloat(discountPercent[1].innerText) <= percentage) {
                limitDiscount.style.display = 'none';
                const mrp = parseFloat(MRP);
                const gst = parseInt(GST);
                const discountAmount = -1 * (((percentage - 100) * mrp) / (100 * (1 + (gst / 100))))
                const checkDecimal = countPlacesHelper(discountAmount)
                const result = checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2 ? discountAmount : Math.trunc(discountAmount * 100) / 100;

                let discount = {
                    appliedDiscount: {
                        value: Number(event.target.value),
                        valueType: 'PERCENTAGE',
                        title: 'MANUAL',
                    }
                }

                const rateInclGST = result * (1 + (gst / 100))

                const checkDecimalPlaces = countPlacesHelper(rateInclGST);
                const InclGstPrice = checkDecimalPlaces === 1 || checkDecimalPlaces === 0 || checkDecimalPlaces === 2 ? rateInclGST : Math.trunc(rateInclGST * 100) / 100

                let arr = productDetails;
                for (let ele of arr) {
                    if (ele.productId === Id) {
                        ele.rateInclGST = InclGstPrice;
                        Object.assign(ele, discount);
                    }
                }

                if (updateDiscountPercent !== undefined) {
                    if (updateDiscountPercent.length !== 0) {
                        for (let element of updateDiscountPercent) {
                            if (element !== Id) {
                                const array = [element, Id]
                                setUpdateDiscountPercent([...array])
                            }
                        }
                    } else {
                        setUpdateDiscountPercent([Id]);
                    }
                }

                setTimeout(() => {
                    event.target.value = '';
                }, 1500)

                setAddToggle(true);
                setProductId(arr);
                setProductDetails([...arr]);

            } else {
                limitDiscount.style.display = 'block';
            }
        } else {
            // document.getElementById('discountPercentRestrict').style.display = 'block';
            document.getElementById(event.target.id).value ='';
            alert('Discount Must be Smaller Than 100%')
        }
    } else {
        limitDiscount.style.display = 'none';
    }

};
const preventValueWithoutDecimal = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const handleEditHelper = (event) => {
    if (document.activeElement.isContentEditable) {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
            return;
        } else {
            preventValueWithoutDecimal(event)
        }
    }
};

// handle Blur Rate(Price)
export const handleBlurHelper = (
    event, Id, previousUnit, MRP,productDetails,setAddToggle,setProductId,
    setProductDetails
) => {
    const userAmount = JSON.parse(JSON.stringify(event.target.innerText));
    if (+userAmount > +MRP) {
        alert('Please enter valid price');
        event.target.innerText = previousUnit
        return;
    } 
    const discountAmount = MRP - userAmount;
    const discount = {
        appliedDiscount: {
            value: discountAmount,
            valueType: 'FIXED_AMOUNT',
            title: 'MANUAL'
        },
    };
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.variantId === Id) {
            Object.assign(ele, discount);
        }
    }
    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

export const handleBlurHelperPoEdit = (
    event, Id, previousUnit, MRP,productDetails,setAddToggle,setProductId,
    setProductDetails
) => {
    const userAmount = JSON.parse(JSON.stringify(event.target.innerText));
    if (+userAmount > +MRP) {
        alert('Please enter valid price');
        event.target.innerText = previousUnit
        return;
    } 
    const discountAmount = MRP - userAmount;
    const discount = {
        appliedDiscount: {
            value: discountAmount,
            valueType: 'FIXED_AMOUNT',
            title: 'MANUAL'
        },
    };
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.productId === Id) {
            Object.assign(ele, discount);
        }
    }
    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

export const preventNegativeValues = (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

// OnQty Blur Handled
export const handleKeyUpHelper = (event) => {
    //key code for enter
    if (event.key === "ArrowDown" && event.target.value <= 0) {
        event.preventDefault()
    } else {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        } else {
            preventNegativeValues(event)
        }
    }
}

export const handleBlurBulkPackagingQty1Helper = (
    event, Id, bulkPackagingValue, type, bulkPackagingValue2, restrictMsgId,
    productDetails, setAddToggle, setProductId, setProductDetails
) => {
    if (event === '' || event === '0') {
        document.getElementById(restrictMsgId).style.display = 'none';
        let arr = productDetails;
        for (let ele of arr) {
            if (ele.variantId === Id) {
                let packing1 = bulkPackagingValue2 !== undefined && ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPackagingValue2 : 0;
                ele.quantity = ele.PCS + 0 + packing1;
                ele.bulkPackingValue1 = 0;
            }
        }

        setAddToggle(true)
        setProductId(arr);
        setProductDetails([...arr]);
    } else {
        if (event) {
            if (event < 0) {
                document.getElementById(restrictMsgId).style.display = 'block';
                const createOrder = document.getElementById('createOrderBtn')
                if (createOrder !== null) {
                    createOrder.disabled = true;
                }
                const draftOrder = document.getElementById("draftOrderBtn");
                if (draftOrder !== null) {
                  draftOrder.disabled = true;
                }
                const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
                if (interWhDraftOrder !== null) {
                    interWhDraftOrder.disabled = false;
                }
            } else {
                document.getElementById(restrictMsgId).style.display = 'none';
                const pro = JSON.parse(event);
                let arr = productDetails;
                for (let ele of arr) {
                    if (ele.variantId === Id) {
                        if (ele.bulkPackingValue1 !== pro) {
                            let packing2 = bulkPackagingValue2 !== undefined && ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPackagingValue2 : 0;
                            ele.quantity = ele.PCS + pro * bulkPackagingValue + packing2;
                            ele.bulkPackingValue1 = pro;
                            if (pro > 0) {
                                let variant = document.getElementById(ele.variantId)
                                variant.children[0].children[1].lastChild.style.display = 'none';
                                const createOrder = document.getElementById('createOrderBtn')
                                if (createOrder !== null) {
                                    createOrder.disabled = false;
                                }
                                const draftOrder = document.getElementById("draftOrderBtn");
                                if (draftOrder !== null) {
                                  draftOrder.disabled = false;
                                }
                                const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
                                if (interWhDraftOrder !== null) {
                                    interWhDraftOrder.disabled = false;
                                }
                            }
                        } 
                    }
                }

                setAddToggle(true);
                setProductId(arr);
                setProductDetails([...arr]);
            }
        }
    }
};

export const handleBlurBulkPackagingQty2Helper = (
    event, Id, bulkPackagingValue, type, bulkPackagingValue1, restrictMsgId,
    productDetails, setAddToggle, setProductId, setProductDetails
) => {
    if (event === '' || event === '0') {
        document.getElementById(restrictMsgId).style.display = 'none';
        let arr = productDetails;
        for (let ele of arr) {
            if (ele.variantId === Id) {
                let packing1 = bulkPackagingValue1 !== undefined && ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPackagingValue1 : 0;
                ele.quantity = ele.PCS + 0 + packing1;
                ele.bulkPackingValue2 = 0;
            }
        }

        setAddToggle(true)
        setProductId(arr);
        setProductDetails([...arr]);
    } else {
        if (event < 0) {
            document.getElementById(restrictMsgId).style.display = 'block';
            const createOrder = document.getElementById('createOrderBtn')
            if (createOrder !== null) {
                createOrder.disabled = true;
            }
            const draftOrder = document.getElementById("draftOrderBtn");
            if (draftOrder !== null) {
              draftOrder.disabled = true;
            }
            const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
            if (interWhDraftOrder !== null) {
                interWhDraftOrder.disabled = false;
            }
        } else {
            document.getElementById(restrictMsgId).style.display = 'none';
            const pro = JSON.parse(event);
            let arr = productDetails;
            for (let ele of arr) {
                if (ele.variantId === Id) {
                    let packing1 = bulkPackagingValue1 !== undefined && ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPackagingValue1 : 0;
                    ele.quantity = ele.PCS + pro * bulkPackagingValue + packing1;
                    ele.bulkPackingValue2 = pro;
                    if (pro > 0) {
                        let variant = document.getElementById(ele.variantId)
                        variant.children[0].children[1].lastChild.style.display = 'none';
                        const createOrder = document.getElementById('createOrderBtn')
                        if (createOrder !== null) {
                            createOrder.disabled = false;
                        }
                        const draftOrder = document.getElementById("draftOrderBtn");
                        if (draftOrder !== null) {
                          draftOrder.disabled = false;
                        }
                        const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
                        if (interWhDraftOrder !== null) {
                            interWhDraftOrder.disabled = false;
                        }
                    }
                }
            }

            setAddToggle(true);
            setProductId(arr);
            setProductDetails([...arr]);
        }
    }
};

// increase qty from product
export const incQtyBulkPackagingHelper = (
    event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
    setProductDetails
) => {
    for (let productDetail of productDetails) {
        if (productDetail?.productId === Id) {
            if (type === 'bulkPackingValue1') {
                productDetail.quantity = parseInt(productDetail.quantity) + parseInt(bulkPackagingValue);
                productDetail.bulkPackingValue1 = productDetail.bulkPackingValue1 ? productDetail.bulkPackingValue1 + 1 : 1;
            } else {
                productDetail.quantity = parseInt(productDetail.quantity) + parseInt(bulkPackagingValue);
                productDetail.bulkPackingValue2 = productDetail.bulkPackingValue2 ? productDetail.bulkPackingValue2 + 1 : 1;
            }

            let variant = document.getElementById(productDetail.productId)
            variant.children[0].children[1].lastChild.style.display = 'none';
            const draftOrder = document.getElementById('draftOrderBtn');
            if (draftOrder !== null) {
                draftOrder.disabled = false;
            }
            const createOrder = document.getElementById('createOrderBtn')
            if (createOrder !== null) {
                createOrder.disabled = false;
            }
        }
    }

    setAddToggle(true)
    setProductId(productDetails);
    setProductDetails([...productDetails]);
};

export const decQtyBulkPackagingHelper = (
    event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
    setProductDetails
) => {
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.variantId === Id) {
            if (ele.quantity !== 1) {
                if (type === 'bulkPackingValue1') {
                    ele.quantity = ele.bulkPackingValue1 && ele.bulkPackingValue1 > 0 ? parseInt(ele.quantity) - parseInt(bulkPackagingValue) : ele.quantity;
                    ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 > 0 && ele.bulkPackingValue1 - 1 : 0;
                } else {
                    ele.quantity = ele.bulkPackingValue2 && ele.bulkPackingValue2 > 0 ? parseInt(ele.quantity) - parseInt(bulkPackagingValue) : ele.quantity;
                    ele.bulkPackingValue2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 > 0 && ele.bulkPackingValue2 - 1 : 0;
                }
            }
        }
    }

    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

export const decQtyBulkPackagingHelperPoEdit = (
    event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
    setProductDetails
) => {
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.productId === Id) {
            if (ele.quantity !== 1) {
                if (type === 'bulkPackingValue1') {
                    ele.quantity = ele.bulkPackingValue1 && ele.bulkPackingValue1 > 0 ? parseInt(ele.quantity) - parseInt(bulkPackagingValue) : ele.quantity;
                    ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 > 0 && ele.bulkPackingValue1 - 1 : 0;
                } else {
                    ele.quantity = ele.bulkPackingValue2 && ele.bulkPackingValue2 > 0 ? parseInt(ele.quantity) - parseInt(bulkPackagingValue) : ele.quantity;
                    ele.bulkPackingValue2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 > 0 && ele.bulkPackingValue2 - 1 : 0;
                }
            }
        }
    }

    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

// handle Blur Qty
export const handleBlurQtyHelper = (
    event, Id, bulkPacking1, bulkPacking2, restrictMsgId, productDetails, setAddToggle,
    setProductId, setProductDetails
) => {

    if (event === '' || event === '0') {
        document.getElementById(restrictMsgId).style.display = 'none';
        let arr = productDetails;
        for (let ele of arr) {
            if (ele.variantId === Id) {
                let packing1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPacking1 : 0;
                let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                ele.quantity = 0 + packing1 + packing2;
                ele.PCS = 0;
            }
        }

        setAddToggle(true)
        setProductId(arr);
        setProductDetails([...arr]);
    } else {
        if (event) {
            if (event < 0) {
                document.getElementById(restrictMsgId).style.display = 'block';
                const createOrder = document.getElementById('createOrderBtn')
                if (createOrder !== null) {
                    createOrder.disabled = true;
                }
                const draftOrder = document.getElementById("draftOrderBtn");
                if (draftOrder !== null) {
                  draftOrder.disabled = true;
                }
                const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
                if (interWhDraftOrder !== null) {
                    interWhDraftOrder.disabled = false;
                }
            } else {
                document.getElementById(restrictMsgId).style.display = 'none';
                const pro = JSON.parse(event);
                let arr = productDetails;
                for (let ele of arr) {
                    if (ele.variantId === Id) {
                        if (pro >= bulkPacking1) {
                            const bulkPacking = parseInt(pro/bulkPacking1);
                            let packing1 = ele.bulkPackingValue1 ? (ele.bulkPackingValue1 + bulkPacking) * bulkPacking1 : 
                            bulkPacking * bulkPacking1;
                            let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                            ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 + bulkPacking : bulkPacking;
                            ele.PCS = pro % bulkPacking1;
                            ele.quantity = ele.PCS + packing1 + packing2;
                        } else {
                            let packing1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPacking1 : 0;
                            let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                            ele.quantity = pro + packing1 + packing2;
                            ele.PCS = pro;
                        }
                        if (pro > 0) {
                            let variant = document.getElementById(ele.variantId)
                            variant.children[0].children[1].lastChild.style.display = 'none';
                            // document.getElementById('draftOrderBtn').disabled = false;
                            const createOrder = document.getElementById('createOrderBtn')
                            if (createOrder !== null) {
                                createOrder.disabled = false;
                            }
                            const draftOrder = document.getElementById("draftOrderBtn");
                            if (draftOrder !== null) {
                              draftOrder.disabled = false;
                            }
                            const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
                            if (interWhDraftOrder !== null) {
                                interWhDraftOrder.disabled = false;
                            }
                        }

                    }
                }

                setAddToggle(true);
                setProductId(arr);
                setProductDetails([...arr]);
            }
        }
    }
};

export const handleBlurQtyHelperPo = (
    event, Id, bulkPacking1, bulkPacking2, restrictMsgId, productDetails, setAddToggle,
    setProductId, setProductDetails
) => {
    if (event === '' || event === '0') {
        document.getElementById(restrictMsgId).style.display = 'none';
        let arr = productDetails;
        for (let ele of arr) {
            if (ele.variantId === Id) {
                let packing1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPacking1 : 0;
                let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                ele.quantity = 0 + packing1 + packing2;
                ele.PCS = 0;
            }
        }

        setAddToggle(true)
        setProductId(arr);
        setProductDetails([...arr]);
    } else {
        if (event) {
            if (event < 0) {
                document.getElementById(restrictMsgId).style.display = 'block';
                const createOrder = document.getElementById('createOrderBtn')
                if (createOrder !== null) {
                    createOrder.disabled = true;
                }
                const draftOrder = document.getElementById("draftOrderBtn");
                if (draftOrder !== null) {
                  draftOrder.disabled = true;
                }
                const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
                if (interWhDraftOrder !== null) {
                    interWhDraftOrder.disabled = false;
                }
            } else {
                document.getElementById(restrictMsgId).style.display = 'none';
                const pro = JSON.parse(event);
                let arr = productDetails;
                for (let ele of arr) {
                    if (ele.variantId === Id) {
                        if (pro >= bulkPacking1) {
                            const bulkPacking = parseInt(pro/bulkPacking1);
                            let packing1 = ele.bulkPackingValue1 ? (ele.bulkPackingValue1 + bulkPacking) * bulkPacking1 : 
                            bulkPacking * bulkPacking1;
                            let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                            ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 + bulkPacking : bulkPacking;
                            ele.PCS = pro % bulkPacking1;
                            ele.quantity = ele.PCS + packing1 + packing2;
                        } else {
                            let packing1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPacking1 : 0;
                            let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                            ele.quantity = pro + packing1 + packing2;
                            ele.PCS = pro;
                        }
                        if (pro > 0) {
                            let variant = document.getElementById(ele.variantId)
                            variant.children[0].children[1].lastChild.style.display = 'none';
                            // document.getElementById('draftOrderBtn').disabled = false;
                            const createOrder = document.getElementById('createOrderBtn')
                            if (createOrder !== null) {
                                createOrder.disabled = false;
                            }
                            const draftOrder = document.getElementById("draftOrderBtn");
                            if (draftOrder !== null) {
                              draftOrder.disabled = false;
                            }
                            const interWhDraftOrder = document.getElementById('InterWarehousedraftOrderBtn');
                            if (interWhDraftOrder !== null) {
                                interWhDraftOrder.disabled = false;
                            }
                        }

                    }
                }

                setAddToggle(true);
                setProductId(arr);
                setProductDetails([...arr]);
            }
        }
    }
};

export const formatNumberHelper = (number) => {
    const formattedNumber = parseFloat(number).toFixed(2);
    return formattedNumber;
};

// showLimitDiscount
export const showLimitDiscountHelper = (mrp, originalUnitPrice, value) => {
    const MRP = parseInt(mrp);
    const DP = parseFloat(originalUnitPrice);
    const GST = parseInt(value);
    const result = (MRP - DP * (1 + GST / 100)) / MRP;
    const TotalDiscount = result * 100;
    const decimalPlaces = countPlacesHelper(TotalDiscount);
    let results = decimalPlaces === 1 || decimalPlaces === 0 || decimalPlaces === 2 ? TotalDiscount : Math.trunc(TotalDiscount * 100) / 100;
    return results;
};

export const handleDisableSelectHelper = () => {
    const $handleSelect = document.getElementById('selectToggle')
    if ($handleSelect) {
        $handleSelect.value = 'DEFAULT';
        $handleSelect.disabled = true;
    }
}

export const handleEnableSelectHelper = () => {
    const $handleSelect = document.getElementById('selectToggle')
    if ($handleSelect !== null) {
        $handleSelect.value = 'DEFAULT';
        $handleSelect.disabled = false;
    }
}

// Open Window Print
export const handleSendPIHelper = (event,overAllDiscount) => {
    event.target.disabled = true;

    const discountHide = document.getElementById('noDiscountAvailPrint')
    if (overAllDiscount.length === 0) {
        discountHide.style.display = 'none';
    }

    // onbeforeprint = document.title ='Invoice';
    setTimeout(() => {
        window.print();
        onafterprint = document.title = 'DEPO24 PORTAL';
        onafterprint = event.target.disabled = false;
        onafterprint = discountHide.style.display = 'flex';
    }, 500);

}

// Create draftOrder(Save as Draft Order)
export const handleCreateDraftOrderHelper = (
    event, showCustomerDetail, showLocationDetail, showProduct, productDetails, 
    customerIDAddress, BillingAddress, vendorGstNumber, locationIDAddress,
    locationBillingAddress, locationGstNumber, overAllDiscount, paymentDetails,
    shippingPrice, setShippingDetails, shippingDetails, documentsAttached,
    locationContactDetails, vendorContactDetails, notes, navigate, setIsCreateOrderPage,
    setLoading
) => {
    for (let ele of productDetails) {
        if (ele?.quantity === 0) {
            let variant = document.getElementById(ele.variantId);
            variant.scrollIntoView();
            alert('Please Enter Quantity')
            variant.children[0].children[1].lastChild.style.display = "block";
            return;
        }
    }
    setLoading(true);
    // event.target.disabled = true;
    event.target.innerHTML = 'Creating Draft Order...';
    const vendor = showCustomerDetail[0];
    const location = showLocationDetail[0];

    const taxLines = showProduct.taxLines;

    for (let product of productDetails) {
        const index = showProduct.lineItems.findIndex(variantIndex => variantIndex.productId === product.variantId);
        product.mrp = +showProduct.lineItems[index].priceDataAffiliateData.mrp;
        product.unitOfMeasurement = showProduct.lineItems[index].basicCollectionDataBrandData.unit;
        product.subTotal = showProduct.lineItems[index].subTotal;
        product.discountedUnitPrice = showProduct.lineItems[index].discountedUnitPrice;
        product.discountedSubTotal = showProduct.lineItems[index].subTotal;
        product.discountedTotal = showProduct.lineItems[index].total;
        product.discountPercentage = showProduct.lineItems[index].discountPercentage;
    }

    const variantLineItems = productDetails.map(obj => {
        const { variantId, ...rest } = obj;
        return { productId: variantId, ...rest };
    });

    const vendorBillingAddressObject = {};
    const vendorAddressObject = {};
    let vendorDetails = null;

    if (vendor) {
        const vendorAddress = customerIDAddress[0];
        for (const key in vendorAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((vendorAddressObject[key] = `${vendorAddress[key]}`));
            }
        }

        let vendorBillingAddress = BillingAddress.length === 0 ? customerIDAddress[0] : BillingAddress[0];

        for (const key in vendorBillingAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'updatedAt' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((vendorBillingAddressObject[key] = `${vendorBillingAddress[key]}`));
            }
        }

        for (const key in vendor) {
            if (key !== 'status' || key !== 'active') {
                Object.assign((vendorAddressObject[key] = `${vendor[key]}`));
                Object.assign((vendorBillingAddressObject[key] = `${vendor[key]}`));
            }
        }
        vendorBillingAddressObject['addressType'] = "Billing"
        vendorAddressObject['addressType'] = "Shipping"
        vendorAddressObject['vendorGstNumber'] = vendorGstNumber
        vendorBillingAddressObject['vendorGstNumber'] = vendorGstNumber
        vendorDetails = [vendorAddressObject, vendorBillingAddressObject]
    }

    const locationBillingAddressObject = {};
    const locationAddressObject = {};
    let locationDetails = null

    if (location) {
        const locationAddress = locationIDAddress[0];
        for (const key in locationAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'

            ) {
                Object.assign((locationAddressObject[key] = `${locationAddress[key]}`));
            }
        }

        let locationBilling = locationBillingAddress.length === 0 ? locationIDAddress[0] : locationBillingAddress[0];

        for (const key in locationBilling) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((locationBillingAddressObject[key] = `${locationBilling[key]}`));
            }
        }

        for (const key in location) {
            if (key !== 'status' || key !== 'active') {
                Object.assign((locationAddressObject[key] = `${location[key]}`));
                Object.assign((locationBillingAddressObject[key] = `${location[key]}`));
            }
        }
        locationBillingAddressObject['addressType'] = "Billing"
        locationAddressObject['addressType'] = "Shipping"
        locationAddressObject['locationGstNumber'] = locationGstNumber
        locationBillingAddressObject['locationGstNumber'] = locationGstNumber
        locationDetails = [locationAddressObject, locationBillingAddressObject]
    }

    let paymentObject
    if (overAllDiscount.length !== 0) {
        paymentObject = {
            "totalAmount": showProduct?.total,
            "totalTaxAmount": showProduct?.totalTaxAmount,
            "subTotalAmount": showProduct?.subTotal,
            "paymentMode": paymentDetails?.paymentMode,
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
            "overallDiscountType": overAllDiscount[0]?.valueType,
            "overallDiscountValue": overAllDiscount[0]?.value,
            "overallDiscountTitle": overAllDiscount[0]?.title,
        }
    } else {
        paymentObject = {
            "totalAmount": showProduct?.total,
            "totalTaxAmount": showProduct?.totalTaxAmount,
            "subTotalAmount": showProduct?.subTotal,
            "paymentMode": paymentDetails?.paymentMode,
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
        }
    }

    if (shippingPrice.length !== 0) {
        setShippingDetails({
            ...shippingDetails,
            "freightCharges": shippingPrice[0]?.price,
            "transporterName": shippingPrice[0]?.title,

        })
    }

    const formData = new FormData()

    for (let i = 0; i < documentsAttached.length; i++) {
        formData.append("documentsAttached", documentsAttached[i]);
    }

    const data = {
        "DpoLineItems": variantLineItems,
        "DpoVendorDetails": vendorDetails,
        "DpoLocationDetails": locationDetails,
        "DpoShipmentDetails": shippingDetails,
        "DpoPaymentDetails": paymentObject,
        "DpoLocationContactDetails": locationContactDetails,
        "DpoVendorContactDetails": vendorContactDetails,
        "DpoTaxLines": taxLines,
        "notes": notes,
    }
    const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== null && value !== undefined && value !== "")
    );

    formData.append('data', JSON.stringify(filteredData));


    axiosInstance
        .post('/purchaseOrders/saveDraftPurchaseOrder', formData)
        .then((res) => {
            // setSendPIOpenOrder(true);
            // setSendPIDraftOrder(res.data.data);
            setLoading(false);
            event.target.innerHTML = 'Draft Order Created';
            setIsCreateOrderPage(false);
            navigate(`/draftPurchaseOrders/${res?.data?.data?.draftPurchaseOrderName}`);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
            event.target.disabled = false;
            event.target.innerHTML = 'Save as Draft Purchase Order';
        });
}

// Update Exist Order
export const handleUpdateOrderHelper = (
    event, showCustomerDetail, showLocationDetail, showProduct, productDetails, 
    customerIDAddress, BillingAddress, vendorGstNumber, locationIDAddress,
    locationBillingAddress, locationGstNumber, overAllDiscount, paymentDetails,
    shippingDetails, documentsAttached, locationContactDetails, vendorContactDetails,
    notes, navigate, specificId, setVendorContactDetails, setLocationContactDetails,
    defaultFiles, draftOrder, updateDraftOrderId, 
    setIsCreateOrderPage, navigateUrl
) => {
    for (let productDetail of productDetails) {
        if (productDetail?.quantity === 0) {
            let variant = document.getElementById(productDetail.productId);
            variant.scrollIntoView();
            alert('Please Enter Quantity')
            variant.children[0].children[1].lastChild.style.display = "block";
            return;
        }
    }
    event.target.innerHTML = 'Draft Order Updating...';
    const vendor = showCustomerDetail[0];
    const location = showLocationDetail[0];

    const taxLines = showProduct.taxLines;

    for (let productDetail of productDetails) {
        const index = showProduct?.lineItems?.findIndex(variantIndex => variantIndex?.productId === productDetail?.productId);
        productDetail.mrp = +showProduct?.lineItems[index]?.priceDataAffiliateData?.mrp;
        productDetail.unitOfMeasurement = showProduct?.lineItems[index]?.basicCollectionDataBrandData?.unit;
        productDetail.subTotal = showProduct?.lineItems[index]?.subTotal;
        productDetail.discountedUnitPrice = showProduct?.lineItems[index]?.discountedUnitPrice;
        productDetail.discountedSubTotal = showProduct?.lineItems[index]?.subTotal;
        productDetail.discountedTotal = showProduct?.lineItems[index]?.total;
        productDetail.discountPercentage = showProduct?.lineItems[index]?.discountPercentage;
    }

    const variantLineItems = productDetails?.map(obj => {
        const { appliedDiscount, ...rest } = obj;
        const object = { ...rest };
        if(appliedDiscount && appliedDiscount?.title === null) {
            if (appliedDiscount?.valueType) {
                appliedDiscount.title = ''
                object.appliedDiscount = appliedDiscount;
            }
        }
        return object;
    });

    const vendorBillingAddressObject = {};
    const vendorAddressObject = {};
    let vendorDetails = [];

    if (vendor) {
        const vendorAddress = customerIDAddress[0];
        for (const key in vendorAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((vendorAddressObject[key] = `${vendorAddress[key]}`));
            }
        }

        let vendorBillingAddress = BillingAddress.length === 0 ? customerIDAddress[0] : BillingAddress[0];

        for (const key in vendorBillingAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'updatedAt' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((vendorBillingAddressObject[key] = `${vendorBillingAddress[key]}`));
            }
        }

        for (const key in vendor) {
            if (key !== 'status' || key !== 'active') {
                Object.assign((vendorAddressObject[key] = `${vendor[key]}`));
                Object.assign((vendorBillingAddressObject[key] = `${vendor[key]}`));
            }
        }
        vendorBillingAddressObject['addressType'] = "Billing"
        vendorAddressObject['addressType'] = "Shipping"
        vendorBillingAddressObject['PoVendorDetailId'] = specificId.poVendorDetailIdBilling
        vendorBillingAddressObject['purchaseOrderId'] = specificId.purchaseOrderId
        vendorAddressObject['PoVendorDetailId'] = specificId.poVendorDetailIdShipping
        vendorAddressObject['purchaseOrderId'] = specificId.purchaseOrderId
        vendorAddressObject['vendorGstNumber'] = vendorGstNumber
        vendorBillingAddressObject['vendorGstNumber'] = vendorGstNumber
        vendorDetails = [vendorAddressObject, vendorBillingAddressObject]
        setVendorContactDetails({ ...vendorContactDetails, 'poVendorContactDetailId': specificId.poVendorContactDetailId ? specificId.poVendorContactDetailId : null })

    }
    const locationBillingAddressObject = {};
    const locationAddressObject = {};
    let locationDetails = []

    if (location) {
        const locationAddress = locationIDAddress[0];
        for (const key in locationAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'

            ) {
                Object.assign((locationAddressObject[key] = `${locationAddress[key]}`));
            }
        }

        let locationBilling = locationBillingAddress.length === 0 ? locationIDAddress[0] : locationBillingAddress[0];

        for (const key in locationBilling) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((locationBillingAddressObject[key] = `${locationBilling[key]}`));
            }
        }

        for (const key in location) {
            if (key !== 'status' || key !== 'active') {
                Object.assign((locationAddressObject[key] = `${location[key]}`));
                Object.assign((locationBillingAddressObject[key] = `${location[key]}`));
            }
        }
        locationBillingAddressObject['addressType'] = "Billing"
        locationAddressObject['addressType'] = "Shipping"
        locationBillingAddressObject['poLocationDetailId'] = specificId.poLocationDetailIdBilling
        locationAddressObject['poLocationDetailId'] = specificId.poLocationDetailIdShipping
        locationAddressObject['purchaseOrderId'] = specificId.purchaseOrderId
        locationBillingAddressObject['purchaseOrderId'] = specificId.purchaseOrderId
        locationAddressObject['locationGstNumber'] = locationGstNumber
        locationBillingAddressObject['locationGstNumber'] = locationGstNumber
        locationDetails = [locationAddressObject, locationBillingAddressObject]
        setLocationContactDetails({ ...locationContactDetails, 'poLocationContactDetailId': specificId.poLocationContactDetailId ? specificId.poLocationContactDetailId : null })
    }

    let paymentObject
    if (overAllDiscount.length !== 0) {
        paymentObject = {
            "totalAmount": showProduct?.total,
            "totalTaxAmount": showProduct?.totalTaxAmount,
            "subTotalAmount": showProduct?.subTotal,
            "paymentMode": paymentDetails?.paymentMode,
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
            "poPaymentDetailId": specificId.poPaymentDetailId,
            "overallDiscountType": overAllDiscount[0]?.valueType,
            "overallDiscountValue": +overAllDiscount[0]?.value,
            "overallDiscountTitle": overAllDiscount[0]?.title,
        }
    } else {
        paymentObject = {
            "totalAmount": Math.round(showProduct?.total),
            "totalTaxAmount": Math.round(showProduct?.totalTaxAmount),
            "subTotalAmount": Math.round(showProduct?.subTotal),
            "paymentMode": paymentDetails?.paymentMode,
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
            "poPaymentDetailId": specificId.poPaymentDetailId

        }
    }

    const formData = new FormData()

    for (let i = 0; i < documentsAttached.length; i++) {
        formData.append("documentsAttached", documentsAttached[i]);
    }

    let poShippingDetails = { ...shippingDetails, poShipmentDetailId: specificId.poShipmentDetailId ? specificId.poShipmentDetailId : null }

    let data = {
        "documentsAttached": defaultFiles,
        "PoLineItems": variantLineItems,
        "PoVendorDetails": vendorDetails,
        "PoLocationDetails": locationDetails,
        "PoShipmentDetails": poShippingDetails,
        "PoPaymentDetails": paymentObject,
        PoTaxLines: taxLines,
        notes: notes,
    }

    if (draftOrder?.PoVendorContactDetails !== null) {
        Object.assign(data, { PoVendorContactDetails: { ...vendorContactDetails } })
    }

    if (draftOrder?.PoLocationContactDetails !== null) {
        Object.assign(data, { PoLocationContactDetails: { ...locationContactDetails } })
    }

    const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== null && value !== undefined && value !== "")
    );


    formData.append('data', JSON.stringify(filteredData));

    const purchaseOrderNameToBeUpdated = updateDraftOrderId[0];
    axiosInstance
        .put(`/purchaseOrders/purchaseOrder/${purchaseOrderNameToBeUpdated}`, formData)
        .then((res) => {
            event.target.innerHTML = 'Purchase Order Updated';
            setIsCreateOrderPage(false);
            navigate(`/${navigateUrl}`);
        })
        .catch((err) => {
            const errorMessage = err?.response?.data?.message || `Updating Po ${purchaseOrderNameToBeUpdated} failed!`;
            event.target.innerHTML = 'Update Purchase Order'; 
            alert(errorMessage)
        });
}

export const countPlacesHelper = (num) => {
    var sep = String(23.32).match(/\D/)[0];
    var b = String(num).split(sep);
    return b[1] ? b[1].length : 0;
}

export const handleChangeHelper = (value, sku, lineItems, setLineItems) => {
    let lineItemsList = lineItems;

    lineItemsList = lineItemsList.map(lineItem => {
        if (lineItem.basicCollectionDataBrandData.sku === sku) {
            lineItem.PCS = parseInt(value);
        }
        return lineItem;
    });

    setLineItems(lineItemsList)
}

export const handleUpdateExistPurchaseOrderHelper = (
    purchaseOrderName, setUpdateExistOrder, setPurchaseOrder, setUpdateDraftOrderId, setShippingDetails,
    setPaymentDetails, setVendorContactDetails, setVendorGstNumber, setLocationContactDetails, setNotes,
    setLocationGstNumber, setSpecificId, setDefaultFiles, setUpdateDiscountPercent, setDiscountPercent,
    setOverAllDiscount, setShippingPrice, setProductId, setProductDetails, setProduct,
    handleEnableSelect, setUpdateToggle, setLineItems, setCustomerDetail, setCustomerIDAddress,
    setBillingAddress, setLocationDetail, setLoadingPurchaseOrderCalculationServiceState,
) => {
    axiosInstance
        .get(`/purchaseOrders/purchaseOrderDetails/${purchaseOrderName}`)
        .then((response) => {
            const purchaseOrderDetails = response?.data?.data;
            if (purchaseOrderDetails === null) {
                return
            }

            if (purchaseOrderDetails) {
                setUpdateExistOrder(true);
            }

            setPurchaseOrder(purchaseOrderDetails);
            setUpdateDraftOrderId([purchaseOrderDetails?.purchaseOrderName])
            setShippingDetails(purchaseOrderDetails?.PoShipmentDetails);
            setPaymentDetails(purchaseOrderDetails?.PoPaymentDetails);
            setBillingAddress([purchaseOrderDetails?.PoVendorDetails?.find(poVendorDetail => poVendorDetail?.addressType === 'Billing')]);
            setCustomerIDAddress([purchaseOrderDetails?.PoVendorDetails?.find(poVendorDetail => poVendorDetail?.addressType === 'Shipping')]);
            setVendorContactDetails(purchaseOrderDetails?.PoVendorContactDetails)
            setVendorGstNumber(purchaseOrderDetails?.PoVendorDetails[0]?.vendorGstNumber)
            setCustomerDetail(purchaseOrderDetails?.PoVendorDetails)
            setLocationDetail(purchaseOrderDetails?.PoLocationDetails)
            setLocationContactDetails(purchaseOrderDetails?.PoLocationContactDetails)
            setNotes(purchaseOrderDetails?.notes);
            setLocationGstNumber(purchaseOrderDetails?.PoLocationDetails[0]?.locationGstNumber)
            const ids = {
                poLocationContactDetailId: purchaseOrderDetails?.PoLocationContactDetails?.poLocationContactDetailId,
                poVendorContactDetailId: purchaseOrderDetails?.PoVendorContactDetails?.poVendorContactDetailId,
                poPaymentDetailId: purchaseOrderDetails?.PoPaymentDetails?.poPaymentDetailId,
                poShipmentDetailId: purchaseOrderDetails?.PoShipmentDetails?.poShipmentDetailId,
                purchaseOrderId: purchaseOrderDetails?.purchaseOrderId,
                poLocationDetailIdShipping: purchaseOrderDetails
                    ?.PoLocationDetails
                    ?.find(poLocationDetail => poLocationDetail?.addressType === 'Shipping')
                    ?.poLocationDetailId,
                poLocationDetailIdBilling: purchaseOrderDetails
                    ?.PoLocationDetails
                    ?.find(poLocationDetail => poLocationDetail?.addressType === 'Billing')
                    ?.poLocationDetailId,
                poVendorDetailIdShipping: purchaseOrderDetails
                    ?.PoVendorDetails
                    ?.find(poVendorDetail => poVendorDetail?.addressType === 'Shipping')
                    ?.PoVendorDetailId,
                poVendorDetailIdBilling: purchaseOrderDetails
                    ?.PoVendorDetails
                    ?.find(poVendorDetail => poVendorDetail?.addressType === 'Billing')
                    ?.PoVendorDetailId,
            }

            setSpecificId(ids)

            let files = [];
            for (let i = 0; i < purchaseOrderDetails?.documentsAttached.length; i++) {
                files.push(purchaseOrderDetails?.documentsAttached[i])
            }
            setDefaultFiles(files)
            const poLineItems = purchaseOrderDetails?.PoLineItems;

            let lineItemsToBeCalculated = [];
            let docDbLineItemProductIds = [];
            for (let poLineItem of poLineItems) {
                docDbLineItemProductIds.push(poLineItem?.productId);
                const lineItemToBeCalculated = {
                    productId: poLineItem?.productId,
                    quantity: poLineItem?.quantity,
                    lineItemId: poLineItem?.lineItemId, 
                    ...(poLineItem?.districtForPrices && {districtForPrices: poLineItem?.districtForPrices}),
                }
                if (poLineItem?.discountValue || poLineItem?.discountPercentage) {
                    const appliedDiscount = {
                        title: poLineItem.discountTitle ? poLineItem.discountTitle : '',
                        value: Number(poLineItem.discountValue ? poLineItem.discountValue : poLineItem.discountPercentage),
                        valueType: poLineItem.discountType ? poLineItem?.discountType : 'PERCENTAGE'
                    }
                    lineItemToBeCalculated.appliedDiscount = appliedDiscount;
                }
                lineItemsToBeCalculated.push(lineItemToBeCalculated)

            }
            setUpdateDiscountPercent(docDbLineItemProductIds)
            setDiscountPercent(docDbLineItemProductIds);

            let overallDiscount = {}
            if (purchaseOrderDetails?.PoPaymentDetails?.overallDiscountValue !== null) {
                overallDiscount = {
                    title: purchaseOrderDetails?.PoPaymentDetails?.overallDiscountTitle,
                    value: +purchaseOrderDetails?.PoPaymentDetails?.overallDiscountValue,
                    valueType: purchaseOrderDetails?.PoPaymentDetails?.overallDiscountType
                }
            }

            let shipping = {}
            if (purchaseOrderDetails?.PoShipmentDetails?.freightCharges) {
                shipping = {
                    price: purchaseOrderDetails?.PoShipmentDetails?.freightCharges
                }
            }

            const data = {
                lineItems: lineItemsToBeCalculated,
                orderType: "purchase",
                shippingLine: shipping,
                overallDiscount: Object.keys(overallDiscount).length !== 0 ? overallDiscount : undefined,
            }

            if (Object.keys(overallDiscount).length === 0) {
                setOverAllDiscount([])
            } else {
                setOverAllDiscount([overallDiscount])
            }

            setShippingPrice([shipping]);
            setLoadingPurchaseOrderCalculationServiceState(true);
            const endpoint = '/calculate/purchaseOrder';
            axiosInstance
                .post(endpoint, { ...data })
                .then((res) => {
                    if (res?.data !== undefined) {
                        const calculatedPoLineItems = res?.data?.lineItems;
                        setProductId(lineItemsToBeCalculated);
                        lineItemsToBeCalculated = lineItemsToBeCalculated.map((lineItemToBeCalculated) => {
                            const calculatedPoLineItem = calculatedPoLineItems
                                ?.find((calculatedPoLineItem) => {
                                    return (calculatedPoLineItem.productId === lineItemToBeCalculated.productId);
                                });
                            return {
                                ...lineItemToBeCalculated,
                                PCS: calculatedPoLineItem?.PCS,
                                bulkPackingValue1: calculatedPoLineItem?.bulkPackingValue1,
                                bulkPackingValue2: calculatedPoLineItem?.bulkPackingValue2,
                            }
                        })
                        setProductDetails(lineItemsToBeCalculated);
                        setProduct(res?.data);
                        setTimeout(() => {
                            handleEnableSelect();
                        }, 1500)
                        setUpdateToggle(false);
                        const lineItem = res?.data?.lineItems;
                        setLineItems([...lineItem]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    let calculatePurchaseOrderRoutePendingCount = localStorage.getItem(endpoint);
                    calculatePurchaseOrderRoutePendingCount = calculatePurchaseOrderRoutePendingCount ? parseInt(calculatePurchaseOrderRoutePendingCount) : 0;
                    if(calculatePurchaseOrderRoutePendingCount === 0){
                        setLoadingPurchaseOrderCalculationServiceState(false);
                    }
                });
        });
}

export const handlePoCalculationHelper = (
    lineItems, locationIDAddress, customerIDAddress, lineItemsToBeCalculated, overAllDiscount,
    shippingPrice, setAddToggle, updateDiscountPercent, setDiscountPercent, editDiscountPercent,
    setLineItems, setProduct, setLoadingPurchaseOrderCalculationServiceState
) => {
    lineItems.splice(0, lineItems?.length);

    let billFromState = ""
    let billToState = ""

    if (locationIDAddress?.length !== 0) {
        billFromState = locationIDAddress?.[0]?.state
    }

    if (customerIDAddress?.length !== 0) {
        billToState = customerIDAddress?.[0]?.state
    }

    const data = {
        lineItems: lineItemsToBeCalculated,
        shipToState: billToState,
        shipFromState: billFromState,
        orderType: "purchase",
        overallDiscount: overAllDiscount.length !== 0 ? overAllDiscount[0] : undefined,
        shippingLine: shippingPrice.length !== 0 ? shippingPrice[0] : undefined,
    }

    setLoadingPurchaseOrderCalculationServiceState(true);
    const endpoint = '/calculate/purchaseOrder';
    axiosInstance
        .post(endpoint, { ...data })
        .then((res) => {
            setAddToggle(false);
            if (updateDiscountPercent !== undefined) {
                if (updateDiscountPercent.length !== 0) {
                    setDiscountPercent(updateDiscountPercent)
                } else {
                    if (editDiscountPercent.length !== 0) {
                        setDiscountPercent([]);
                    }
                }
            }


            const obj = {...res.data};
            const lineItem = res.data.lineItems;
            setLineItems([...lineItem]);
            setProduct(obj)
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            let calculatePurchaseOrderRoutePendingCount = localStorage.getItem(endpoint);
            calculatePurchaseOrderRoutePendingCount = calculatePurchaseOrderRoutePendingCount ? parseInt(calculatePurchaseOrderRoutePendingCount) : 0;
            if (calculatePurchaseOrderRoutePendingCount === 0) {
                setLoadingPurchaseOrderCalculationServiceState(false);
            }
        });
}