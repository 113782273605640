import * as React from 'react';
import { Button, Tooltip } from '@mui/material';
import { Add, DeleteForever } from '@mui/icons-material';
import MultiFilters from './MultiFilters';

const CustomFilterPanel = ({ 
  multiFilters, setMultiFilters, handleApplyFilter, setApplyFilter, handleRemoveAllFilter
}) => {

  const handleAddFilter = () => {
    setMultiFilters([...multiFilters, {field: '', value: '', operator: 'contains'} ])
  }

  const handleRemove = (index) => {
    if (multiFilters?.length > 1) {
      const newFilters = multiFilters?.filter((s, filterIndex) => filterIndex !== index);
      setMultiFilters([...newFilters]);
    }
  }

  return (
    <div
      style={{ 
        position:"absolute", 
        inset:"0px auto auto 0px", 
        margin:"0px", 
        transform:"translate(0px, 104px)", 
        zIndex: 1300 
      }} 
    >
      <div 
        style={{
          flexDirection: 'column',
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
          backgroundColor: "rgb(255, 255, 255)",
          minWidth: "300px",
          maxHeight: "450px",
          display: "flex",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          borderRadius: "4px",
          padding: '5px 10px'
        }}
      >
        {multiFilters?.length && multiFilters?.map((filter,index) => (
          <MultiFilters 
            filters={filter} key={`filterId-${index}`} filterId={index} id={`filterId-${index}`}
            removeSection={() => handleRemove(index)}
            setMultiFilters={setMultiFilters}
          />
        ))}
        
        <div style={{display:'flex',justifyContent:'space-between'}}>
          {multiFilters?.length < 3 && (
            <Tooltip 
              title="Add" placement="top-end" 
              style={{fontSize:"13px", fontWeight:600, marginTop:'5px'}}
              onClick={handleAddFilter}
            >
              <Button><Add style={{ marginRight:'8px',fontSize:"20px", marginTop:'-4px' }}/>ADD FILTER</Button>
            </Tooltip>
          )}

          <div>
            <Tooltip 
              title="Remove Filter" placement="top-end" 
              style={{fontSize:"13px", fontWeight:600, marginTop:'5px',marginRight:10,textAlign:'end'}}
              onClick={handleRemoveAllFilter}
            >
              <Button><DeleteForever style={{marginRight:'4px'}}/>REMOVE ALL</Button>
            </Tooltip>

            <Tooltip 
              title="Apply" placement="top-end" 
              style={{fontSize:"13px", fontWeight:600, marginTop:'5px',marginRight:10,textAlign:'end'}}
              onClick={handleApplyFilter}
            >
              <Button>APPLY FILTER</Button>
            </Tooltip>
          </div>

        </div>
      </div>
    </div>
  );
}

export default CustomFilterPanel;