import React, { Fragment,useState,useEffect } from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';


const ShippingPopUp = (props)=> {
    const [states,setStates] = useState([]);

    const {
        shippingModal,
        hideShippingPop,
        showCustomerDetail,
        setCustomerIDAddress,
        setBillingAddress,
        addressArray,
		setAddressArray,
        addressType,
        num,
        setNum,
        pincode,
        setPincode,
        type       
    } = props;

    const handleNumChange = event => {
        const limit = 10;
        setNum(event.target.value.slice(0, limit));
    };

    const handlePinCodeChange = event => {
        const limit = 6;
        setPincode(event.target.value.slice(0, limit));
    };

    const handleSubmit = (event)=> {
        event.preventDefault();
        const data = {
            firstName: event.target.first.value.trim(),
            lastName: event.target.last.value ? event.target.last.value.trim() : undefined,
            addressLine1: event.target.address1.value,
            addressLine2: event.target.address2.value,
            city: event.target.city.value,
            pincode: event.target.pincode.value,
            state: event.target.state.value,
            addressType: addressType,
            phoneNumber: event.target.phone.value? event.target.phone.value : undefined,
        }
        
        if (type === "Vendor"){
            axiosInstance
                .post(`/vendor/vendorAddress/${showCustomerDetail[0].vendorCode}`,data)
                .then((res) =>{
                    setAddressArray([...addressArray,res.data.data])
                    if(res.data.data.addressType === 'Shipping'){
                        setCustomerIDAddress([res.data.data])
                    }else{
                        setBillingAddress([res.data.data])  
                    }       
                });
        } else if (type === "Location"){
            axiosInstance
            .post(`/location/locationAddress/${showCustomerDetail[0]?.locationCode}`,data)
            .then((res) =>{
                setAddressArray([...addressArray,res.data.data]);
                if(res.data.data.addressType === 'Shipping'){
                    setCustomerIDAddress([res.data.data])
                }else{
                    setBillingAddress([res.data.data])  
                }       
            });
        }
        hideShippingPop();
        setNum('');
        setPincode('');
    }

    document.addEventListener('mouseup', function(e) {
        let container = document.getElementById('shippingNumberOutline');
        if(container !== null){
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

   
    const handleMinLengthPhoneNo = (event) => {
        const phoneNo = /^\d{10}$/;
        if (event.target.value.length === 0) {
            return
        }
        if(event.target.value.match(phoneNo)){
            document.getElementById('phoneErrMsg').style.display = 'none';
        }else{
            document.getElementById('phoneErrMsg').style.display = 'block';
        }
    }

    const handleMinLengthPinCode = (event) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if(event.target.value.match(pincode)){
                document.getElementById('pinErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            }else{
                document.getElementById('btn').disabled = true;
                document.getElementById('pinErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('pinErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setStates(response.data.data);
            })
    },[])

    return (
        <Fragment>
            {shippingModal && (
                <div className={Classes.modal} style={{bottom:'10%',top:'3%'}}>
                    <div className={Classes.BoxContent}>
                        <p>Add New Address</p>
                    </div>
                    
                    <form method="post" onSubmit={handleSubmit} id='formData'>
                        <div className={Classes.AddressPopUp}>
                            <div>
                                <TextField 
                                    id='country'
                                    name='country'
                                    label='Country / Origin'
                                    value='India'
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top:"0%",fontSize:14},"& .MuiOutlinedInput-input":{padding:'6px 10px'}}}
                                    disabled={true}
                                />
                            </div>

                            <div style={{ display:'flex',justifyContent:'space-between', marginTop:15 }}>
                                <div style={{width:'48%'}}>
                                    <TextField 
                                        required={true}
                                        id='first'
                                        name='first'
                                        label='First Name'
                                        autoComplete='off'
                                        placeholder='Enter Your First Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{width:'48%'}}>
                                    <TextField 
                                        id='last'
                                        name='last'
                                        label='Last Name'
                                        autoComplete='off'
                                        placeholder='Enter Your Last Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop:15 }}>
                                <TextField 
                                    required={true}
                                    id='address1'
                                    name='address1'
                                    label='Address 1'
                                    autoComplete='off'
                                    placeholder='Enter Your Address'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ marginTop:15 }}>
                                <TextField
                                    id='address2'
                                    name='address2'
                                    label='Address 2'
                                    autoComplete='off'
                                    placeholder='Apartment,Suite,etc'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
                                <div style={{width:'48%'}}>
                                    <TextField 
                                        required={true}
                                        id='city'
                                        name='city'
                                        label='City'
                                        autoComplete='off'
                                        placeholder='Enter Your City'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            
                                <div style={{width:'48%'}}>
                                    <TextField 
                                        type="number"
                                        required={true}
                                        id='pincode'
                                        name='pincode'
                                        label='Pincode'
                                        autoComplete='off'
                                        placeholder='Enter Your Pincode'
                                        maxLength={6}
                                        sx={{ width: '100%'}}
                                        value={pincode}
                                        onBlur={(e) => handleMinLengthPinCode(e)}
                                        onChange={handlePinCodeChange}
                                    />

                                    <div 
                                        className={Classes.PhoneErrorMsg} 
                                        id='pinErrMsg' 
                                        style={{fontSize:'14px'}}
                                    >
                                        <p>Please enter a valid 6 digit pincode</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{display:'flex',marginTop:15,justifyContent:'space-between'}}>
                                <div style={{ width:'48%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="state"
                                        name='state'
                                        options={states}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="States" required/>}
                                    />
                                </div>
                                <div style={{width: '48%'}}>
                                    <TextField 
                                        type="number"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        maxLength={10}
                                        sx={{ width: '100%'}}
                                        value={num}
                                        onBlur={(e) => handleMinLengthPhoneNo(e)}
                                        onChange={(e) => handleNumChange(e)}
                                    />

                                    <div 
                                        className={Classes.PhoneErrorMsg} 
                                        id='phoneErrMsg' 
                                    >
                                        <p>Please enter a valid 10 digit mobile number</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom: "10%"}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideShippingPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                ADD
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </Fragment>
    );
}


export default ShippingPopUp;