import { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from '../createPurchaseOrder/css/create.module.css';
// import Aside from './aside/aside';
import PoEditAside from '../../../components/editPurchaseOrder/editPoAside';
import TaxRatesPopup from '../createPurchaseOrder/TaxRates_popup';
import DiscountPopup from '../createPurchaseOrder/addDiscountPopup';
import TaxRatesPrint from '../createPurchaseOrder/TaxRatesPrint';
import ConfirmPopup from '../createPurchaseOrder/confirmPopup';
import { Spinner } from 'react-bootstrap';
import CreateOrderComponent from '../../../components/editPurchaseOrder/createOrder';
import PaymentDetailsComponent from '../../../components/editPurchaseOrder/paymentDetails';
import { 
    handleTaxRatesHelper,
    hideTaxPopupHelper,
    showDiscountPopupHelper,
    hideDiscountPopupHelper,
    handleBlurPercentageHelper,
    handleEditHelper,
    handleKeyUpHelper,
    handleBlurBulkPackagingQty1Helper,
    handleBlurBulkPackagingQty2Helper,
    incQtyBulkPackagingHelper,
    formatNumberHelper,
    showLimitDiscountHelper,
    handleSendPIHelper,
    handleCreateDraftOrderHelper,
    handleUpdateOrderHelper,
    handleChangeHelper, 
    handleDisableSelectHelper,
    handleEnableSelectHelper,
    handleUpdateExistPurchaseOrderHelper,
    handlePoCalculationHelper,
    incQtyHelperPoEdit,
    updateArrHelperPo,
    handleBlurQtyHelperPo,
    decQtyBulkPackagingHelperPoEdit,
    handleBlurHelperPoEdit,
    decQtyHelperPoEdit
} from '../../../components/editPurchaseOrder/helperFunction';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';

const EditPurchaseOrder = (props) => {
    const { isCreateOrderPage, setIsCreateOrderPage } = props;
    const [error, setError] = useState(null);
    const [productId, setProductId] = useState([]);
    const [addToggleBtn, setAddToggle] = useState(false);
    const [showProduct, setProduct] = useState([]);
    const [variantRole, setVariantRole] = useState([]);
    const [showTaxRate, setTaxRates] = useState(false);
    const [discountModal, setDiscountModal] = useState(false);
    const [productDetails, setProductDetails] = useState([]);
    const [updateDiscountPercent, setUpdateDiscountPercent] = useState([]);
    const [editDiscountPercent, setDiscountPercent] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPurchaseOrderCalculationService, setLoadingPurchaseOrderCalculationService] = useState(false);
    const [notExistProductShopify, setProductNotExistShopify] = useState(false);
    const [notExistProductShopifyId, setProductNotExistShopifyId] = useState([]);
    const [lineItems,setLineItems] = useState([]);
    const adminUser = useSelector((state) => state?.auth?.user);

    const districts = adminUser?.settings?.districts;
    const districtOptions = districts?.map((district) => {
        return {
            label: district,
            value: district
        }
    });
    const [selectedDistrict, setSelectedDistrict] = useState(districtOptions?.[0]);
  
    // modal
    const [showConfirmPopup, setConfirmShowPopup] = useState(false);

    // ShippingPopUp
    const [shippingPrice, setShippingPrice] = useState([]);
    const [previousTarget, setPreviousTarget] = useState([]);

    // aside
    const [showCustomerDetail, setCustomerDetail] = useState([]);
    const [customerIDAddress, setCustomerIDAddress] = useState([]);
    const [vendorGstNumber, setVendorGstNumber] = useState("");
    const [locationGstNumber, setLocationGstNumber] = useState("");
    const [BillingAddress, setBillingAddress] = useState([]);
    const [addressArray, setAddressArray] = useState([]);
    const [overAllDiscount, setOverAllDiscount] = useState([]);
    const [draftOrder, setDraftOrder] = useState([]);
    const [shippingDetails, setShippingDetails] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [documentsAttached, setDocumentsAttached] = useState([]);
    const [vendorContactDetails, setVendorContactDetails] = useState(null);
    const [locationContactDetails, setLocationContactDetails] = useState(null);

    // store deliveryRemark & notes in State
    const [notes, setNotes] = useState(null);
    const [showLocationDetail, setLocationDetail] = useState([]);
    const [locationIDAddress, setLocationIDAddress] = useState([]);
    const [locationBillingAddress, setLocationBillingAddress] = useState([]);

    // sendPIDraftOrder
    const navigate = useNavigate();
    const { Id: purchaseOrderName } = useParams();

    const inputRef = useRef(null);
    const handleClick = () => {
        inputRef.current.focus();
    };

    // sendExistOrder
    const [updateToggle, setUpdateToggle] = useState(true);
    const [updateDraftOrderId, setUpdateDraftOrderId] = useState([]);
    const [updateExistOrder, setUpdateExistOrder] = useState(false);
    const [showDiscountType, setDiscountType] = useState([])
    const [specificId, setSpecificId] = useState({});
    const [defaultFiles, setDefaultFiles] = useState([]);

    const [active, setActive] = useState(true);

    const onChangeActive = (e) => {
		setActive(e.target.checked);
	};

    const setLoadingPurchaseOrderCalculationServiceState = (value) => {
        setLoadingPurchaseOrderCalculationService(value);
    };

    const hideConfirmPopup = (event) => {
        setConfirmShowPopup(false)
    }

    // show TaxRate Popup
    const handleTaxRates = () => {
        setTaxRates(true);
        handleTaxRatesHelper(setTaxRates);
    };

    // hide TaxRate Popup
    const hideTaxPopup = () => {
        hideTaxPopupHelper(setTaxRates);
    };

    // show discount popup
    const showDiscountPopup = () => {
        showDiscountPopupHelper(setDiscountModal);
    };

    // hide Discount popup
    const hideDiscountPopup = () => {
        hideDiscountPopupHelper(setDiscountModal);
    };

    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('outline');
        if (container !== null) {
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const handleOutline = () => {
        document.getElementById('outline').style.border = '2px solid #458fff';
    };

    // remove product from array
    const updateArr = (Id) => {
        updateArrHelperPo(
            Id,productDetails,notExistProductShopifyId,setProductNotExistShopifyId,
            setProductNotExistShopify,setProductId,setProductDetails,setAddToggle,
            setVariantRole,variantRole,setProduct,setOverAllDiscount,setShippingPrice,
            setPreviousTarget
        );
    };

    // increase qty from product
    const incQty = (event, Id,bulkPacking1,bulkPacking2) => {
        incQtyHelperPoEdit(
            event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,
            setProductId,setProductDetails
        );
    };

    // decrease qty from product
    const decQty = (event, Id,bulkPacking1,bulkPacking2) => {
        decQtyHelperPoEdit(
            event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,setProductId,
            setProductDetails
        );
    };

    // Handle Manually Percentage
    const handleEditPercentage = (event) => {
        return handleEditHelper(event)
    };

    // Handle Blur Percentage
    const handleBlurPercentage = (event, Id, MRP, GST, originalUnitPrice) => {
        handleBlurPercentageHelper(
            event, Id, MRP, GST, originalUnitPrice,productDetails,
            updateDiscountPercent,setUpdateDiscountPercent,setAddToggle,setProductId,
            setProductDetails
        );
    };

    const handleEdit = (event) => {
        handleEditHelper(event);
    };

    // handle Blur Rate(Price)
    const handleBlur = (event, Id, previousUnit, MRP) => {
        handleBlurHelperPoEdit(
            event, Id, previousUnit, MRP,productDetails,setAddToggle,setProductId,
            setProductDetails
        )
    };

    // OnQty Blur Handled
    const handleKeyUp = (event) => {
        handleKeyUpHelper(event)
    }

    const handleBlurBulkPackagingQty1 = (event,Id,bulkPackagingValue,type,bulkPackagingValue2,restrictMsgId) => {
        handleBlurBulkPackagingQty1Helper(
            event, Id, bulkPackagingValue, type, bulkPackagingValue2, restrictMsgId,
            productDetails, setAddToggle, setProductId, setProductDetails
        )
    };

    const handleBlurBulkPackagingQty2 = (event,Id,bulkPackagingValue,type,bulkPackagingValue1,restrictMsgId) => {
        handleBlurBulkPackagingQty2Helper(
            event, Id, bulkPackagingValue, type, bulkPackagingValue1, restrictMsgId,
            productDetails, setAddToggle, setProductId, setProductDetails
        )
    };

    // increase qty from product
    const incQtyBulkPackaging = (event, Id, bulkPackagingValue, type) => {
        incQtyBulkPackagingHelper(
            event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
            setProductDetails
        )
    };

    const decQtyBulkPackaging = (event, Id, bulkPackagingValue, type) => {
        decQtyBulkPackagingHelperPoEdit(
            event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
            setProductDetails
        )
    };

    // handle Blur Qty
    const handleBlurQty = (event, Id, bulkPacking1, bulkPacking2, restrictMsgId) => {
        handleBlurQtyHelperPo(
            event, Id, bulkPacking1, bulkPacking2, restrictMsgId, productDetails, setAddToggle,
            setProductId, setProductDetails
        );
    };

    const formatNumber = (number) => {
        return formatNumberHelper(number);
    };

    // showLimitDiscount
    const showLimitDiscount = (mrp, originalUnitPrice, value) => {
        return showLimitDiscountHelper(mrp, originalUnitPrice, value);
    };

    const handleDisableSelect = () => {
        handleDisableSelectHelper()
    }

    const handleEnableSelect = () => {
        handleEnableSelectHelper();
    }

    // Open Window Print
    const handleSendPI = (event) => {
        handleSendPIHelper(event,overAllDiscount);
    }

    // Create draftOrder(Save as Draft Order)
    const handleCreateDraftOrder = (event) => {
        handleCreateDraftOrderHelper(
            event, showCustomerDetail, showLocationDetail, showProduct, productDetails, 
            customerIDAddress, BillingAddress, vendorGstNumber, locationIDAddress,
            locationBillingAddress, locationGstNumber, overAllDiscount, paymentDetails,
            shippingPrice, setShippingDetails, shippingDetails, documentsAttached,
            locationContactDetails, vendorContactDetails, notes, navigate,
            setIsCreateOrderPage, setLoading
        )
    }

    // Update Exist Order
    const handleUpdateOrder = (event) => {
        handleUpdateOrderHelper(
            event, showCustomerDetail, showLocationDetail, showProduct, productDetails, 
            customerIDAddress, BillingAddress, vendorGstNumber, locationIDAddress,
            locationBillingAddress, locationGstNumber, overAllDiscount, paymentDetails,
            shippingDetails, documentsAttached, locationContactDetails, vendorContactDetails,
            notes, navigate, specificId, setVendorContactDetails, setLocationContactDetails,
            defaultFiles, draftOrder, updateDraftOrderId,
            setIsCreateOrderPage, 'purchaseOrders'
        )
    }

    useEffect(() => {
        if (purchaseOrderName && updateToggle) {
            let timeoutId = setTimeout(() => {
                handleUpdateExistPurchaseOrderHelper(
                    purchaseOrderName, setUpdateExistOrder, setDraftOrder, setUpdateDraftOrderId, setShippingDetails,
                    setPaymentDetails, setVendorContactDetails, setVendorGstNumber, setLocationContactDetails, setNotes,
                    setLocationGstNumber, setSpecificId, setDefaultFiles, setUpdateDiscountPercent, setDiscountPercent,
                    setOverAllDiscount, setShippingPrice, setProductId, setProductDetails, setProduct,
                    handleEnableSelect, setUpdateToggle, setLineItems, setCustomerDetail, setCustomerIDAddress,
                    setBillingAddress, setLocationDetail, setLoadingPurchaseOrderCalculationServiceState
                )
            }, 500)
            return () => clearTimeout(timeoutId)
        }
    }, [purchaseOrderName, updateToggle])

    useEffect(() => {
        setProduct(showProduct)
    },[showProduct])


    useEffect(() => {
        if (addToggleBtn && productDetails?.length !== 0) {
            let timeoutId = setTimeout(() => {
                handlePoCalculationHelper(
                    lineItems, locationIDAddress, customerIDAddress, productDetails, overAllDiscount,
                    shippingPrice, setAddToggle, updateDiscountPercent, setDiscountPercent, 
                    editDiscountPercent, setLineItems, setProduct, setLoadingPurchaseOrderCalculationServiceState
                )
            }, 500)
            return () => clearTimeout(timeoutId)
        }
    }, [addToggleBtn, showProduct, overAllDiscount, productDetails, shippingPrice,
        updateDiscountPercent, editDiscountPercent, locationIDAddress, customerIDAddress,
        lineItems
    ]);

    useEffect(() => {
        setIsCreateOrderPage(true);
    }, [])

    const handleChange = (value, sku) => {
        handleChangeHelper(value, sku, lineItems, setLineItems);
    }

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <Fragment>
            {loading ? (
                <div
                    style={{
                        position:'fixed', top:'50%', left:'50%',
                        transform:'translate(-50%, -50%)',
                        backgroundColor:'rgba(0, 0, 0, 0.5)',
                        zIndex:'9999', height:'100%', width:'100%',
                        display:'flex', justifyContent: 'center',
                        alignItems:'center',
                    }}
                >
                    <Spinner animation="border" variant="light" />
                </div>
            ) : null}

            <div className={Classes.container} id="color">
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Autocomplete
                        options={districtOptions}
                        value={selectedDistrict}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSelectedDistrict(newValue);
                            }
                        }}
                        style={{ width: '20%' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select District for Price"
                                size="small"
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </div>
                {error ? (
                    <Alert variant="danger" onClose={() => setError(false)} dismissible>
                        <Alert.Heading>{error}</Alert.Heading>
                        <p>Try reloading!</p>
                    </Alert>
                ) : null}

                <div className={Classes.mainContainer} style={{ marginTop:30 }}>
                    <div className={Classes.containBoxes} id='createOrderProducts'>
                        <CreateOrderComponent 
                            setProductDetails={setProductDetails} 
                            setAddToggle={setAddToggle}
                            handleEdit={handleEdit}
                            handleBlur={handleBlur}
                            handleOutline={handleOutline}
                            showProduct={showProduct}
                            lineItems={lineItems}
                            handleEditPercentage={handleEditPercentage}
                            handleBlurPercentage={handleBlurPercentage}
                            showLimitDiscount={showLimitDiscount}
                            handleChange={handleChange}
                            handleBlurQty={handleBlurQty}
                            incQty={incQty}
                            decQty={decQty}
                            handleBlurBulkPackagingQty1={handleBlurBulkPackagingQty1}
                            handleKeyUp={handleKeyUp}
                            incQtyBulkPackaging={incQtyBulkPackaging}
                            decQtyBulkPackaging={decQtyBulkPackaging}
                            handleBlurBulkPackagingQty2={handleBlurBulkPackagingQty2}
                            updateArr={updateArr}
                            onChangeActive={onChangeActive}
                            active={active}
                            componentType={'Purchase Order'}
                        />

                        <PaymentDetailsComponent 
                            showProduct={showProduct}
                            showDiscountPopup={showDiscountPopup}
                            handleClick={handleClick}
                            handleTaxRates={handleTaxRates}
                            showCustomerDetail={showCustomerDetail}
                            showLocationDetail={showLocationDetail}
                            updateExistOrder={updateExistOrder}
                            notExistProductShopify={notExistProductShopify}
                            handleCreateDraftOrder={handleCreateDraftOrder}
                            handleSendPI={handleSendPI}
                            handleUpdateOrder={handleUpdateOrder}
                            buttonText={"Save as Draft Purchase Order"}
                            loadingPurchaseOrderCalculationService={loadingPurchaseOrderCalculationService}
                            active={active}
                        />

                        {showProduct && (
                            <div id='TaxRatesPrint' style={{ display: 'none' }}>
                                <TaxRatesPrint
                                    showProduct={showProduct}
                                    showCustomerDetail={showCustomerDetail}
                                    addressArray={addressArray}
                                    customerIDAddress={customerIDAddress}
                                />
                            </div>
                        )}

                        <div>
                            <ConfirmPopup
                                showConfirmPopup={showConfirmPopup}
                                hideConfirmPopup={hideConfirmPopup}
                            />
                        </div>
                    </div>
                    <div className={Classes.asideContainer} id='DraftPOAsideContainer'>
                        <PoEditAside
                            draftOrder={draftOrder}
                            setShippingDetails={setShippingDetails}
                            shippingDetails={shippingDetails}
                            setShippingPrice={setShippingPrice}
                            productDetails={productDetails}
                            overAllDiscount={overAllDiscount}
                            setLoadingPurchaseOrderCalculationServiceState={setLoadingPurchaseOrderCalculationServiceState}
                            inputRef={inputRef}
                            setProduct={setProduct}
                        />
                    </div>
                </div>
            </div>

            <div className={discountModal ? Classes.UpdateSalesDialog : undefined}>
                <DiscountPopup
                    discountModal={discountModal}
                    setDiscountModal={setDiscountModal}
                    showDiscountPopup={showDiscountPopup}
                    hideDiscountPopup={hideDiscountPopup}
                    productDetails={productDetails}
                    setProduct={setProduct}
                    overAllDiscount={overAllDiscount}
                    shippingPrice={shippingPrice}
                    showDiscountType={showDiscountType}
                    setDiscountType={setDiscountType}
                    setOverAllDiscount={setOverAllDiscount}
                    setLoadingPurchaseOrderCalculationServiceState={setLoadingPurchaseOrderCalculationServiceState}
                />
            </div>

            <div className={showTaxRate ? Classes.UpdateSalesDialog : undefined}>
                <TaxRatesPopup
                    showTaxRate={showTaxRate}
                    hideTaxPopup={hideTaxPopup}
                    draftOrder={showProduct ? {DpoTaxLines:showProduct?.taxLines,DpoPaymentDetails: {totalTaxAmount: showProduct?.totalTaxAmount}} : draftOrder}
                />
            </div>
        </Fragment>
    );
};

export default EditPurchaseOrder;