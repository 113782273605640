import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';

const UpdateBillingPopUp = (props) => {
    const [states,setStates] = useState([]);

    const {
        hideUpdateBillingPop,
        updateBillingModal,
        showCustomerDetail,
        setBillingAddress,
        BillingAddress,
        updateExistOrder,
        addressType,
        addressArray,
        setAddressArray,
        type
    } = props;


    const handleFormSubmit = (event) => {
        event.preventDefault();
        hideUpdateBillingPop();

        let lastName;
        if (event.target.last.value) {
            lastName = event.target.last.value.trim();
        } else {
            lastName = "";
        }
        let data = {
            firstName: event.target.first.value.trim(),
            lastName: lastName,
            addressLine1: event.target.address1.value,
            addressLine2: event.target.address2.value,
            city: event.target.city.value,
            pincode: event.target.pincode.value,
            state: event.target.state.value,
            addressType: addressType,
            phoneNumber: event.target.phone.value? event.target.phone.value: undefined,

        }

        if (type === 'Vendor') {

            axiosInstance
                .put(`/vendor/vendorAddress/${BillingAddress[0].vendorAddressesId}`, data)
                .then((res) => {
                    let newAddresses = addressArray.filter((address) => address.vendorAddressesId !== BillingAddress[0].vendorAddressesId);
                    setBillingAddress([res.data.data]);
                    setAddressArray([...newAddresses, res.data.data]);
                });
        } else {
            axiosInstance
                .put(`/location/locationAddress/${BillingAddress[0].locationAddressesId}`, data)
                .then((res) => {
                    let newAddresses = addressArray.filter((address) => address.locationAddressesId !== BillingAddress[0].locationAddressesId);
                    setBillingAddress([res.data.data]);
                    setAddressArray([...newAddresses, res.data.data]);
                });
        }
    }

    const handleMinLengthPincode = (event) => {
        const pincode = /^\d{6}$/;
        if (event.target.value.match(pincode)) {
            document.getElementById('pinErrMsg2').style.display = 'none';
            document.getElementById('btn').disabled = false;
        } else {
            document.getElementById('btn').disabled = true;
            document.getElementById('pinErrMsg2').style.display = 'block';
        }
    }
    const handleMinLengthphoneNumber = (event) => {
        const phoneNo = /^\d{10}$/;
        if (event.target.value.length === 0) {
            return
        }
        if(event.target.value.match(phoneNo)){
            document.getElementById('phoneErrMsg2').style.display = 'none';
        }else{
            document.getElementById('phoneErrMsg2').style.display = 'block';
        }
    }

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setStates(response.data.data);
            })
    },[])

    return (
        <Fragment>
            {updateBillingModal && (
                <div className={Classes.modal} style={{ bottom:'10%',top:'3%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Edit Billing Address</p>
                    </div>

                    <form method="post" onSubmit={handleFormSubmit} id='form'>
                        <div className={Classes.AddressPopUp}>
                            <div style={{ marginTop: '10px' }}>
                                <TextField 
                                    id='country'
                                    name='country'
                                    label='Country / Origin'
                                    value='India'
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top:"0%",fontSize:14},"& .MuiOutlinedInput-input":{padding:'6px 10px'}}}
                                    disabled={true}
                                />
                            </div>

                            <div style={{ display:'flex',marginTop:15,justifyContent:'space-between' }}>
                                <div style={{ width:'48%' }}>
                                    <TextField 
                                        required={true}
                                        id='first'
                                        name='first'
                                        label='First Name'
                                        autoComplete='off'
                                        key={BillingAddress[0]?.firstName}
                                        defaultValue={BillingAddress[0]?.firstName}
                                        placeholder='Enter Your First Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width:'48%' }}>
                                    <TextField 
                                        id='last'
                                        name='last'
                                        label='Last Name'
                                        autoComplete='off'
                                        key={BillingAddress[0]?.lastName}
                                        defaultValue={BillingAddress[0]?.lastName}
                                        placeholder='Enter Your Last Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop:15 }}>
                                <TextField 
                                    required={true}
                                    id='address1'
                                    name='address1'
                                    label='Address 1'
                                    autoComplete='off'
                                    key={BillingAddress[0]?.addressLine1}
                                        defaultValue={BillingAddress[0]?.addressLine1}
                                    placeholder='Enter Your Address'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                <TextField
                                    id='address2'
                                    name='address2'
                                    label='Address 2'
                                    autoComplete='off'
                                    placeholder='Apartment,Suite,etc'
                                    key={BillingAddress[0]?.addressLine2}
                                    defaultValue={BillingAddress[0]?.addressLine2}
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ display:'flex',marginTop:15,justifyContent:'space-between' }}>
                                <div style={{ width:'48%' }}>
                                    <TextField 
                                        required={true}
                                        id='city'
                                        name='city'
                                        label='City'
                                        key={BillingAddress[0]?.city}
                                        defaultValue={BillingAddress[0]?.city}
                                        autoComplete='off'
                                        placeholder='Enter Your City'
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField 
                                        required={true}
                                        id='pincode'
                                        name='pincode'
                                        label='Pincode'
                                        autoComplete='off'
                                        placeholder='Enter Your Pincode'
                                        maxLength={6}
                                        type="tel"
                                        sx={{ width: '100%'}}
                                        inputProps={{maxLength:6}}
                                        key={BillingAddress[0]?.pincode}
                                        defaultValue={BillingAddress[0]?.pincode}
                                        onBlur={(e) => handleMinLengthPincode(e)}
                                    />

                                    <div
                                        className={Classes.PhoneErrorMsg}
                                        id='pinErrMsg2'
                                        style={{ fontSize: '14px' }}
                                    >
                                        <p>Please enter a valid 6 digit pincode</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display:'flex',marginTop:15,justifyContent:'space-between' }}>
                                <div style={{ width:'48%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="state"
                                        name='state'
                                        options={states}
                                        key={BillingAddress[0]?.state}
                                        defaultValue={BillingAddress[0]?.state}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="States" required/>}
                                    />
                                </div>
                                <div style={{width: '48%'}}>
                                    <TextField 
                                        type="tel"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        maxLength={10}
                                        sx={{ width: '100%'}}
                                        inputProps={{maxLength:10}}
                                        key={BillingAddress[0]?.phoneNumber}
                                        defaultValue={BillingAddress[0]?.phoneNumber}
                                        onBlur={(e) => handleMinLengthphoneNumber(e)}
                                    />

                                    <div 
                                        className={Classes.PhoneErrorMsg} 
                                        id='phoneErrMsg2' 
                                        style={{fontSize:'15px'}}
                                    >
                                        <p>Please enter a valid 10 digit mobile number</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom: "10%"}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideUpdateBillingPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>

                    </form>
                </div>
            )}
        </Fragment>
    );
}


export default UpdateBillingPopUp;