import { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import axiosInstance from "../../../utility/axios-instance";
import { BiArrowBack } from "react-icons/bi";
import { Spinner } from "react-bootstrap";
import Classes from "../ordersNew/css/orders.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import fileDownload from "js-file-download";
import Classes2 from "./css/estimateId.module.scss";
import Classes3 from "../affiliateDashboard/scss/createCommission.module.scss";
import { services } from "../../../utility/checkRoleBasedPermission";
import Permission from "../../auth/permissions";
import { useSelector } from "react-redux";
import socket from '../../../socket';

const EstimateId = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [estimate, setEstimate] = useState(null);
  const [draftOrder, setDraftOrder] = useState(null);
  const [order, setOrder] = useState(null);

  const [text, setText] = useState("");
  const inputRef = useRef();
  const [showModal, setShowModal] = useState(false);

  const [loading2, setLoading2] = useState(false);
  const [admins, setAdmins] = useState([]);

  const user = useSelector((state) => state?.auth?.user);

  // eslint-disable-next-line no-unused-vars
  const [pagination, setPagination] = useState({
    page: 1,
    maxPage: 1,
    total: 0,
  });
  const apiRequest = useRef();

  const getDraftOrder = async () => {
    if (!estimate?.draftOrderName) return;
    axiosInstance
      .get(`/pgOrder/admin/draftOrder/${estimate.draftOrderName}`)
      .then((res) => {
        setDraftOrder(res.data.data.draftOrder);
      })
      .catch((err) => {});
  };

  const getOrder = async () => {
    if (!estimate?.draftOrderName) return;
    axiosInstance
      .get(`/pgOrder/admin/order/${estimate.orderName}`)
      .then((res) => {
        setOrder(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getDraftOrder();
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimate]);

  const years = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dn = new Date();

  const createdAt = (createdAt) => {
    const date = new Date(createdAt);
    let createdAtDate =
      date.getFullYear() === dn.getFullYear()
        ? date.getMonth() === dn.getMonth()
          ? date.getDate() === dn.getDate()
            ? `Today at ${date.toLocaleTimeString()}`
            : date.getDate() === dn.getDate() - 1
            ? `Yesterday at ${date.toLocaleTimeString()}`
            : years[date.getMonth()] +
              " " +
              date.getDate() +
              " at " +
              date.toLocaleTimeString()
          : years[date.getMonth()] +
            " " +
            date.getDate() +
            " at " +
            date.toLocaleTimeString()
        : years[date.getMonth()] + " " + date.getFullYear();

    return createdAtDate;
  };

  const navigate = useNavigate();

  const { id } = useParams();

  const fetchEstimate = async () => {
    if (id) {
      setLoading(true);
      axiosInstance
        .get(`/estimate/admin/${id}`)
        .then((response) => {
          let ti = 0;
          for (let i = 0; i < response?.data?.data?.products?.length; i++) {
            ti += response?.data?.data?.products?.[i].quantity;
          }
          setTotalItems(ti);
          setEstimate(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    fetchEstimate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchAdmins = (page = 1, searchText = "", pagination = false) => {
    setLoading2(true);

    if (apiRequest.current) apiRequest.current?.abort();

    apiRequest.current = new AbortController();

    axiosInstance
      .get(`/admin?page=${page}&text=${searchText}`, {
        signal: apiRequest.current?.signal,
      })
      .then((res) => {
        // console.log(res.data.data);
        if (pagination) {
          setAdmins((prev) => [...prev, ...res.data.data.users]);
          setPagination({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        } else {
          setAdmins(res.data.data.users);
          setPagination({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    fetchAdmins(1, text);
  }, [text]);

  const updateEstimate = async (data) => {
    setLoading(true);

    axiosInstance
      .post(`/estimate/admin/${id}`, data)
      .then((response) => {
        let totalItemQuantity = 0;
        totalItemQuantity = response?.data?.data?.products?.reduce((acc, product) => acc + product.quantity, 0);
        setTotalItems(totalItemQuantity); 
        setEstimate(response.data.data);
        socket?.emit("New Notification", user);
      })
      .catch((err) => {
        setError(err.response?.data?.message ?? "Something went wring");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {loading ? (
        <div className={Classes.Spinner}>
          <Spinner animation='border' variant='light' />
        </div>
      ) : null}
      <div className={Classes.container} id='color'>
        <div className='d-flex align-items-center' style={{ gap: "1rem" }} id='hideHeader'> 
          <div>
            <button
              type='button'
              className='btn btn-light'
              onClick={() => { navigate("/estimates") }}
            >
              <BiArrowBack />
            </button>
          </div>

          {estimate ? (
            <h6 className={Classes.draftOrderDetail}>
              {createdAt(estimate.createdAt)}
            </h6>
          ) : null}
        </div>

        {error ? (
          <Alert variant='danger' onClose={() => setError(false)} dismissible>
            <Alert.Heading>{error}</Alert.Heading>
          </Alert>
        ) : null}

        <div style={{ marginTop:"-20px", display:"flex", alignItems:"center", justifyContent:"flex-end", gap:"10px", width:'99%' }}>
          <Permission service={services?.estimates} permission={"create"}>
            {!estimate?.draftOrderName && estimate?.status === "Pending" ? (
              <Link
                to={estimate?.rejected ? "" : `/createOrder?estimateId=${estimate.estimateId}`}
                aria-disabled={estimate?.rejected}
                target='_blank'
                style={{
                  outline: "none",
                  border: "none",
                  borderRadius: "8px",
                  backgroundColor: "green",
                  color: "white",
                  padding: "4px 16px",
                  opacity: estimate.rejected ? "0.5" : "1",
                  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                }}
              >
                Create Order
              </Link>
            ) : null}
          </Permission>

          <Permission service={services?.estimates} permission={"delete"}>
            {!estimate?.orderName ? (
              <button
                style={{
                  outline: "none",
                  border: "none",
                  borderRadius: "8px",
                  backgroundColor: "red",
                  color: "white",
                  padding: "4px 16px",
                  opacity: estimate?.rejected ? "0.5" : "1",
                  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                }}
                disabled={estimate?.status === "Rejected"}
                onClick={() => { updateEstimate({ status: "Rejected" }) }}
              >
                {estimate?.status === "Rejected" ? "Rejected" : "Reject"}
              </button>
            ) : null}
          </Permission>
        </div>

        {estimate ? (
          <div
            className={`mt-4 mb-4 d-flex align-items-start ${Classes2.C}`}
            style={{ gap:"1rem", flexWrap:"wrap" }}
          >
            <div className={Classes2.CC} style={{ width:'74%' }}>
              {estimate?.products ? (
                <div
                  className='pt-3 rounded flex-grow-1'
                  style={{ boxShadow: "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)" }}
                > 
                  <div className='d-flex' style={{ gap: ".5rem", padding:'0px 10px',borderBottom:'1px solid #DBDDE0' }}>
                    <h6 style={{ fontSize: "14px" }}>S.No</h6>
                    <h6 style={{ marginLeft: "1rem" }}>Products</h6>
                  </div>
                
                  {estimate?.products.map((product, index) => {
                    return (
                      <div
                        key={product.productId}
                        className='border-bottom fs-6 d-flex align-items-start'
                        style={{ gap: ".5rem", padding:'8px 10px' }}
                      >
                        <div style={{ fontSize: "13px", color: '#6d7175' }}>
                          #{index + 1}.
                        </div>
                        <div style={{ marginTop:5 }}>
                          <img
                            src={
                              product?.mainImageUrl && product?.mainImageUrl !== ""
                                ? product?.mainImageUrl
                                : process.env.REACT_APP_IMAGE_COMMING_SOON
                            }
                            alt=''
                            style={{ width:45, height:45, border:"1px solid #ced4da", borderRadius: 4 }}
                          />
                        </div>
                        <div style={{ fontSize: "13px", color: "#6d7175" }}>
                          <p style={{ color: "#0d6efd" }}>{product?.productName}</p>
                          <p style={{ color: "#f66200" }}>{product?.brandName}</p>
                          <p>{product?.sku}</p>
                          <p>Quantity: {product.quantity.toString().padStart(2, "0")}</p>
                          <p style={{ color:"#0d6efd" }}>₹{product?.price || 0}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {estimate?.attachments && estimate?.attachments?.length > 0 ? (
                <div
                  className='pt-3 rounded flex-grow-1'
                  style={{ boxShadow: "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)" }}
                >
                  <h6 style={{ paddingLeft:"1rem" }}>
                    Attachments
                  </h6>
                  {estimate?.attachments?.map((a, index) => {
                    return (
                      <div
                        key={a}
                        className='p-3 mt-2 border-bottom d-flex align-items-start justify-content-between'
                        style={{ gap:".5rem", cursor:"pointer", color:"#6d7175", fontSize:"14px !important" }}
                        onClick={() => {
                          Axios.get(estimate.attachments2[index], {
                            responseType: "blob",
                          }).then((res) => {
                            fileDownload(res.data, a);
                          });
                        }}
                      >
                        <p>{a}</p>
                        <div>
                          <FontAwesomeIcon icon='fa-solid fa-download' />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {order ? (
                <Link
                  to={`/ordersNew/${order?.orderName}`}
                  className='p-3 mt-4 rounded flex-grow-1'
                  target='_blank'
                  style={{ display:"block", color:"black", boxShadow:"0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)" }}
                >
                  <h6>Order</h6>
                  <div
                    className='d-flex align-items-start'
                    style={{ gap:"1rem", fontSize:"14px", color:"#6d7175" }}
                  >
                    <p>{order.orderName}</p>
                    <p style={{ color:"blue" }}>{order.status}</p>
                    <p style={{ color:"#ff6200" }}>₹{order.OPaymentDetail.totalPrice}</p>
                  </div>
                </Link>
              ) : null}

              {draftOrder ? (
                <Link
                  to={`/draftOrdersNew/${draftOrder.draftOrderName}`}
                  target='_blank'
                  className='p-3 mt-4 rounded flex-grow-1'
                  style={{
                    display: "block",
                    color: "black",
                    boxShadow: "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)",
                  }}
                >
                  <h6>Draft Order</h6>

                  <div
                    className='d-flex align-items-start'
                    style={{ gap:"1rem", fontSize:"14px", color:"#6d7175" }}
                  >
                    <p>{draftOrder.draftOrderName}</p>
                    <p style={{ color:"blue" }}>{draftOrder?.status}</p>
                    <p style={{ color:"#ff6200" }}>₹{draftOrder?.DoPaymentDetail?.totalPrice}</p>
                  </div>
                </Link>
              ) : null}
            </div>

            <div className={Classes2.CC} style={{ width:'24%' }}>
              <div
                className={`px-3 py-2 rounded border`}
                style={{ boxShadow:"0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)", minWidth:"260px" }}
              >
                <h6>CUSTOMER</h6>
                <div className='mt-2' style={{ fontSize:"14px", color:"#6d7175" }}>
                  <p>{estimate?.displayName}</p>
                  <p>{estimate?.phone}</p>
                  <p>{estimate?.email}</p>
                </div>
              </div>

              <div
                className={`px-3 py-3 rounded border mt-3`}
                style={{ boxShadow:"0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)", minWidth:"260px" }}
              >
                <h6>Assign To</h6>
                <div
                  className={Classes2.OrderTextSearch}
                  onClick={() => { 
                    inputRef?.current?.focus();
                    setShowModal(true);
                  }}
                >
                  <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
                  <div>
                    <input
                      type='text'
                      placeholder='Search Orders'
                      readOnly
                      value={text}
                    />
                  </div>
                </div>
              </div>

              {estimate?.assignedTo ? (
                <div
                  className={`px-3 py-2 rounded border mt-3`}
                  style={{ boxShadow:"0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)", minWidth:"260px" }}
                >
                  <h6>ASSIGNED TO</h6>
                  <div className='mt-2' style={{ fontSize:"14px", color:"#6d7175" }}>
                    <p>{estimate?.assignedToUserName}</p>
                    <p>{estimate?.assignedToName}</p>
                  </div>
                </div>
              ) : null}

              {estimate?.comment ? (
                <div
                  className='py-3 mt-3 rounded flex-grow-1'
                  style={{ boxShadow: "0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181)" }}
                >
                  <h6 style={{ paddingLeft:"1rem" }}>Comment</h6>
                  <pre
                    className='mt-3'
                    style={{ paddingLeft:"1rem", color:"#6d7175", fontSize: "14px !important" }}
                  >
                    {estimate.comment ?? ""}
                  </pre>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>

      <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
        {showModal && (
          <div className={Classes3.Modal}>
            <div className={Classes3.Head}>
              <h5>All Admins</h5>
              <FontAwesomeIcon
                icon='fa-solid fa-xmark'
                size='xl'
                style={{ cursor: "pointer" }}
                onClick={() => { setShowModal(false) }}
              />
            </div>

            <div
              className={Classes3.OrderTextSearch}
              onClick={() => { inputRef?.current?.focus() }}
            >
              <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
              <div>
                <input
                  ref={inputRef}
                  type='text'
                  placeholder='Search Admin'
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              className={Classes3.Orders}
              style={{ height: "calc(100% - 2rem - 32px - 36px)" }}
            >
              {admins?.map((adm) => {
                return (
                  <div
                    key={adm?._id}
                    className='border-bottom px-4 py-2'
                    style={{ cursor:"pointer" }}
                    onClick={() => {
                      updateEstimate({ assignedTo: adm?._id });
                      setShowModal(false);
                    }}
                  >
                    <h6 className='mb-0'>{adm?.username}</h6>
                    <div className='mt-2' style={{ fontSize: "14px" }}>
                      <p>{adm?.name}</p>
                      <p>{adm?.role}</p>
                    </div>
                  </div>
                );
              })}

              {loading2 && <p className='my-1'>Loading ...</p>}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default EstimateId;