import { Fragment, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import Button from '@mui/material/Button';
import ConfirmDialog from './confirmDialog';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Snackbar from '@mui/material/Snackbar';
import FormDialog from '../users/Components/formDialog';
import Table from '../../../components/table/table';
import { setAffiliates } from '../../../store/affiliates';
import { useSelector } from 'react-redux';
import { Switch } from "@mui/material";

const Affiliate = (props) => {
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const [confirmDialog,setConfirmDialog] = useState(false);
    const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
    const [customer,setCustomer] = useState('');
    const [Id,setId] = useState('');
    const [reRender, setReRender] = useState(false);

    const affiliates = useSelector((state) => state.affiliates);

    const showConfirmDialog = (customer) => {
        setConfirmDialog(true);
        setCustomer(customer);
    }

    const hideConfirmDialog = () => {
        setConfirmDialog(false);
    }

    const SalesUserRenderCell = (row)=> {
        return row?.salesUser ? row?.field_sales_user :'N/A';
    }

    const PreSalesUserRenderCell = (row) => {
        return row?.preSalesUser ? row?.field_sales_user : 'N/A';
    }

    const IsVerifiedRenderCell = (row) => {
        return (
            <div>
                {row?.isVerified ? 
                    <VerifiedUserIcon style={{color:'green'}} /> 
                : (
                    <Permission service={services?.affiliates} permission={"create"}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ textTransform:'capitalize' }}
                            style={{ padding:'2px 10px',fontSize:14 }}
                            onClick={(event) => handleVerifyDialog(event, row?.id)}
                        >
                            Verify
                        </Button>
                    </Permission>
                )}
            </div>
        )
    }

    const ActiveRenderCell = (row) => {
        return (
            <Permission service={services?.affiliates} permission={"delete"}>
                <Switch
                    name="active"
                    checked={row?.active}
                    onClick={() => showConfirmDialog(row)}
                    id="flexSwitchCheckChecked"
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </Permission>
        )
    }

    const columns = [
        {
            field: 'display_name',
            headerName: 'Name',
            flex: 1.6,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{textTransform:'capitalize'}}>{params?.row?.display_name}</div>
            )
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'customer_role',
            headerName: 'Role',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.customer_role?.toTitleCase()}</div>
            )
        },
        {
            field: 'customer_code',
            headerName: 'Code',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'field_sales_user',
            headerName: 'Sales User',
            flex: 1.1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <SalesUserRenderCell {...params?.row} /> ),
            customRenderCell: SalesUserRenderCell
        },
        {
            field: 'pre_sales_user',
            headerName: 'Pre Sales User',
            flex: 1.1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( <PreSalesUserRenderCell {...params?.row} />),
            customRenderCell: PreSalesUserRenderCell
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1.7,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'isVerified',
            headerName: 'Verified',
            flex: 0.5,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            align: 'center',
            renderCell: (params) => ( <IsVerifiedRenderCell {...params?.row} />),
            customRenderCell: IsVerifiedRenderCell
        },
        {
            field: 'active',
            headerName: 'Active',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <ActiveRenderCell {...params?.row} />),
            customRenderCell: ActiveRenderCell
        },
    ];

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    const handleVerifyDialog = (event,id) => {
		event.preventDefault()
		setOpenVerifyDialog(true);
        setId(id);
	};

    
	const handleVerify = async () => {
        await axiosInstance
            .put(`/affiliates/${Id}`, {
                isVerified: true
            })
            .then((res) => {
                const successMessage = "Customer Verified Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setReRender(!reRender);
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setReRender(!reRender);
            })
        setOpenVerifyDialog(false)
	};

	const handleCloseVerifyDialog = () => {
		setOpenVerifyDialog(false)
	}

	return (
		<Fragment>
			<div  id="color">
                <FormDialog 
                    open={openVerifyDialog} 
                    handleClose={handleCloseVerifyDialog} 
                    handleSubmit={handleVerify} 
                    dialogTitle={`Verify Affiliate`} 
                    dialogContent={"Are You Sure You Want To Verify Affiliate?"} 
                />

                <Table
                    columns={columns}
                    dataPosition={"affiliateData"}
                    endpoint={`/affiliates`}
                    rowId={'id'}
                    checkBoxSelection={false}
                    version="version-1"
                    reRender={reRender}
                    service={'affiliates'}
                    tabName={'Affiliate'}
                    setData={setAffiliates}
                    data={affiliates}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/fetchAffiliates'}
                    exportFileName={`Affiliates Report- ${new Date()}`}
                />
            </div>

            <div className={confirmDialog ? Classes.UpdateSalesDialog : undefined}>
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    customer={customer}
                    hideConfirmDialog={hideConfirmDialog}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default Affiliate;
