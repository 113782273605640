import * as React from 'react';
import Table from '../../../components/table/table';
import { Fragment, useState } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import { setSkuTransactions } from '../../../store/skuTransactionsSlice';
import { Switch, Tooltip } from '@mui/material';

const columnVisibilityModel = {
    receivingBinCode: false,
    fromBinCode: false,
    toBinCode: false,
} 

const TransactionQtyRenderCell = (row) => {
    return (
        <>
            {parseInt(row.txnQty) > 0  ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
            <span style={{color:parseInt(row.txnQty) > 0  ? row?.txnType === 'Dispatch' || 
            (row?.txnType === 'Movement' && row?.direction === 'within')? 'black':'green' : 'red'}}>{row.txnQty}</span>
        </>
    )
}

const columns = [
    {
        field: `sku`,
        headerName: `SKU`,
        flex: 1.4,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `title`,
        headerName: `Title`,
        flex: 1.4,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `txnQty`,
        headerName: `Transaction Quantity`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
        renderCell: (params) => ( <TransactionQtyRenderCell {...params?.row} />),
        customRenderCell: TransactionQtyRenderCell
    },
    {
        field: `txnType`,
        headerName: `Transaction Type`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
        enumValues: [
            "Adjustment",
            "Receiving",
            "Allocate",
            "Unallocate",
            "Movement",
            "Dispatch"
        ],
    },
    {
        field: `direction`,
        headerName: `Direction`,
        flex: 0.8,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
        enumValues: [
            "In",
            "out",
            "within"
        ],
    },
    {
        field: `receivingBinCode`,
        headerName: `Receiving Bin Code`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `fromBinCode`,
        headerName: `From Bin`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `toBinCode`,
        headerName: `To Bin`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `locationCode`,
        headerName: `Location Code`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `point_in_time_inventory`,
        headerName: `Point-In-Time Inv. (Avl. Qty)`,
        flex: 1.2,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `createdBy`,
        headerName: `Created By`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `createdAt`,
        headerName: `Created At`,
        flex: 1.6,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
];

export default function SkuTransactions() {
    const [isMovementWithinVisible, setIsMovementWithinVisible] = useState(true);
    const skuTransactions = useSelector((state) => state.skuTransactions);

    return (
        <Fragment>
            <Table 
                rowId={'id'} 
                columns={columns} 
                dataPosition={"fetchedSkuTransactions"} 
                endpoint={`/inventory/fetchSkuTransactions?movementWithin=${isMovementWithinVisible}`} 
                checkBoxSelection={false}
                service={'skuTransactions'}
                columnVisibilityModel={columnVisibilityModel}
                tabName={'SKU Transactions'}
                setData={setSkuTransactions}
                data={skuTransactions}
                isMovementWithinVisible={isMovementWithinVisible}
                extraToolBarButtons={[
                    <Tooltip title="Filter Inventory Moved Within" placement="top">
                        <Switch defaultChecked onChange={() => { setIsMovementWithinVisible((prev) => !prev) }} />
                    </Tooltip>
                ]}
                mobileResponsive={true}
                exportEndpoint={`/exports/fetchSkuTransactions?movementWithin=${isMovementWithinVisible}`}
                exportFileName={`SKU Transactions Report- ${new Date()}`}
            />
        </Fragment>
    );
}