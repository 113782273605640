import {Fragment, useState, useEffect} from 'react';
import Alert from 'react-bootstrap/Alert';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import Classes from './css/customerProfile.module.css';
import Aside from '../customers/aside/aside';
import CustomerOrderDetails from '../../../components/customerOrderDetails';
import { useSelector, useDispatch  } from 'react-redux';
import { setBreadcrumb } from '../../../store/breadcrumbSlice';
import Ledger from './customerLedger';


const CustomerProfile = (props) => {
    const [error, setError] = useState(null);
    const [showCustomerProfile,setCustomerProfile] = useState([]);
    const [orders, setOrders] = useState([]);
    const [totalAmount, setTotalAmount] = useState([]);
    const [totalOrders, setTotalOrders] = useState([]);
    const [avgAmount, setAvgAmount] = useState([]);
    const [address, setAddress] = useState([]);
    const [addressArray, setAddressArray] = useState([]);
    const [toggle,setToggle] = useState(true);

    const {customerId} = useParams();
    const breadCrumb = useSelector((state) => state.breadcrumb);
	const dispatch = useDispatch();


    useEffect(() => {
        if (toggle) {
            axiosInstance
            .get(`/user/admin/customerDetail/${customerId}`)
            .then((res) => {
                setToggle(false);
                if (res.data.data.length !== 0) {
                    setTotalAmount(res.data.totalAmount);
                    setTotalOrders(res.data.totalOrders);
                    setAvgAmount(res.data.avgAmount);
                    if(res.data.address) {
                        let addressArr = [];
                        for (let address of res.data.address) {
                            if (address.default === true) {
                                addressArr.unshift(address);
                            } else {
                                addressArr.push(address);
                                setAddressArray(addressArr);
                            }
                        }
                    }
                }
                setOrders(res.data.orders);
                let name = res?.data?.data?.display_name ?
                    res?.data?.data?.display_name :
                    res?.data?.data?.first_name + " " + res?.data?.data?.last_name && res?.data?.data?.last_name
                dispatch(setBreadcrumb({ ...breadCrumb, customerName: name }));
                setCustomerProfile(res.data.data);
            })
        }
    },[customerId, addressArray,setAddressArray, toggle])

	return (
		<Fragment>
			<div className={Classes.Container} style={{ maxWidth:'98%'}}>
				{error ? (
					<Alert variant="danger" onClose={() => setError(false)} dismissible>
						<Alert.Heading>{error}</Alert.Heading>
						<p>Try reloading!</p>
					</Alert>
				) : null}

                <Ledger selectedCustomer={customerId} />

                <div className={Classes.mainContainer} style={{marginTop:10}}>
                    <div className={Classes.ContainerBox}>
                        <CustomerOrderDetails 
                            showCustomerProfile={showCustomerProfile}
                            orders={orders}
                            totalAmount={totalAmount}
                            totalOrders={totalOrders}
                            avgAmount={avgAmount}
                        />
                    </div>
                    
                    <div className={Classes.Aside}>
                        {showCustomerProfile?.length !== 0 && (
                            <Aside 
                                showCustomerProfile={showCustomerProfile}
                                setCustomerProfile={setCustomerProfile}
                                orders={orders}
                                address={address}
                                setAddress={setAddress}
                                addressArray={addressArray}
                                setAddressArray={setAddressArray}
                            />
                        )}
                    </div> 
                </div>
            </div>
		</Fragment>
	)				
};

export default CustomerProfile;
