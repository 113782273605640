import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setOrders } from '../../../store/ordersSlice';
import moment from 'moment';
import Table from '../../../components/table/table';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { useSelector, useDispatch  } from 'react-redux';
import { setBreadcrumb } from '../../../store/breadcrumbSlice';
import { Button } from '@mui/material';
import SnackBar from '../../../components/SnackBar';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';

const OrdersNew = () => {
    const {id} = useParams();
    const location = useLocation();
    const locations = location.pathname.split("/").slice(1)
    const auth = useSelector((state) => state.auth);
    const orders = useSelector((state) => state?.orders);
    const [locationCodes, setLocationCodes] = useState(auth?.user?.settings?.locationSetting || []); // locationCodes
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [reRender, setReRender] = useState(false);

    const breadCrumb = useSelector((state) => state.breadcrumb);
	const dispatch = useDispatch();

    let endpoint = `/pgOrder/admin/orders`;
    if (locations[1] === 'customers') {
        endpoint += `/customers/${id}`
    }

    const columnVisibilityModel = {
        orderPaymentStatus: false,
        locationCode: false,
        createdVia: false,
        orderCancelledAt: false,
        customerPhoneNumber: false,
    }

    const fetchLocations = ()=>{
        // fetch locations
        axiosInstance
            .get('/location/locations')
            .then((res) => {
                const locations = res?.data?.data;
                const locationCodes = locations.map((location) => location?.locationCode);
                setLocationCodes(locationCodes);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchCustomerDetails = ()  => {
        axiosInstance
            .get(`/user/admin/${id}`)
            .then((res) => {
                let name = res?.data?.data?.display_name ? res?.data?.data?.display_name : res?.data?.data?.first_name + " " + res?.data?.data?.last_name && res?.data?.data?.last_name
                dispatch(setBreadcrumb({ ...breadCrumb, customerName: name }));
            })
    }

    useEffect(() => {
        if(["super-admin", "admin"].includes(auth?.user?.role)){
            fetchLocations();
        }
        if(id){
            fetchCustomerDetails();
        }
    }, [])

    const PaymentStatusRenderCell = (row) => {
        return (row?.orderPaymentStatus === "Unpaid" || row?.orderPaymentStatus === '' || row?.orderPaymentStatus === null) ? (
            <Chip icon={<CancelIcon color='disabled' />} sx={{ color: 'red' }} label={'Unpaid'} />
        ) : (
            <Chip icon={<CheckCircleIcon color='disabled' />} sx={{ color: 'green' }} label={row?.orderPaymentStatus} />
        )
    }

    const StatusRenderCell = (row) => {
        return row?.status === "Cancelled" || row?.status === 'Delivered' ? (
            <Chip label={row?.status} style={{ backgroundColor: row?.status === "Cancelled" ? '#e54545' : '#128038', color: '#fff' }} />
        ) : (
            <Chip style={{ backgroundColor: '#ffea88' }} label={row?.status} />
        )
    }

    const OrderTypeRenderCell = (row) => {
        return row?.orderType === "B2C" ? (
            <Chip icon={<PersonIcon color='disabled' />} label={row?.orderType} />
        ) : (
            <Chip icon={<StoreIcon color='disabled' />} label={row?.orderType} />
        )
    }

    let columns = [
        {
            field: 'orderName',
            headerName: 'ORDER',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdAt',
            headerName: 'DATE',
            flex: 1.7,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdVia',
            headerName: 'Platform',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Order',
            columnName: 'createdVia',
            renderCell: (params) => (params?.row?.createdVia),
        },
        {
            field: 'orderCancelledAt',
            headerName: 'Cancelled At',
            flex: 1.5,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return params?.row?.cancelledAt ? moment(params?.row?.cancelledAt).format('MMM DD, YYYY h:mm:ss A') : 'N/A'
            },
        },
        {
            field: 'customerFullName',
            headerName: 'CUSTOMER',
            flex: 1.7,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (params?.row?.customerFullName?.replace(/null/g, ''))
        },
        {
            field: 'locationCodes',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable: true,
            enumValues: locationCodes,
            renderCell: (params) => (params?.row?.locationCodes.join(', '))
        },
        {
            field: 'orderPaymentStatus',
            headerName: 'PAY STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            enumValues: ['Paid', 'Unpaid'],
            renderCell: (props) => ( <PaymentStatusRenderCell {...props?.row} />),
            customRenderCell: PaymentStatusRenderCell
        },
        {
            field: 'customerPhoneNumber',
            headerName: 'Phone Number',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            type: 'enum',
            tableName: 'Order',
            columnName: 'status',
            renderCell: (props) => ( <StatusRenderCell {...props?.row} />),
            customRenderCell: StatusRenderCell
        },
        {
            field: 'total',
            renderCell: (params) => `₹${params?.row?.total}`,
            headerName: 'TOTAL',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'orderType',
            headerName: 'TYPE',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            enumValues: ['B2B', 'B2C'],
            renderCell: (props) => ( <OrderTypeRenderCell {...props?.row} />),
            customRenderCell: OrderTypeRenderCell
        },
        {
            field: 'createdBy',
            headerName: 'CREATED BY',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            type: 'enum',
            tableName: 'Order',
            columnName: 'createdBy',
        },
    ];

    const handleUnAllocateAllSelected = async () => {
        try {
            const unAllocatedOrders = await axiosInstance.post("/orderAllocation/createOrderAllocationAcrossOrders", {
                orderNames: selectedOrders,
                mode: "unallocate"
            })
            setSelectedOrders([]);
            setReRender(!reRender);
            setSnackBar((prevSnackBar) => {
                return {
                    ...prevSnackBar,
                    display: true,
                    message: unAllocatedOrders?.data?.message,
                    type: "success",
                };
            });
        } catch (error) {
            setSnackBar((prevSnackBar) => {
                return {
                    ...prevSnackBar,
                    display: true,
                    message: error?.response?.data?.message || "Un-Allocation failed!",
                    type: "error",
                };
            });
        }
    }
    return (
        <>
            <SnackBar snackBar={snackBar}  setSnackBar={setSnackBar} />
            <Table
                columns={columns}
                dataPosition={"orders"}
                endpoint={endpoint}
                rowId={"orderName"}
                checkBoxSelection={true}
                service={"orders"}
                tabName={"ordersNew"}
                setData={setOrders}
                data={orders}
                navigateOnRowClickEndpoint={`/ordersNew`}
                columnVisibilityModel={columnVisibilityModel}
                mobileResponsive={true}
                exportEndpoint={"/exports/orders"}
                exportFileName={`Orders - ${new Date()}`}
                clickable={true}
                setSelectedId={setSelectedOrders}
                extraToolBarButtons={
                    selectedOrders?.length > 0 ?
                    [
                        <Permission service={services.orderAllocation} permission={"create"}>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleUnAllocateAllSelected}
                            >
                                Un-Allocate
                            </Button>
                        </Permission>
                    ] : []
                }
                reRender={reRender}
                selectedRowIds={selectedOrders}
            />
        </>
    );
}

export default OrdersNew;