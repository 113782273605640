import React, {Fragment, useState, useEffect } from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import Spinner from '../../../../components/spinner';
import { UpdateBillingPopupProps } from "@/types/component/createAndEditOrder/updateBillingPopup";
import { AutoCompleteOptions, CustomerAddressUpdateResponse } from "@/types";

const UpdateBillingPopUp = (props : UpdateBillingPopupProps)=> {
    const [states,setStates] = useState<string[]>([]);
    const [number,setNumber] = useState<string | null>('');
    const [pincode,setPincode] = useState<string|null>(null);
    const [gstNumber, setGstNumber] = useState<string | null>(null);
    const [loading,setLoading] = useState(false);

    const {
        hideUpdateBillingPop,
        updateBillingModal,
        showCustomerDetail,
        selectedBillingAddress,
        setSelectedBillingAddress,
        addressType,
        addressArray,
        setAddressArray,
    } = props;

    async function handlePincodeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const limit = 6;
        const enteredPincode = event.target.value.slice(0, limit);
        setPincode(enteredPincode);
        try {
            const response = await fetch(`https://api.postalpincode.in/pincode/${enteredPincode}`);
            const data = await response.json();

            if (data[0]?.Status === 'Success') {
                const state = document.getElementById('state') as HTMLInputElement;
                const extractedState = data[0]?.PostOffice[0]?.State;
                state.value = extractedState;
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const target = event.target as HTMLFormElement;
        const payload = {
            address1: target.address1.value,
            address2: target.address2.value,
            city: target.city.value,
            province: target.state.value,
            zip: target.pincode.value,
            country: target.country.value,
            firstName: target.first.value,
            lastName: target.last.value ? target.last.value : undefined,
            nick_name: target.nick_name.value ?? undefined,
            phone: `+91${target.phone.value}`,
            addressType: addressType,
            gstNumber: target.gst.value ? target.gst.value : undefined,
        }
        axiosInstance
            .post<CustomerAddressUpdateResponse>(`/user/admin/${showCustomerDetail?.id}/address/${selectedBillingAddress?.id}`, payload)
            .then((res) => {
                const updatedAddress = res.data.data;
                setPincode(null);
                setNumber(null);
                setGstNumber(null);
                const addressModified = {
                    ...updatedAddress,
                    addressLine1: updatedAddress?.address1,
                    addressLine2: updatedAddress?.address2,
                    pincode: updatedAddress?.zip,
                    state: updatedAddress?.province,
                    phoneNumber: updatedAddress?.phone,
                }          
                setSelectedBillingAddress(addressModified);
                setAddressArray([...addressArray, res.data.data]);
            })
            .catch((err) => {
                console.log(err);
                const errorMessage = err?.response?.data?.message;
                alert(errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
            hideUpdateBillingPop();
    }

    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('updateNumberOutline1');
        if (container !== null) {
            if (!container.contains(e.target as Node)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const handleMinLengthPincode = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if (event.target.value.match(pincode)) {
                (document.getElementById('pinErrMsg2') as HTMLElement).style.display = 'none';
                (document.getElementById('updateAddress') as HTMLButtonElement).disabled = false;
            } else {
                (document.getElementById('updateAddress') as HTMLButtonElement).disabled = true;
                (document.getElementById('pinErrMsg2') as HTMLElement).style.display = 'block';
            }
        } else {
            (document.getElementById('pinErrMsg2') as HTMLElement).style.display = 'none';
            (document.getElementById('updateAddress') as HTMLButtonElement).disabled = false;
        }
    }

    const handleMinLengthPhoneNumber = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value) {
            const phoneNo = /^\d{10}$/;
            if (event.target.value.match(phoneNo)) {
                (document.getElementById('phoneErrMsg2') as HTMLElement).style.display = 'none';
                (document.getElementById('updateAddress') as HTMLButtonElement).disabled = false;
            } else {
                (document.getElementById('updateAddress') as HTMLButtonElement).disabled = true;
                (document.getElementById('phoneErrMsg2') as HTMLElement).style.display = 'block';
            }
        } else {
            (document.getElementById('phoneErrMsg2') as HTMLElement).style.display = 'none';
            (document.getElementById('updateAddress') as HTMLButtonElement).disabled = false;
        }
    }

    const handleValidGst = (event: React.FocusEvent<HTMLInputElement>) => {
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            (document.getElementById('updateAddress') as HTMLButtonElement).disabled = false;
            (document.getElementById('isValidGst') as HTMLElement).style.display = 'none';
        } else {
            if (event.target.value === '') {
                (document.getElementById('updateAddress') as HTMLButtonElement).disabled = false;
                (document.getElementById('isValidGst') as HTMLElement).style.display = 'none';
            } else {
                (document.getElementById('updateAddress') as HTMLButtonElement).disabled = true;
                (document.getElementById('isValidGst') as HTMLElement).style.display = 'block';
            }
        }
    }

    const preventNegativeValues = (e: { key: string; preventDefault: () => any; }) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setGstNumber(selectedBillingAddress?.gstNumber || null);
                setNumber(selectedBillingAddress?.phoneNumber?.slice(3) || null);
                setPincode(selectedBillingAddress?.pincode || null);
                setStates(response.data.data);
            })
    },[selectedBillingAddress])


    return (
        <Fragment>
            {updateBillingModal && (
                <div className={Classes.modal} style={{ bottom:'11%',top:'3%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Edit Billing Address</p>
                    </div>

                    <form
                        method="post"
                        onSubmit={handleFormSubmit}
                        id='form'
                    >
                        <div className={Classes.AddressPopUp}>
                            <div style={{ marginTop: '10px' }}>
                                <TextField size="small"
                                    id='country'
                                    name='country'
                                    label='Country / Origin'
                                    value='India'
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top:"0%",fontSize:14},"& .MuiOutlinedInput-input":{padding:'6px 10px'}}}
                                    disabled={true}
                                />
                            </div>

                            <div style={{ display:'flex', marginTop:10,justifyContent:'space-between' }}>
                                <div style={{width: '48%'}}>
                                    <TextField size="small"
                                        required={true}
                                        id='first'
                                        name='first'
                                        label='First Name'
                                        autoComplete='off'
                                        key={selectedBillingAddress?.firstName}
                                        defaultValue={selectedBillingAddress?.firstName}
                                        placeholder='Enter Your First Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField size="small"
                                        id='last'
                                        name='last'
                                        label='Last Name'
                                        autoComplete='off'
                                        key={selectedBillingAddress?.lastName}
                                        defaultValue={selectedBillingAddress?.lastName}
                                        placeholder='Enter Your Last Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div style={{display:'flex', marginTop:10,justifyContent:'space-between'}}>
                                <div style={{width:'48%'}}>
                                    <TextField size="small"
                                        required={true}
                                        id='nick_name'
                                        name='nick_name'
                                        label='Nick Name'
                                        autoComplete='off'
                                        key={selectedBillingAddress?.nick_name}
                                        defaultValue={selectedBillingAddress?.nick_name && selectedBillingAddress?.nick_name !== 'null' ? selectedBillingAddress?.nick_name : ''}
                                        placeholder='Enter Your Nick Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div style={{marginTop:10}}>
                                <TextField size="small"
                                    required={true}
                                    id='address1'
                                    name='address1'
                                    label='Address 1'
                                    autoComplete='off'
                                    key={selectedBillingAddress?.addressLine1}
                                    defaultValue={selectedBillingAddress?.addressLine1}
                                    placeholder='Apartment,suite,etc'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                <TextField
                                    id='address2'
                                    name='address2'
                                    label='Address 2'
                                    autoComplete='off'
                                    key={selectedBillingAddress?.addressLine2}
                                    defaultValue={selectedBillingAddress?.addressLine2}
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                                <div style={{width:'48%'}}>
                                    <TextField size="small"
                                        required={true}
                                        id='city'
                                        name='city'
                                        label='City'
                                        key={selectedBillingAddress?.city}
                                        defaultValue={selectedBillingAddress?.city}
                                        autoComplete='off'
                                        placeholder='Enter Your City'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            
                                <div style={{width:'48%'}}>
                                    <TextField size="small"
                                        type="number"
                                        required={true}
                                        id='pincode'
                                        name='pincode'
                                        label='Pincode'
                                        autoComplete='off'
                                        placeholder='Enter Your Pincode'
                                        sx={{ width: '100%'}}
                                        inputProps={{ maxLength: 6, minLength: 6 }}
                                        value={pincode}
                                        onBlur={handleMinLengthPincode}
                                        onChange={handlePincodeChange}
                                        onKeyDown={preventNegativeValues}
                                    />

                                    <div
                                        className={Classes.PhoneErrorMsg}
                                        id='pinErrMsg2'
                                        style={{ fontSize: '14px' }}
                                    >
                                        <p>Please enter a valid 6 digit pincode</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                                <div style={{ width:'48%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="state"
                                        size="small"
                                        options={states}
                                        defaultValue={selectedBillingAddress?.state}
                                        key={selectedBillingAddress?.state}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="States" required/>}
                                    />
                                </div>

                                <div style={{width: '48%'}}>
                                    <TextField size="small"
                                        type="number"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        inputProps={{ maxLength: 10 }}
                                        sx={{ width: '100%'}}
                                        value={number}
                                        onBlur={handleMinLengthPhoneNumber}
                                        onChange={(event) => setNumber(event.target.value.slice(0, 10))}
                                        onKeyDown={preventNegativeValues}
                                    />

                                    <div
                                        className={Classes.PhoneErrorMsg}
                                        id='phoneErrMsg2'
                                        style={{ fontSize: '15px' }}
                                    >
                                        <p>Please enter a valid 10 digit mobile number</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        id='gst'
                                        name='gst'
                                        label='GST Number'
                                        size="small"
                                        autoComplete='off'
                                        placeholder='Enter GST Number'
                                        sx={{ width: '100%' }}
                                        value={gstNumber}
                                        onBlur={handleValidGst}
                                        onChange={(event) => setGstNumber(event.target.value.slice(0, 15))}

                                    />
                                    <div
                                        id='isValidGst'
                                        className={Classes.IsValidGst}
                                    >
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom: "10%"}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideUpdateBillingPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='updateAddress' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>

                    </form>

                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default UpdateBillingPopUp;