import Table from '../../../components/table/table';
import Classes from '../customers/css/customer.module.css';
import { setCustomersUpdateHistory } from '../../../store/customerUpdateHistory';
import { useSelector } from 'react-redux';

const CustomerUpdateHistory = () => {
    const customersUpdateHistory = useSelector((state) => state.customersUpdateHistory);

    const columns = [
        {
            field: 'name',
            headerName: 'First Name',
            // flex:1,
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'phone',
            headerName: 'Phone',
            // flex:1,
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'updateType',
            headerName: 'Update Type',
            headerClassName: 'super-app-theme--header',
            flex:1,
            filterable: false,
            sortable: false
        },
        {
            field: 'before',
            headerName: 'Old Details',
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'after',
            headerName: 'Updated Details',
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'updatedBy',
            headerName: 'Updated By',
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex:1.5,
            headerClassName: 'super-app-theme--header',
        }
    ]

    return (
        <Table
            columns={columns}
            dataPosition={"usersUpdateHistory"}
            endpoint={`/user/admin/fetchCustomerUpdateHistory`}
            rowId={'_id'}
            checkBoxSelection={false}
            service={'customers'}
            tabName={'Customers'}
            setData={setCustomersUpdateHistory}
            data={customersUpdateHistory}
            mobileResponsive={true}
        />
    )
}

export default CustomerUpdateHistory;