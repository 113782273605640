import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	total: 0,
	page: 1,
	maxPage: 1,
	states: [],
};

const statesSettingsSlice = createSlice({
	name: 'statesSettings',
	initialState: initialState,
	reducers: {
		setStates: (state, action) => action.payload,
		reset: (state, action) => initialState,
	},
});

export const { setStates, reset } = statesSettingsSlice.actions;

export default statesSettingsSlice.reducer;