import React, { useEffect, useState } from 'react'
import SnackBar from '../../../components/SnackBar'
import Spinner from '../../../components/spinner'
import Table from '../../../components/table/table'
import Permission from '../../auth/permissions'
import { services } from '../../../utility/checkRoleBasedPermission'
import { useSelector } from 'react-redux'
import { setStates } from '../../../store/statesSettingSlice'
import { ImportFileComponent } from '../../../components/importFileComponent'
import { downloadCSV } from '../../../utility/downloadCSV'

export default function States() {

    const [reRender, setReRender] = useState(false);
    const statesSettings = useSelector((state) => state.statesSettings);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);

    const [csvResponse, setCsvResponse ] = useState();

    const columnVisibilityModel={updated_at : false, deleted : true, id : false}

    const columns = [
        {
            field: `id`,
            headerName: `ID`,
            flex: .5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            filterable: false
        },
        {
            field: `name`,
            headerName: `Name`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: `code`,
            headerName: `Code`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: `tin`,
            headerName: `TIN`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: `created_at`,
            headerName: `Created At`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            filterable: false
        },
        {
            field: `updated_at`,
            headerName: `Updated At`,
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            filterable: false
        },
    ];

    useEffect(() => {
        if(csvResponse){
            if(csvResponse?.invalidRecords?.length){
                downloadCSV(csvResponse?.invalidRecords, `Invalid States Please check- ${new Date()}`)
            }
        }
    },[csvResponse])

  return (
    <>
        <div style={{marginTop : '20px'}}>
                <Permission service={services?.statesSettings} permission={"create"}>
                    <div style={{display:'flex',justifyContent:'space-between',marginBottom:'5px',flexWrap:'wrap',gap:5}}>
                        <ImportFileComponent
                            endpoint={'/pincode/createStatesViaCSV'}
                            serviceName={services?.statesSettings}
                            type="states"
                            templateDownloadEndpoint={"/templates/statesUploadCsv"}
                            setReRender={setReRender}
                            setData={setCsvResponse}
                        />
                    </div>
                </Permission>
                <Permission service={services?.statesSettings} permission={"read"}>
                <div>
                    <Table 
                        rowId={'id'} 
                        columns={columns} 
                        dataPosition={"states"} 
                        endpoint={'/pincode/fetchAllStatesDetails'}
                        exportEndpoint={'/exports/states'}
                        exportFileName={`States - ${new Date()}`}
                        checkBoxSelection={false} 
                        reRender={reRender} 
                        service={'states'}
                        tabName={'States'}
                        setData={setStates}
                        data={statesSettings}
                        columnVisibilityModel={columnVisibilityModel}
                        version={'version-2'}
                        mobileResponsive={true}
                    />
                    {loading ? <Spinner /> : null}
                </div>
                </Permission>
            </div>
            {loading ? <Spinner /> : null}
        <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </>
  )
}