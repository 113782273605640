import React, {Fragment, useEffect, useState} from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import Spinner from '../../../../components/spinner';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import '../css/styles.css';
import { snakeToTitleCase } from '../../../../utility/textFormatting';
import { useAffiliate } from "../../../../hooks/useAffiliate";
import { AutoCompleteOptions, CustomerCreateResponse, NewCustomerModalProps, SalesUsersApiResponse } from "@/types";

const CustomerModal = (props: NewCustomerModalProps)=> {
    const [loading,setLoading] = useState<boolean>(false);
    const [salesTeam,setSalesTeam] = useState<AutoCompleteOptions>([]);
    const [affiliate,setAffiliate] = useState('');
    const [salesUser,setSalesUser] = useState('');
    const [preSalesUser,setPreSalesUser] = useState('');
    const [customerType,setCustomerType] = useState('');
    const [customerRole,setCustomerRole] = useState('');
    const [email,setEmail] = useState('');
    const [states,setStates] = useState<AutoCompleteOptions>([]);
    const [customerRoles, setCustomerRoles] = useState<string[]>([]);
    const [cities,setCities] = useState<AutoCompleteOptions>([]);
    const [areas,setAreas] = useState<AutoCompleteOptions>([]);
    const { affiliateList } = useAffiliate();

    const { 
        setDetail,
        errorMessage,
        setErrorMessage,
        gstNO,
        setGstNO,
        num,
        setNum,
        setDisplayName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        businessName,
        setBusinessName,
        popUpType,
        endpoint,
        selectedState,
        setSelectedState,
        setCity,
        city,
        setArea,
        area,
        setReRender,
        reRender
    } = props;

    const handleFirstNameChange = (event: { target: { value: any; }; }) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event: { target: { value: any; }; }) => {
        setLastName(event.target.value)
    }

    const handleBusinessName = (event: { target: { value: any; }; }) => {
        setBusinessName(event.target.value)
    }

    useEffect(() => {
        const businessDetail = businessName !== '' ? businessName : `${firstName} ${lastName}`;
        setDisplayName(businessDetail)
    }, [firstName, lastName, businessName]);

    useEffect(() => {
        axiosInstance
            .get<SalesUsersApiResponse>('/admin/salesTeam')
            .then((res) => {
                const arr : AutoCompleteOptions = [];
                for (let ele of res.data.data?.users) {
                    arr.push({label:ele?.name,Id: `${ele?.pgdbAdminId}`})
                }
                setSalesTeam(arr);
            })
    },[]);

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setStates(response?.data?.data)
            })
    },[])

    useEffect(() => {
        axiosInstance
            .get('/user/admin/customerRoles')
            .then((res) => {
                let roles = res?.data?.data
                roles = roles.map((role: { title: any; }) => {
                    return role?.title
                })
                setCustomerRoles([...roles] as string[])
            })
    }, [])


    const handleGstField = (event: { target: { value: string; }; }) => {
        const result=  event.target.value.replace(/[^\w\s]/gi, "")
        setGstNO(result)
    }

    const handleNumChange = (event: { target: { value: string | any[]; }; }) => {
        const limit = 10;
        setNum(event.target.value.slice(0, limit));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('btn') as HTMLButtonElement;
        button.innerHTML = 'Save Customer';
        const roles: { [key: string]: string } = {}
        customerRoles?.length &&
            customerRoles.forEach((role) => {
                const roleInTitleCase = snakeToTitleCase(role)
                const roleInSnakeCase = role
                console.log({ roleInTitleCase, roleInSnakeCase })
                roles[roleInTitleCase] = roleInSnakeCase
            })
        const target = event.target as HTMLFormElement;
        const data = {
            email: target.email.value || undefined,
            phone:`+91${target.phone.value}`,
            firstName: target.first.value,
            lastName: target.last.value ? target.last.value : undefined,
            gstNumbers: target.gst.value ? target.gst.value : undefined,
            customerRole: (document.querySelector("#customerRole") as HTMLInputElement)?.value ? roles[(document.querySelector("#customerRole") as HTMLInputElement)?.value] : undefined,
            customerType: target.customerType.value ? target.customerType.value : undefined,
            businessName: target.businessName.value ? target.businessName.value : undefined,
            affiliateUser : affiliate ? affiliate : undefined,
            salesUser: salesUser ? salesUser : undefined,
            preSalesUser: preSalesUser ? preSalesUser: undefined,
            state: selectedState ? selectedState : undefined,
            city: city ? city : undefined,
            area: target.area.value ? target.area.value : undefined
        }

        axiosInstance
            .post<CustomerCreateResponse>(endpoint,{...data})
            .then((res) =>{
                const form = document.getElementById('form') as HTMLFormElement;
                form.reset();
                props.onHideModal();
                if (!popUpType) {
                    setDetail(res?.data?.data);
                } else {
                    setReRender(!reRender);
                }
                setLoading(false);
                setErrorMessage('');
                setAffiliate('');
                setPreSalesUser('');
                setSalesUser('');
                setCustomerRole('');
                setCustomerType('');
                setEmail('');
                setSelectedState('');
                setCity('');
                setArea('');
                button.innerHTML = 'Customer Created';
            }).catch((err) => {
                button.innerHTML = 'Customer Create';
                setLoading(false);

                if(err?.response?.data?.hasOwnProperty('keyPattern')){
                    const existData = Object.keys(err?.response?.data?.keyPattern)
 
                    if(existData[0] === 'email'){
                        setErrorMessage('This email already exists, Please enter another email')
                    }else if( existData[0] === 'phone'){
                        setErrorMessage('This number already exists, Please enter another number')
                    }   
                }else{
                    if(err?.response?.data?.hasOwnProperty('message')){
                        if(err.response.data.message === 'Error creating user on Shopify'){
                            setErrorMessage('Please try to provide different input, If Issue Still Persist Then Contact Tech Team')   
                        }else{
                            setErrorMessage(err.response.data.message)
                        }
                    }
                }
            })
    }


    document.addEventListener('mouseup', function(e) {
        let container = document.getElementById('numberOutline');
        if(container !== null){
            if (!container.contains(e.target as Node)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const handleMinLength = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                (document.getElementById('phoneErrMsg') as HTMLElement).style.display = 'none';
                (document.getElementById('btn') as HTMLButtonElement).disabled = false;
            }else{
                (document.getElementById('btn') as HTMLButtonElement).disabled = true;
                (document.getElementById('phoneErrMsg') as HTMLElement).style.display = 'block';
            }
        } else {
            (document.getElementById('phoneErrMsg') as HTMLElement).style.display = 'none';
            (document.getElementById('btn') as HTMLButtonElement).disabled = false;
        }
    }

    const handleValidGst = (event: { target: { value: string | null; }; }) =>{
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            (document.getElementById('btn') as HTMLButtonElement).disabled = false;
            (document.getElementById('isValidGst') as HTMLElement).style.display = 'none';
        } else {
            if(event.target.value === '') {
                (document.getElementById('btn') as HTMLButtonElement).disabled = false;
                (document.getElementById('isValidGst') as HTMLElement).style.display = 'none';
            } else {
                (document.getElementById('btn') as HTMLButtonElement).disabled = true;
                (document.getElementById('isValidGst') as HTMLElement).style.display = 'block';
            }
        }
    }

    const handleEmail = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        if (event.target.value) {
            setEmail(event.target.value)
        }
    }

    useEffect(()=> {
        if (selectedState) {
            axiosInstance
            .get(`/pincode/getAllCities?state=${selectedState}`)
            .then((response) => {
                setCities(response.data?.data)
            })
        }
    },[selectedState]);

    useEffect(()=> {
        if (city) {
            axiosInstance
            .get(`/others/areas/${city}`)
            .then((response) => {
                const areas = [];
                for (let areaName of response.data.data) {
                    areas.push({Id:areaName?._id,label:areaName?.area})
                }
                setAreas(areas);
            })
        }
    },[city]);
    const handleCancelModal = () => {
        props.onHideModal();
        setAffiliate('');
        setPreSalesUser('');
        setSalesUser('');
        setCustomerRole('');
        setCustomerType('');
        setEmail('');
    }
    return (
        <Fragment>
            {props.onShowModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'8%',bottom:'11%',zIndex:'9999'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>{endpoint === '/user/admin/affiliates' ? "Create a New Affiliate": 'Create a New Customer'}</p>
                    </div>
                    
                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form' 
                        style={{
                            overflowY:'auto',
                            height:'72%'
                        }}
                    >
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>

                        <div style={{padding:20}}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div style={{ width:'29%' }}>
                                    <TextField
                                        required
                                        id='firstName'
                                        name="first"
                                        label="First Name"
                                        placeholder='First Name'
                                        autoComplete='off'
                                        value={firstName || ''}
                                        onChange={handleFirstNameChange}
                                        sx={{ width: '100%',"& label": {top:firstName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'29%' }}>
                                    <TextField
                                        id='lastName'
                                        name="last"
                                        label="Last Name"
                                        placeholder='Last Name'
                                        autoComplete='off'
                                        value={lastName || ''}
                                        onChange={handleLastNameChange}
                                        sx={{ width: '100%',"& label": {top: lastName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'40%' }}>
                                    <TextField
                                        id='displayName'
                                        name="displayName"
                                        label="Display Name"
                                        placeholder='Display Name'
                                        value={businessName ? businessName :firstName ? firstName + " " + lastName: ''}
                                        autoComplete='off'
                                        disabled={true}
                                        sx={{ width: '100%',"& label": {top: firstName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{ width:'32%' }}>
                                    <TextField 
                                        required
                                        id='phoneNumber'
                                        name="phone"
                                        type="number"
                                        label="Phone Number"
                                        inputProps={{ maxLength: 10 }}
                                        onChange={handleNumChange}
                                        onBlur={(e) => handleMinLength(e)}
                                        placeholder='Enter Your 10 Digit Phone Number'
                                        value={num ? num : ''}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: num ? "0%" : "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{width:'32%'}}>
                                    <TextField
                                        required
                                        id='email'
                                        name="email"
                                        label="Email"
                                        value={email || ''}
                                        onChange={(e) => setEmail(e?.target?.value)}
                                        onBlur={handleEmail}
                                        placeholder='Enter Your Email'
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: email ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <div>
                                        <TextField 
                                            id='gstNumber'
                                            name="gst"
                                            label="GST Number"
                                            inputProps={{ minLength: 15, maxLength: 15 }}
                                            placeholder='Enter valid Gst Number'
                                            value={gstNO ? gstNO: ''}
                                            onChange={handleGstField}
                                            onBlur={handleValidGst}
                                            autoComplete='off'
                                            sx={{ width: '100%',"& label": {top: gstNO ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </div>

                                    <div 
                                        id='isValidGst'
                                        className={Classes.IsValidGst}
                                    >
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>

                            <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                <p>Please enter a valid 10 digit mobile number</p>
                            </div>


                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{width:'32%'}}>
                                    <TextField
                                        id='businessName'
                                        name="businessName"
                                        label="Business Name"
                                        placeholder='Enter Your Business Name'
                                        value={businessName || ''}
                                        onChange={handleBusinessName}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top:businessName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                { customerRoles.length && <div style={{width:'32%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="customerRole"
                                        options={[...customerRoles.map((role) => { return { "label": snakeToTitleCase(role) } })]}
                                        sx={{width: '100%',"& label": {top: customerRole ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        value={customerRole ? { label: snakeToTitleCase(customerRole) } : null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setCustomerRole(newValue.label);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Customer Roles"/>}
                                    />
                                </div>}

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="customerType"
                                        options={['B2B', 'B2C']}
                                        sx={{width: '100%',"& label": {top: customerType ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        value={customerType || ''}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setCustomerType(newValue);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Customer Type" />}
                                    />
                                </div>
                            </div>

                            
                            <div className={Classes.ContentBox} style={{marginTop:25}}>

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="state"
                                        options={states}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{
                                            width: '100%',
                                            "& label": {top: selectedState ? "0%": "-16%",fontSize:14},
                                            "& .MuiInputBase-input": {height:'12px'},
                                            "& .MuiOutlinedInput-root":{padding:'6px'},
                                            "& .MuiAutocomplete-input" : {fontSize:14}
                                        }}
                                        onChange={(event, newValue) => {
                                            setSelectedState(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} label="States" required/>}
                                    />
                                </div>

                                <div style={{width:'32%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="city"
                                        options={cities}
                                        disabled={!selectedState ? true: false}
                                        sx={{width: '100%',"& label": {top: city ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setCity(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="City"/>}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="area"
                                        options={areas}
                                        disabled={!city ? true: false}
                                        sx={{width: '100%',"& label": {top: area ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onBlur={(event) => {setArea((event.target as HTMLInputElement).value)}}
                                        renderInput={(params) => <TextField {...params} label="Area"/>}
                                    />
                                </div>
                            </div>

                            <div 
                                className={Classes.ContentBox} 
                                style={{marginTop:25,justifyContent: endpoint === '/user/admin/affiliates' ? 'flex-start': 'space-between'}}
                            >
                                {endpoint !== '/user/admin/affiliates' && (
                                    <div style={{width:'32%'}}>
                                        <Autocomplete
                                            disablePortal
                                            id="affiliate"
                                            options={affiliateList.map((affiliate) => ({
                                                label: affiliate?.display_name,
                                                Id: `${affiliate?.id}`,
                                              }))}
                                            ListboxProps={{sx: { fontSize: 14 }}}
                                            sx={{
                                                width: '100%',
                                                "& label": {top: affiliate ? "0%": "-16%",fontSize:14},
                                                "& .MuiInputBase-input": {height:'12px'},
                                                "& .MuiOutlinedInput-root":{padding:'6px'},
                                                "& .MuiAutocomplete-input" : {fontSize:14}
                                            }}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setAffiliate(newValue.Id);
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Select Affiliate"/>}
                                        />
                                    </div>
                                )}

                                {salesTeam.length !== 0 && (
                                    <div style={{width:'32%'}}>
                                        <Autocomplete
                                            disablePortal
                                            id="salesUser"
                                            options={salesTeam}
                                            sx={{width: '100%',"& label": {top: salesUser ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSalesUser(newValue.Id);
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Sales User"/>}
                                        />
                                    </div>
                                )}

                                <div 
                                    style={{
                                        width: endpoint === '/user/admin/affiliates' ? '32%' : '33%',
                                        marginLeft:endpoint === '/user/admin/affiliates' ? 10: 0
                                    }}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="preSalesUser"
                                        options={salesTeam}
                                        sx={{width: '100%',"& label": {top: preSalesUser ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setPreSalesUser(newValue.Id);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Pre Sales User"/>}
                                    />
                                </div>
                            </div>
                        </div>


                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'11%',
                                position:'fixed',
                                boxShadow:'none',
                                borderTop: 0
                            }}
                        >
                            <button 
                                onClick={handleCancelModal}
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                CREATE
                            </button>
                        </div>
                        
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default CustomerModal;