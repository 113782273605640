import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import CapsuleChip from '../CapsuleChip';

import { roundOfUptoXDecimal } from '../../utility/calculation';

const CreateOrderComponent = (props) => {
    const { 
        handleEdit,
        handleBlur,
        showProduct,
        handleEditPercentage,
        handleBlurPercentage,
        showLimitDiscount,
        handleChange,
        handleBlurQty,
        incQty,
        decQty,
        handleBlurBulkPackagingQty1,
        handleKeyUp,
        incQtyBulkPackaging,
        decQtyBulkPackaging,
        handleBlurBulkPackagingQty2,
        updateArr,
        active,
        componentType,
    } = props;

    const formatNumber = (number) => {
        const formattedNumber = parseFloat(number).toFixed(2);
        return formattedNumber;
    };

    const preventValueWithoutDecimal = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    return (
        <Fragment>
            <div className={[Classes.Box,'box'].join(' ')}>
                {showProduct ? (
                    <div className={Classes.variantContainer}>
                        <div className={Classes.Thead} style={{justifyContent:'space-between',paddingRight: componentType && !(active) ? '40px' : '22px' }} >
                            <div className={['createOrderProductHeading',Classes.variantProductHeader].join(' ')}>
                                Product
                            </div>

                            {componentType && active && (
                                <div className={['discountCreateOrder',Classes.variantDiscountHeader].join(' ')}>
                                    <p>Discount</p>
                                    <p id='inclGst'>(Incl. GST)</p>
                                </div>
                            )}                            

                            <div className={['qtyHeader',Classes.variantQuantityHeader].join(' ')}>
                                Quantity
                            </div>
                            
                            {componentType && active && (
                                <div className={['subtotal',Classes.variantSubTotalHeader].join(' ')}>
                                    <p style={{ paddingLeft: 5 }}>Subtotal</p>
                                    <p>(Excl. GST)</p>
                                </div>
                            )}

                            {componentType && active && (
                                <div className={['total',Classes.variantTotalHeader].join(' ')}>
                                    <p style={{ paddingLeft: 10 }}>Total</p>
                                    <p>(Incl. GST)</p>
                                </div>
                            )}
                        </div>

                        {showProduct?.lineItems?.map((item, index) => (
                            <div
                                className={[Classes.containProduct,'variantProduct'].join(' ')}
                                id={item?.productId}
                                key={item?.productId}
                                style={{justifyContent:'space-between'}}
                            >
                                <div style={{justifyContent:'space-between',display:'flex',width:'98%'}}>
                                    <div className={[Classes.productDetail,'productVariant'].join(' ')} id={item?.productId}>
                                        <div>
                                            <img
                                                src={
                                                    item?.mainImageUrl ?
                                                    item?.mainImageUrl :
                                                    "https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png"
                                                }
                                                alt="icon"
                                                className={Classes.variantImage}
                                            />
                                        </div>

                                        <div className={Classes.variantDetail}>
                                            <p style={{ color:'#0d6efd' }}>{item?.basicCollectionDataBrandData?.groupName}</p>
                                            <div style={{ color: '#6d7175' }}>
                                                <div className={Classes.commonDisplay}>
                                                    <p>{item?.variationDataProductInformationProductDetail?.variable1Value}</p>

                                                    {item?.variationDataProductInformationProductDetail?.variable2Value && (
                                                        <div className={Classes.commonDisplay}>
                                                            <p style={{ margin: '0px 5px' }}>/</p>
                                                            <p>{item?.variationDataProductInformationProductDetail?.variable2Value}</p>
                                                        </div>
                                                    )}

                                                    {item?.variationDataProductInformationProductDetail?.variable3Value && (
                                                        <div className={Classes.commonDisplay}>
                                                            <p style={{ margin: '0px 5px' }}>/</p>
                                                            <p>{item?.variationDataProductInformationProductDetail?.variable3Value}</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <p>{item?.basicCollectionDataBrandData?.sku}</p>
                                                
                                                {componentType && active && (
                                                    <div className={[Classes.GstRate, 'gstRate'].join(' ')}>
                                                        <p>GST Rate:- </p>
                                                        <p style={{ marginLeft: 5 }}>{item?.priceDataAffiliateData?.gst}%</p>
                                                    </div>
                                                )}
                                            </div>

                                            {componentType && active && (
                                                <div
                                                    style={{ display:'flex' }}
                                                    className={[Classes.rateFontSize,'hidePricePrint'].join(' ')}
                                                >
                                                    <p style={{ color:'rgb(109, 113, 117)',marginRight:9 }}>Net Price</p>
                                                    <div style={{ display: 'flex', color: '#0d6efd' }}>
                                                        <p>&#8377;</p>
                                                        <p
                                                            style={{ padding: '0px 5px' }}
                                                            contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            onKeyDown={(e) => handleEdit(e)}
                                                            onBlur={(e) =>
                                                                handleBlur(
                                                                    e,
                                                                    item?.productId,
                                                                    item?.discountedUnitPrice,
                                                                    item?.priceDataAffiliateData?.mrp
                                                                )
                                                            }
                                                        >
                                                            {formatNumber(item?.discountedUnitPrice)}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                            {componentType && active && (
                                                <div 
                                                    style={{ display:'flex' }}
                                                    className={[Classes.rateFontSize,'hidePricePrint'].join(' ')}
                                                >
                                                    <p style={{ color:'rgb(109, 113, 117)',marginRight:9 }}>
                                                        MRP
                                                    </p>
                                                    <p style={{ padding: '0px 5px',color:'#0d6efd' }}>
                                                        &#8377;{item?.priceDataAffiliateData?.mrp}
                                                    </p>
                                                </div>
                                            )}

                                            {componentType && active && (
                                                <div className={Classes.MrpLessThanRestrict}>
                                                    <p className={Classes.MrpRestrictMessage}>
                                                        Price must be less than {item?.priceDataAffiliateData?.originalListingPrice}
                                                    </p>
                                                </div>
                                            )}

                                            {componentType && active && (
                                                <div id='manuallyDiscountPercent'>
                                                    <div className={Classes.commonDisplay}>
                                                        <p className={Classes.variantDiscountInclGst}>Discount Incl. GST</p>
                                                        <div>
                                                            <input
                                                                type="number"
                                                                id={`discountPercentage-${index}`}
                                                                onChange={(e) => handleEditPercentage(e)}
                                                                onBlur={(e) => handleBlurPercentage(
                                                                    e,
                                                                    item?.productId,
                                                                    item?.priceDataAffiliateData?.mrp,
                                                                    item?.priceDataAffiliateData?.gst,
                                                                    item?.priceDataAffiliateData?.mrp
                                                                )}
                                                                className={Classes.variantDiscountInclGstInput}
                                                                onKeyDown={preventValueWithoutDecimal}
                                                            />
                                                        </div>

                                                        <div className={[Classes.hideResponsiveRow,'hideResponsiveRow'].join(' ')}>
                                                            <div
                                                                id='discountIncWidPrint'
                                                                className={[
                                                                    item?.priceDataAffiliateData?.appliedDiscountTitle ?
                                                                    Classes.marginTop : '',
                                                                    Classes.variantDiscountTitle
                                                                ].join(' ')}
                                                            >
                                                                <div style={{ display:'flex',justifyContent: 'center' }}>
                                                                    <p>{formatNumber(item?.discountPercentage)}</p>
                                                                    <p>%</p>
                                                                </div>

                                                                {item?.priceDataAffiliateData?.appliedDiscountTitle && (
                                                                    <div id='discountType' className={Classes.appliedDiscountType}>
                                                                        {item?.priceDataAffiliateData?.appliedDiscountTitle}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id='discountPercentRestrict' className={Classes.DiscountMsg}>
                                                        <p className={Classes.DiscountRestrictMsg}>
                                                            Discount must be smaller than 100%
                                                        </p>
                                                    </div>

                                                    <div id='discountPercentRestrict' className={Classes.DiscountMsg}>
                                                        <p className={Classes.DiscountRestrictMsg}>
                                                            Discount must be smaller than 100%
                                                        </p>
                                                    </div>

                                                    <div className={[Classes.MrpLessThanRestrict,'index','discountContent'].join(' ')}>
                                                        <div className={Classes.MrpRestrictMessage} style={{ display:'flex',marginTop:5 }}>
                                                            <p>Discount must be greater than</p>
                                                            <p style={{ marginLeft: 5 }}>
                                                                {showLimitDiscount(
                                                                    item?.priceDataAffiliateData?.mrp,
                                                                    item?.priceDataAffiliateData?.mrp,
                                                                    item?.priceDataAffiliateData?.gst
                                                                )}
                                                            </p>
                                                            <p>%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {componentType && active && item?.districtForPrices && 
                                                <CapsuleChip capsulePart1={"District For Prices"} capsulePart2={item?.districtForPrices} colorChip={"#243750"} />
                                            }
                                            <div className={Classes.SubTotal} style={{ marginTop: 20 }}>
                                                <div className='subtotal' id='discTotalPrint'>
                                                    <p className={Classes.responsiveTotal}>Subtotal</p>
                                                    <p>&#8377;{formatNumber(item?.subTotal)}</p>
                                                </div>

                                                <div
                                                    className={['total',Classes.variantTotal].join(' ')}
                                                    id='discTotalPrint'
                                                >
                                                    <p className={Classes.responsiveTotal}>Total</p>
                                                    <p>&#8377;{roundOfUptoXDecimal(item.total)}</p>
                                                </div>
                                            </div>

                                            <div className={Classes.QuantityProductErrorMsg}>
                                                <p>Quantity Cannot be less than 1</p>
                                            </div>
                                        </div>
                                    </div>

                                    {componentType && active && (
                                        <div                                
                                            className={[Classes.hideDiscountResponsive,Classes.variantDiscountBox,'createOrderPercent'].join(' ')}
                                            id='discountIncWidPrint'
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>{formatNumber(item?.discountPercentage)}</p>
                                                <p>%</p>
                                            </div>
                                            {item?.appliedDiscount && ( 
                                                <div id='discountType' className={Classes.appliedDiscountType}>
                                                    {item?.appliedDiscount.title}
                                                </div>
                                            )}
                                            {item?.priceDataAffiliateData?.appliedDiscountTitle && (
                                                <p 
                                                    id='exclGSTPrint'
                                                    className={['exclGst',Classes.responsiveExclGst].join(' ')}
                                                >(Incl. GST)</p>
                                            )}
                                            <p > </p>
                                        </div>
                                    )}

                                    <div 
                                        className={['createOrderQty',Classes.createOrderQuantity].join(' ')}
                                        style={{ width:'14%' }}
                                    >
                                        <p>{item?.quantity}</p>
                                        <p className={Classes.QtyPCSHighlight}>
                                            {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                                        </p>
                                    </div>

                                    {componentType && active && (
                                        <div
                                            className={[Classes.hideTotalResponsive,Classes.variantProductTotal,'total'].join(' ')}
                                            id='discTotalPrint'
                                        >
                                            &#8377;{roundOfUptoXDecimal(item?.subTotal)}
                                        </div>
                                    )}

                                    {componentType && active && (
                                        <div
                                            className={[Classes.hideTotalResponsive,Classes.variantProductTotal,'total'].join(' ')}
                                            id='discTotalPrint'
                                        >
                                            &#8377;{roundOfUptoXDecimal(item.total)}
                                        </div>
                                    )}
                                </div>

                                <div className='removeIcon'>
                                    <FontAwesomeIcon
                                        icon={faXmark}
                                        className={Classes.removeProductInContainer}
                                        onClick={() => updateArr(item?.productId)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </Fragment>
    )
}

export default CreateOrderComponent;