import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SnackBar from "../../../../components/SnackBar";
import { TextField, Autocomplete } from "@mui/material";
import axiosInstance from "../../../../utility/axios-instance";
import Classes from "../../customers/css/Modal.module.css";
import Spinner from "../../../../components/spinner";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateCompany = (props) => {
  const { open, closeDialog, setReRender, reRender } = props;

  const [loading, setLoading] = useState(false);
  const [pincode, setPincode] = useState("");
  const [num, setNum] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

const data = {
  companyName: event?.target?.companyName?.value,
  companyNameInTally: event?.target?.companyNameInTally?.value,
  gstNumber: event.target.gstNumber.value,
  addressLine1: event.target.addressLine1.value,
  addressLine2: event?.target?.addressLine2?.value,
  city: event.target.city.value,
  pincode: event.target.pincode.value,
  state: event.target.state.value,
  country: event?.target?.country?.value,
  phoneNumber: event.target.phoneNumber.value,
  email: event.target.email.value,
  bankAccountName: event.target.bankAccountName.value,
  bankAccountNumber: event.target.bankAccountNumber.value,
  bankName: event.target.bankName.value,
  bankAccountIfsc: event.target.bankAccountIfsc.value,
  pan: event.target.pan.value,
  invoicePrefix: event.target.invoicePrefix.value,
  invoiceInfix: event.target.invoiceInfix.value,
  // invoiceNumber: event.target.invoiceNumber.value
  cin: event?.target?.cin?.value ? event?.target?.cin?.value : undefined,
  invoice_start_sequence: event?.target?.customInvoiceSeq?.value ? event?.target?.customInvoiceSeq?.value : undefined
};
;

    axiosInstance
      .post(`/adminRoutes/companies`, { ...data })
      .then((res) => {
        const form = document.getElementById("form");
        form.reset();
        handleClose();
        setReRender(!reRender);
        setLoading(false);
        setSnackBar({
          display: true,
          type: "success",
          message: res?.data?.message,
        });
      })
      .catch((err) => {
        setLoading(false);
        setSnackBar({
          display: true,
          type: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  const handleClose = () => {
    const form = document.getElementById("form");
    form.reset();
    setNum("");
    setPincode("");
    setGstNo("");
    document.getElementById("isValidGst").style.display = "none";
    document.getElementById("pinErrMsg").style.display = "none";
    document.getElementById("phoneErrMsg").style.display = "none";
    document.getElementById("isValidPAN").style.display = "none";
    document.getElementById("isValidCIN").style.display = "none";
    closeDialog();
  };

  const handleValidGst = (event) => {
    let regex = new RegExp(
      /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/,
    );

    if (event.target.value == null) {
      return "false";
    }

    if (regex.test(event.target.value) === true) {
      document.getElementById("btn").disabled = false;
      document.getElementById("isValidGst").style.display = "none";
    } else {
      if (event.target.value === "") {
        document.getElementById("btn").disabled = false;
        document.getElementById("isValidGst").style.display = "none";
      } else {
        document.getElementById("btn").disabled = true;
        document.getElementById("isValidGst").style.display = "block";
      }
    }
  };

  const handleValidPAN = (event) => {
    let regex = new RegExp(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/);

    if (event.target.value == null) {
      return "false";
    }

    if (regex.test(event.target.value) === true) {
      document.getElementById("btn").disabled = false;
      document.getElementById("isValidPAN").style.display = "none";
    } else {
      if (event.target.value === "") {
        document.getElementById("btn").disabled = false;
        document.getElementById("isValidPAN").style.display = "none";
      } else {
        document.getElementById("btn").disabled = true;
        document.getElementById("isValidPAN").style.display = "block";
      }
    }
  };

  const isValidCINNumber = (event) => {
    let regex = new RegExp(
      /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
    );

    if (event.target.value == null) {
      return "false";
    }

    if (regex.test(event.target.value) === true) {
      document.getElementById("btn").disabled = false;
      document.getElementById("isValidCIN").style.display = "none";
    } else {
      if (event.target.value === "") {
        document.getElementById("btn").disabled = false;
        document.getElementById("isValidCIN").style.display = "none";
      } else {
        document.getElementById("btn").disabled = true;
        document.getElementById("isValidCIN").style.display = "block";
      }
    }
  };

  const preventNegativeValues = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleNumChange = (event) => {
    const limit = 10;
    setNum(event.target.value.slice(0, limit));
  };

  const handleMinLength = (event) => {
    if (event.target.value) {
      const phoneno = /^\d{10}$/;
      if (event.target.value.match(phoneno)) {
        document.getElementById("phoneErrMsg").style.display = "none";
        document.getElementById("btn").disabled = false;
      } else {
        document.getElementById("btn").disabled = true;
        document.getElementById("phoneErrMsg").style.display = "block";
      }
    } else {
      document.getElementById("phoneErrMsg").style.display = "none";
      document.getElementById("btn").disabled = false;
    }
  };

  const handleMinLengthPinCode = (event) => {
    if (event.target.value) {
      const pincode = /^\d{6}$/;
      if (event.target.value.match(pincode)) {
        document.getElementById("pinErrMsg").style.display = "none";
        document.getElementById("btn").disabled = false;
      } else {
        document.getElementById("btn").disabled = true;
        document.getElementById("pinErrMsg").style.display = "block";
      }
    } else {
      document.getElementById("pinErrMsg").style.display = "none";
      document.getElementById("btn").disabled = false;
    }
  };

  async function handlePincodeChange(event) {
    const limit = 6;
    const enteredPincode = event.target.value.slice(0, limit);
    setPincode(enteredPincode);
  }

  useEffect(() => {
    axiosInstance.get("/pincode/getAllStates").then((response) => {
      setStates(response.data.data);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get("/pincode/getAllCities").then((response) => {
      setCities(response.data.data);
    });
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        sx={{ "& .MuiDialog-paper": { maxWidth: "775px" } }}
      >
        <DialogTitle>Create Company</DialogTitle>
<form method="post" onSubmit={handleSubmit} id="form">
  <div style={{ padding: "5px 20px" }}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "32%" }}>
        <TextField
          label={"Company Name"}
          placeholder={"Enter company name"}
          required={true}
          id="companyName"
          name={"companyName"}
          autoComplete="off"
          size="small"
          sx={{ width: "100%" }}
        />
      </div>

      <div style={{ width: "32%" }}>
        <TextField
          label={"Company Name In Tally"}
          placeholder={"Enter company name in tally"}
          required={true}
          id="companyNameInTally"
          name={"companyNameInTally"}
          autoComplete="off"
          size="small"
          sx={{ width: "100%" }}
        />
      </div>

      <div style={{ width: "32%" }}>
        <TextField
          label={"GST Number"}
          placeholder={"Enter company GST"}
          required={true}
          id="gstNumber"
          name={"gstNumber"}
          minLength={15}
          maxLength={15}
          inputProps={{ maxLength: 15 }}
          onBlur={handleValidGst}
          onChange={(event) => setGstNo(event.target.value)}
          value={gstNo}
          autoComplete="off"
          autoFocus="off"
          sx={{ width: "100%" }}
          size="small"
        />

        <div id="isValidGst" className={Classes.IsValidGst}>
          <p>Please fill valid GST Number</p>
        </div>
      </div>
    </div>

    <div
      style={{
        marginTop: 15,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <TextField
        label={"Email"}
        type="email"
        placeholder={"Enter company email"}
        required={true}
        id="email"
        name={"email"}
        autoComplete="off"
        sx={{ width: "32%" }}
        size="small"
      />

      <div style={{ width: "32%" }}>
        <TextField
          label={"PAN"}
          placeholder={"Enter company PAN"}
          required={true}
          id="pan"
          name={"pan"}
          autoComplete="off"
          sx={{ width: "100%" }}
          onBlur={(e) => handleValidPAN(e)}
          minLength={10}
          maxLength={10}
          inputProps={{ maxLength: 10 }}
          size="small"
        />

        <div id="isValidPAN" className={Classes.IsValidGst}>
          <p>Please fill valid PAN Number</p>
        </div>
      </div>

      <div style={{ width: "32%" }}>
        <TextField
          label={"CIN"}
          placeholder={"Enter company CIN"}
          id="cin"
          name={"cin"}
          autoComplete="off"
          sx={{ width: "100%" }}
          size="small"
          minLength={21}
          maxLength={21}
          inputProps={{ maxLength: 21 }}
          onBlur={(e) => isValidCINNumber(e)}
        />

        <div id="isValidCIN" className={Classes.IsValidGst}>
          <p>Please fill valid CIN Number</p>
        </div>
      </div>
    </div>

    <div
      style={{
        marginTop: 15,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "32%" }}>
        <TextField
          label={"Address Line 1"}
          placeholder={"Enter company address line 1"}
          required={true}
          id="addressLine1"
          name={"addressLine1"}
          autoComplete="off"
          sx={{ width: "100%" }}
          size="small"
        />
      </div>
      <div style={{ width: "32%" }}>
        <TextField
          label={"Address Line 2"}
          placeholder={"Enter company address line 2"}
          required={false}
          id="addressLine2"
          name={"addressLine2"}
          autoComplete="off"
          sx={{ width: "100%" }}
          size="small"
        />
      </div>
      <div style={{ width: "32%" }}>
        <TextField
          type="number"
          label={"Postal Code"}
          placeholder={"Enter postal code"}
          required={true}
          id="pincode"
          name={"pincode"}
          value={pincode}
          onBlur={(e) => handleMinLengthPinCode(e)}
          onChange={handlePincodeChange}
          onKeyDown={preventNegativeValues}
          autoComplete="off"
          sx={{ width: "100%" }}
          size="small"
        />

        <div
          className={Classes.PhoneErrorMsg}
          id="pinErrMsg"
          style={{ fontSize: 14 }}
        >
          <p>Please enter a valid 6 digit postal code</p>
        </div>
      </div>
    </div>

    <div
      style={{
        marginTop: 15,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "32%" }}>
        <Autocomplete
          disablePortal
          id={"city"}
          name={"city"}
          options={cities}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label="City" required={true} />
          )}
          size="small"
        />
      </div>
      <div style={{ width: "32%" }}>
        <Autocomplete
          required={true}
          disablePortal
          id="state"
          name="state"
          options={states}
          ListboxProps={{ sx: { fontSize: 14 } }}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label="State/Province" required />
          )}
          size="small"
        />
      </div>
      <div style={{ width: "32%" }}>
        <TextField
          label={"Country"}
          placeholder={"Enter company country"}
          required={true}
          id="country"
          name={"country"}
          autoComplete="off"
          sx={{ width: "100%" }}
          size="small"
        />
      </div>
    </div>
    <div
      style={{
        marginTop: 10,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "32%" }}>
        <TextField
          type="number"
          label={"Phone Number"}
          placeholder={"Enter company phone number"}
          required={true}
          id="phoneNumber"
          name={"phoneNumber"}
          maxLength={10}
          onChange={handleNumChange}
          onBlur={(e) => handleMinLength(e)}
          onKeyDown={preventNegativeValues}
          value={num ? num : ""}
          autoComplete="off"
          sx={{ width: "100%" }}
          size="small"
        />

        <div className={Classes.PhoneErrorMsg} id="phoneErrMsg">
          <p>Please enter valid 10 digit phone number</p>
        </div>
      </div>
    </div>

    <div style={{ marginTop: 15 }}>
      <p>Bank Details</p>
      <div
        style={{
          marginTop: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label={"Bank Account Name"}
          placeholder={"Enter bank account name"}
          required={true}
          id="bankAccountName"
          name={"bankAccountName"}
          autoComplete="off"
          sx={{ width: "32%" }}
          size="small"
        />
        <TextField
          label={"Bank Account Number"}
          placeholder={"Enter bank account number"}
          required={true}
          type="number"
          id="bankAccountNumber"
          name={"bankAccountNumber"}
          autoComplete="off"
          sx={{ width: "32%" }}
          onKeyDown={preventNegativeValues}
          size="small"
        />

        <TextField
          label={"Bank Account IFSC"}
          placeholder={"Enter bank account IFSC"}
          required={true}
          id="bankAccountIfsc"
          name={"bankAccountIfsc"}
          autoComplete="off"
          sx={{ width: "32%" }}
          size="small"
        />
      </div>
    </div>
    <div
      style={{
        marginTop: 10,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "32%" }}>
        <TextField
          label={"Bank Name"}
          placeholder={"Enter bank name"}
          required={true}
          id="bankName"
          name={"bankName"}
          autoComplete="off"
          sx={{ width: "100%" }}
          size="small"
        />
      </div>
    </div>
    <div style={{ marginTop: 10 }}>
      <p>Invoice Details</p>
      <span style={{ fontSize: "12px", fontWeight: 500 }}>
        Example : <i>HM-STO</i> (prefix) / <i>24-25</i> (infix) / XXXX
      </span>
      <div
        style={{
          marginTop: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "32%",
          }}
        >
          <TextField
            label={"Invoice Prefix"}
            placeholder={"Set invoice prefix"}
            required={true}
            id="invoicePrefix"
            name={"invoicePrefix"}
            autoComplete="off"
            sx={{ width: "100%" }}
            size="small"
          />
          <div
            style={{
              fontSize: "small",
              color: "gray",
              textAlign: "center",
            }}
          >
            <span>
              <b>Company-PREFIX</b>
            </span>
            <span>/</span>
            <span>Company-INFIX</span>
            <span>/</span>
            <span>12</span>
          </div>
        </div>
        <div
          style={{
            width: "32%",
          }}
        >
          <TextField
            label={"Invoice Infix"}
            placeholder={"Set invoice infix"}
            id="invoiceInfix"
            name={"invoiceInfix"}
            autoComplete="off"
            sx={{ width: "100%" }}
            size="small"
          />
          <div
            style={{
              fontSize: "small",
              color: "gray",
              textAlign: "center",
            }}
          >
            <span>Company-PREFIX</span>
            <span>/</span>
            <span>
              <b>Company-INFIX</b>
            </span>
            <span>/</span>
            <span>12</span>
          </div>
        </div>
        <div
          style={{
            width: "32%",
          }}
                >
                  <TextField
                    label={'Starting Seq.'}
                    placeholder={'Set Starting Seq. (optional)'}
                    id='customInvoiceSeq'
                    name={'customInvoiceSeq'}
                    autoComplete='off'
                    sx={{ width: '100%' }}
                    size="small"
                  />
          {/* <TextField
            label={"Invoice Number"}
            required={true}
            placeholder={"Set invoice number"}
            id="invoiceNumber"
            name={"invoiceNumber"}
            autoComplete="off"
            sx={{ width: "100%" }}
            size="small"
          /> */}
        </div>
      </div>
    </div>
  </div>
  <DialogActions>
    <Button onClick={handleClose}>CLOSE</Button>
    <Button type="submit" id="btn">
      CREATE
    </Button>
  </DialogActions>
</form>


        {loading ? <Spinner /> : null}
      </Dialog>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </div>
  );
};

export default CreateCompany;
