import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete, Backdrop, Badge, Button, Chip, TextField, Tooltip } from "@mui/material";
import Table from '../../../components/table/table';
import UploadDocumentDialog from "../../../components/uploadDocumentDialog";
import { Close, Upload } from "@mui/icons-material";
import { Link } from "react-router-dom";
import axiosInstance from "../../../utility/axios-instance";
import { setLedgers } from '../../../store/ledgerSlice';
import CollectPayment from "../ordersNew/collectPayment";
import SnackBar from "../../../components/SnackBar";
import CreditInfoDialog from "./creditInfo";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { createdAt } from "../../../utility/textFormatting";
import BillingCodeLedger from "./billingCodeLedger";
import DialogWithInputs from "../../../components/uploadDocWithOtherInputs";
import { requestSignedUrls } from "../../../utility/s3";

export default function LedgerDashboard() {
  const [loading, setLoading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [sellers, setSellers] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [creditDocUploadData, setCreditDocUploadData] = useState(null);

  const ledgers = useSelector((state) => state.ledgers);
  const addresses = useSelector((state) => state.addresses);

  const [ledger, setLedger] = useState(null)
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

  const BillingCodeRenderCell = (row) => {
    return (
      <Button
        size="small"
        variant="outline"
        onClick={(e) => showLedgerPopup(e, row?.billingCode)}
      >
        {row?.billingCode}
      </Button>
    )
  }

  const CreditInfoRenderCell = (row) => {
    return (
      <div style={{ width: '100%',display: "flex", gap: 10, justifyContent: 'space-between' }}>
        <div>
          <p>Limit: {row?.creditLimit ? row?.creditLimit : 0}</p>
          <p>Days: {row?.creditDays ? row?.creditDays : 0}</p>
        </div>
        <Button
          size="small"
          variant="outline"
          onClick={(e) => showCreditInfoPopup(e, row?.billingCode, row?.creditLimit, row?.creditDays, row?.customerId)}
        >
          <BorderColorIcon fontSize="small" />
        </Button>
      </div>
    )
  }

  const RemarkRenderCell = (row) => {
    return (
      <div>{createdAt(row?.documentsAttached[0]?.uploadedDate)}</div>
    )
  }

  const DocumentAttachedRenderCell = (row) => {
    return (
      <div style={{display: 'flex'}}>
        {row?.documentsAttached?.length > 0 ? (
          <div>
          <Tooltip
            title={decodeURIComponent(
              row?.documentsAttached[0]?.signedUrlForKey?.substring(
                row?.documentsAttached[0]?.signedUrlForKey?.lastIndexOf("/") + 1,
                row?.documentsAttached[0]?.signedUrlForKey?.lastIndexOf("?")
              )
            )}
            arrow
          >
            <Chip
              size="small"
              clickable
              label={decodeURIComponent(
                row?.documentsAttached[0]?.signedUrlForKey?.substring(
                  row?.documentsAttached[0]?.signedUrlForKey?.lastIndexOf("/") + 1,
                  row?.documentsAttached[0]?.signedUrlForKey?.lastIndexOf("?")
                )
              )}
              component="a"
              href={row?.documentsAttached[0]?.signedUrlForKey}
            />
          </Tooltip>
          </div>
          ) : null}
        <Button
          size="icon"
          variant="outline"
          onClick={(e) =>
            showUploadDocPopup(e, row?.billingCode, row)
          }
        >
          <Upload className="w-4 h-4" />
        </Button>
      </div>
    )
  };

  const CustomerCreditNoteRenderCell = (row) => {
    return (
      <div style={{display: 'flex'}}>
        <Badge 
          badgeContent={row?.creditNotes?.length} 
          sx={{"& .MuiBadge-badge": {color: "white", backgroundColor: "#ff6200"}}}
        >
          <Button
            size="icon"
            variant="outline"
            onClick={(e) =>
              showCreditDocUploadPopup(e, row?.billingCode, row)
            }
          >
            <Upload className="w-4 h-4" />
          </Button>
        </Badge>
      </div>
    )
  };

  const SendPaymentLinkRenderCell = (row) => {
    <Button onClick={() => {setLedger(row)}}>
      Send Payment Link
    </Button>
  }

  const columnsBillingCode = [
    {
      field: "billingCode",
      headerName: "BILLING CODE",
      width: 150,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      renderCell: (params) => ( <BillingCodeRenderCell {...params?.row} />),
      customRenderCell: BillingCodeRenderCell
    },
    {
      field: "displayName",
      headerName: "CUSTOMER",
      width: 150,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "phoneNumber",
      headerName: "PHONE",
      width: 150,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "gst",
      headerName: "GST",
      width: 150,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "lastFulfillment",
      headerName: "Last Fulfillment Paid",
      width: 150,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "creditInfo",
      headerName: "Credit Info",
      width: 200,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      renderCell: (params) => ( <CreditInfoRenderCell {...params?.row} /> ),
      customRenderCell: CreditInfoRenderCell
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 150,
      renderCell: (params) => ( <RemarkRenderCell {...params?.row} /> ),
      customRenderCell: RemarkRenderCell,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "totalDue",
      width: 150,
      headerName: "Limit Utilized",
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
    },
    {
      field: "documentsAttached",
      headerName: "Document",
      sortable: false,
      width: 250,
      filterable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => ( <DocumentAttachedRenderCell {...params?.row} /> ),
      customRenderCell: DocumentAttachedRenderCell
    },
    {
      field: "customerCreditNote",
      headerName: "Credit Note",
      sortable: false,
      width: 250,
      filterable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => ( <CustomerCreditNoteRenderCell {...params?.row} /> ),
      customRenderCell: CustomerCreditNoteRenderCell
    },
    {
      field: "sendPaymentlink",
      headerName: "Send Payment Link",
      sortable: false,
      width: 200,
      filterable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => ( <SendPaymentLinkRenderCell {...params?.row} /> ),
      customRenderCell: SendPaymentLinkRenderCell,
    },
  ];

  const [billingCode, setBillingCode] = useState("");
  const [openDocUploadPopup, setOpenDocPopup] = useState(false);
  const [openCreditDocUploadPopup, setOpenCreditDocUploadPopup] = useState(false);
  const [openCreditInfo, setOpenCreditInfo] = useState(false);
  const [creditLimit, setCreditLimit] = useState(0);
  const [creditDays, setCreditDays] = useState(0);
  const [customerId, setCustomerId] = useState(null);
  const [openLedgerDetails, setOpenLedgerDetails] = useState(false);

  const showLedgerPopup = (e, id) => {
    setBillingCode(id);
    setOpenLedgerDetails(true);
  }

  const hideCloseLedgerPopup = () => {
    setBillingCode("");
    setOpenLedgerDetails(false);
  }


  const showUploadDocPopup = (e, id) => {
    setBillingCode(id);
    setOpenDocPopup(true);
  };

  const hideClosePopup = () => {
    setBillingCode("");
    setOpenDocPopup(false);
  };

  const showCreditDocUploadPopup = async (e, billingCode, row) => {
    setBillingCode(billingCode);
    const signedUrls = await requestSignedUrls(row?.creditNotes?.map((creditNote) => creditNote?.url_key));
    setCreditDocUploadData((prevCreditDocUploadData)=>{
       return {
        ...prevCreditDocUploadData,
        docUrls: signedUrls || [],
      } 
      });
    setOpenCreditDocUploadPopup(true);
  };

  const hideCreditDocUploadPopup = () => {
    setBillingCode("");
    setOpenCreditDocUploadPopup(false);
  };

  const showCreditInfoPopup = (e, id, creditLimit, creditDays, customerId) => {
    setCreditLimit(creditLimit);
    setCreditDays(creditDays);
    setCustomerId(customerId);
    setBillingCode(id);
    setOpenCreditInfo(true);
  };

  const hideCloseCreditInfoPopup = () => {
    setBillingCode("");
    setOpenCreditInfo(false);
    setCreditDays(0);
    setCreditLimit(0);
    setCustomerId(null);
  };


  const fetchCompanies = async () => {
    setLoading(true);
    await axiosInstance
      .get(`/company/getAllCompanies`)
      .then((res) => {
        setCompanies(res?.data?.data?.companies);
        setSelectedCompany(res?.data?.data?.companies[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const fetchSellers = async () => {
    setLoading(true);
    await axiosInstance
      .get(`/seller/fetchAllSellers`)
      .then((res) => {
        setSellers(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCompanies()
    fetchSellers()
  }, [])


  useEffect(() => {
    if (selectedCompany || selectedSeller) {
      setReRender((prev) => !prev);
    }
  }, [selectedCompany, selectedSeller]);


  return (
    <>
      <div className="">
        <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        <Backdrop
          sx={{ zIndex: "1000" }}
          open={!!ledger}
          onClick={() => {
            setLedger(null);
          }}
        >
          <div
            style={{
              width: "90%",
              maxWidth: "630px",
              backgroundColor: "#fff",
              borderRadius: "4px",
              padding: "0px",
              color: "#000",
              position: "relative",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Close
              style={{
                color: "black",
                position: "absolute",
                top: "2px",
                right: "2px",
                cursor: "pointer",
              }}
              onClick={() => {
                setLedger(null);
              }}
            />
            <CollectPayment
              ledger={ledger}
              setSnackBar={setSnackBar}
              isLedger={true}
              setLedger={setLedger}
            />
          </div>
        </Backdrop>
        <div className="">
          <h2>Ledger Dashboard</h2>
          <div style={{ display: "flex", flexWrap:'wrap', gap: 5 }}>
            <Autocomplete
              size="small"
              name="sellerId"
              sx={{ width: window.innerWidth < 540 ? '100%': '30%'}}
              disabled={selectedSeller ? true : false}
              id="multiple-limit-tags"
              options={companies ? companies : [{ name: "Loading...", id: 0 }]}
              value={selectedCompany}
              getOptionLabel={(company) => company?.companyNameInTally}
              getOptionSelected={(option, company) => option === company}
              onChange={(e, newVal) => setSelectedCompany(newVal)}
              renderInput={(params) => (
                <TextField {...params} label="Company" variant="outlined" />
              )}
            />

            <Autocomplete
              size="small"
              name="sellerId"
              sx={{ width: window.innerWidth < 540 ? '100%': '30%' }}
              id="multiple-limit-tags"
              options={sellers ? sellers : [{ name: "Loading...", id: 0 }]}
              value={selectedSeller}
              getOptionLabel={(seller) => seller?.sellerName}
              getOptionSelected={(option, seller) => option === seller}
              onChange={(e, newVal) => setSelectedSeller(newVal)}
              renderInput={(params) => (
                <TextField {...params} label="Seller" variant="outlined" />
              )}
            />
          </div>
        </div>
        {selectedCompany && (
          <Table
            columns={columnsBillingCode}
            dataPosition={"recentCustomersData"}
            endpoint={`/ledger/ledgerDashboard?companyId=${selectedCompany?.id ? selectedCompany?.id : ""}&sellerId=${selectedSeller?.id ? selectedSeller?.id : ""}`}
            rowId={"billingCode"}
            checkBoxSelection={false}
            setData={setLedgers}
            data={ledgers}
            version={"version-1"}
            reRender={reRender}
            mobileResponsive={true}
          />
        )}
      </div>
      <UploadDocumentDialog
        showPopup={openDocUploadPopup}
        hideClosePopup={hideClosePopup}
        url={`/ledger/uploadLedgerManually/${billingCode}`}
        setReRender={setReRender}
      />

      <DialogWithInputs
        dialogWithInputsOpen={openCreditDocUploadPopup}
        handleDialogWithInputsClose={hideCreditDocUploadPopup}
        dialogFormUploadData={creditDocUploadData}
        setDialogFormUploadData={setCreditDocUploadData}
        endpoint={`/pgOrder/admin/uploadCreditNoteForCustomer/${billingCode}`}
        setReRender={setReRender}
        documentFieldName={"creditNoteDocument"}
        documentName={"Credit Note"}
        dialogTitle={"Upload Credit Note : " + billingCode}
      >
    
          <TextField
            label="Credit Note Number"
            name="creditNoteNumber"
            variant="outlined"
            size="small"
            style={{ marginTop: "10px" }}
            value={creditDocUploadData?.creditNoteNumber}
            onChange={(e) => {
              setCreditDocUploadData((prevCreditDocUploadData) => ({
                ...prevCreditDocUploadData,
                creditNoteNumber: e?.target?.value,
              }));
            }}
            fullWidth
          />
         
          <TextField
            label="Remark"
            name="remark"
            variant="outlined"
            size="small"
            style={{ marginTop: "10px" }}
            value={creditDocUploadData?.remark}
            onChange={(e) => {
              setCreditDocUploadData((prevCreditDocUploadData) => ({
                ...prevCreditDocUploadData,
                remark: e?.target?.value,
              }));
            }}
            fullWidth
          />

      </DialogWithInputs>

      <CreditInfoDialog
        showPopup={openCreditInfo}
        hideClosePopup={hideCloseCreditInfoPopup}
        billingCode={billingCode}
        setReRender={setReRender}
        companyId={selectedCompany?.id}
        creditLimit={creditLimit}
        creditDays={creditDays}
        customerId={customerId}
      />

      <BillingCodeLedger
        open={openLedgerDetails}
        hideClosePopup={hideCloseLedgerPopup}
        billingCode={billingCode}
      />
    </>
  );
}
