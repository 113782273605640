import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Classes from "./pages/home/draftOrdersNew/css/draftOrderDetailPage.module.css";
import { setPinTab } from "./store/pinTabSlice";

const PinnedTab = ({ isNavCollapse }) => {
    const pinTab = useSelector((state) => state.pinTab);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleRedirectDashboard = (url) => {
        navigate(`/${url}`);
    }

    const handleRemove = (event, tabName) => {
        event.stopPropagation()
        const updatedPinTab = [...pinTab];
        const index = updatedPinTab.findIndex(tab => tab.name === tabName);
					
        // Update the values of the found empty tab
        updatedPinTab[index] = {
            ...updatedPinTab[index],  // Copy the existing empty tab (preserving any other properties if any)
            name: null,
            url: null,
            multiTabName: null
        };
        dispatch(setPinTab(updatedPinTab));
    }

    return (
        <div style={{ gap:"0.5rem",fontSize:14, marginLeft: !isNavCollapse ? 245 : 0 }} className={Classes.Company}>
            {pinTab?.filter(tab => tab?.name)?.map((tab, index) => (
                <div 
                    key={index} 
                    className={Classes.PinnedTab} 
                    onClick={() => handleRedirectDashboard(tab.url)} 
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                >
                    <p style={{ marginRight: 5 }}>{tab.name}</p>
                
                    <img 
                        src="https://www.svgrepo.com/show/436850/pin-slash-fill.svg"  
                        alt="Pin Icon" 
                        width="16px" 
                        height="14px"
                        style={{ 
                            filter: 'invert(80%)',  // Slightly dimmed default color
                            transform: 'rotate(30deg)', 
                            marginTop: 4, 
                            transition: 'all 0.3s ease-in-out'  // Smooth transition
                        }} 
                        onClick={(e) => handleRemove(e,tab?.name)}
                        onMouseOver={(e) => {
                            e.currentTarget.style.filter = 'invert(100%)';  // Full white on hover
                            e.currentTarget.style.transform = 'rotate(30deg) scale(1.1)'; // Slight scale
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.filter = 'invert(80%)';  // Revert to dimmed white
                            e.currentTarget.style.transform = 'rotate(30deg) scale(1)';  // Back to normal size
                        }}
                    />
                </div>
            ))}
            <div className={Classes['cust-divider']}></div>
        </div>
    )
}

export default PinnedTab;