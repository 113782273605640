import { Fragment, useState, useEffect } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import '../createOrders/css/styles.css';
import Button from '@mui/material/Button';
import SnackBar from '../../../components/SnackBar';
import { TextField, Autocomplete } from '@mui/material';
import { useParams } from 'react-router-dom';

const OrderAllocation = () => {
    const [loading, setLoading] = useState(false);
    const [allocateBins, setAllocateBins] = useState([]);
    const [unAllocateBins,setUnAllocateBins] = useState([]);
    const [toBeUnAllocatedData, setToBeUnAllocatedData] = useState([]);
    const [toBeAllocatedData,setToBeAllocatedData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [isActionInProgress, setIsActionInProgress] = useState(false);
    const { id } = useParams();

    const fetchOrder = async () => {
        if (id) {
            setLoading(true);
            await axiosInstance
                .get(`/pgOrder/admin/order/${id}`)
                .then(async (response) => {
                    const orderReponseData = response?.data?.data;
                    const lineItems = [...orderReponseData?.OLineItems?.fulfilledLineItems,...orderReponseData?.OLineItems?.unfulfilledLineItems];
                    const binAndQtyNew=[];
                    const binsAndQty = [];
                    if (lineItems.length !== 0) {
                        const deliveryNumbers = [];
                        for (let lineItem of lineItems) {
                            const { locationCode: fulfillmentLocationCode, sku } = lineItem
                            const quantityFilter = !lineItem?.fulfillmentName ? { "field": "availableQtyGt", "value": 0 } : { "field": "committedQtyGt", "value": 0 }
                            const filter = [{ "field": "sku", "operator": "equal", "value": sku },{ "field": "isVirtual", "operator": "equal", "value": false }, { "field": "locationCode", "value": fulfillmentLocationCode }, { "field": "binType", "value": 'Saleable' }, quantityFilter]

                            // make array of all unique deliveryNumbers which in Allocated State
                            if(!(deliveryNumbers.includes(lineItem?.deliveryNumber))) 
                                deliveryNumbers.push(lineItem?.deliveryNumber)

                                await axiosInstance
                                    .get(`/inventory/inventory-view?filter=${encodeURIComponent(JSON.stringify(filter))}&limit=30`)
                                    .then((res) => {
                                        const response = res.data?.data?.fetchedSkuBinMappings;
                                        if (response?.length !== 0) {
                                            for (let ele of response) {
                                                let binQty = !lineItem?.fulfillmentName ? `Avl-Qty ${ele?.availableQty}` : `Cmt-Qty ${ele?.committedQty}`
                                                binsAndQty.push({ 
                                                    binAndAvlQty: `${ele?.binCode}, ${binQty}`, 
                                                    sku: sku, 
                                                    type: !lineItem?.fulfillmentName? 'fulfilled' :'unFulfilled', 
                                                    lineItemId: lineItem?.oLineItemId,
                                                    binCode: ele?.binCode,
                                                })
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error, 'error')
                                        const errorMessage = error?.response?.data?.message
                                        setSnackBar((prevSnackBar) => {
                                            return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                                        });
                                    })
                        }

                        for(let deliveryNumber of deliveryNumbers){
                            deliveryNumber  && await axiosInstance
                            .get(`/orderAllocation/fetBinWiseAllocatedQty/${deliveryNumber}`)
                            .then((res) => {
                                const response = res.data?.data?.binWiseAllocatedQtyOfSku;
                                if (response?.length !== 0) {
                                    for (let ele of response) {
                                        binAndQtyNew.push({ 
                                            binAndCmtQty: `${ele?.binCode}, Cmt-Qty ${ele?.allocatedQty}`, 
                                            sku: ele?.sku, 
                                            type:'unFulfilled', 
                                            lineItemId: ele?.lineItemId,
                                            deliveryNumber: deliveryNumber,
                                            allocatedQty: ele?.allocatedQty,
                                            binCode: ele?.binCode
                                        })
                                    }
                                }
                            })
                            .catch((error) => {
                                const errorMessage = error?.response?.data?.message
                                setSnackBar((prevSnackBar) => {
                                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                                });
                            })
                        }
                        setUnAllocateBins(binAndQtyNew);
                        setAllocateBins(binsAndQty);
                    }

                    const unFulfilledLineItemsCombinedWithBins = orderReponseData?.OLineItems?.unfulfilledLineItems
                        ?.filter(unfulfilledLineItem => unfulfilledLineItem?.openQty > 0)
                        ?.map((unfulfilledLineItem) => {
                            const bin = binsAndQty?.find(bin => {
                                return (bin?.sku === unfulfilledLineItem?.sku 
                                && bin?.lineItemId === unfulfilledLineItem?.oLineItemId
                                && bin?.type === 'fulfilled');
                            });
                            return {
                                ...unfulfilledLineItem,
                                ...bin,
                            };
                        })
                        ?.map((unfulfilledLineItem) => {
                            return {
                                binAndAvlQty: unfulfilledLineItem?.binAndAvlQty,
                                binCode: unfulfilledLineItem?.binCode,
                                availableQty: unfulfilledLineItem?.unfulfilledQuantity,
                                qty: unfulfilledLineItem?.unfulfilledQuantity, // will be edited via frontend
                                sku: unfulfilledLineItem?.sku, 
                                type: unfulfilledLineItem?.type,
                                lineItemId: unfulfilledLineItem?.lineItemId,
                                orderId: orderReponseData?.orderId,
                                isChildLineItem: unfulfilledLineItem?.isChildLineItem,
                                locationCode: unfulfilledLineItem?.locationCode,
                            }
                        });                    
                    const fulfilledLineItemsWithSplitByAllocatedBins = orderReponseData?.OLineItems?.fulfilledLineItems
                        ?.filter(fulfilledLineItem => ['Allocated']?.includes(fulfilledLineItem?.status))
                        ?.reduce((acc, fulfilledLineItem) => {
                            const bins = binAndQtyNew?.filter(bin => {
                                return (bin?.sku === fulfilledLineItem?.sku 
                                && bin?.lineItemId === fulfilledLineItem?.oLineItemId 
                                && bin?.deliveryNumber === fulfilledLineItem?.deliveryNumber
                                && bin?.type === 'unFulfilled');
                            });
                            bins.map(bin => {
                                return acc.push({
                                    ...fulfilledLineItem,
                                    ...bin,
                                });
                            });
                            return acc;
                        }, [])
                        ?.map((fulfilledLineItem) => {
                            return {
                                binAndCmtQty: fulfilledLineItem?.binAndCmtQty,
                                binCode: fulfilledLineItem?.binCode,
                                allocatedQty: fulfilledLineItem?.allocatedQty,
                                qty: fulfilledLineItem?.allocatedQty, // will be edited via frontend
                                sku: fulfilledLineItem?.sku, 
                                type: fulfilledLineItem?.type,
                                lineItemId: fulfilledLineItem?.lineItemId,
                                deliveryNumber: fulfilledLineItem?.deliveryNumber,
                                orderId: fulfilledLineItem?.orderId,
                                fulfillmentName: fulfilledLineItem?.fulfillmentName,
                                isChildLineItem: fulfilledLineItem?.isChildLineItem,
                                locationCode: fulfilledLineItem?.locationCode,
                            }
                        });                    

                    setToBeUnAllocatedData([...fulfilledLineItemsWithSplitByAllocatedBins]);
                    setToBeAllocatedData([...unFulfilledLineItemsCombinedWithBins]);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    // setError(error?.response?.data?.message);
                });
        }
    }

    useEffect(() => {
        fetchOrder();
    }, []);

    const getBinsToAllocateFromForSku = ({sku, deliveryNumber, lineItemId}) => {
        const binsForSkuAndDeliveryNumber = allocateBins?.filter(bin => {
            return bin?.sku === sku && bin.deliveryNumber === deliveryNumber 
            && bin?.lineItemId === lineItemId && bin?.type === 'fulfilled';
        });
        if(allocateBins?.length !== 0 && binsForSkuAndDeliveryNumber?.length !== 0){
            return binsForSkuAndDeliveryNumber?.map(bin => bin?.binAndAvlQty)?.unique();
        }else{
            return []
        }
    }

    const createOrderAllocation = (data) => {
        axiosInstance
            .post(`/orderAllocation/createOrderAllocation`, data)
            .then((res) => {
                fetchOrder();
                const successMessage = `Order ${data?.mode} successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setIsActionInProgress(false);
                setLoading(false);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setIsActionInProgress(false);
                setLoading(false);
            })
    }

    const handleAllocationUnAllocation = ({
            processedLineItem,
            allocationMode,
        }) => {
        const { sku, locationCode, isChildLineItem, orderId, binCode, qty } = processedLineItem;
        setLoading(true);
        if (isActionInProgress) {
            return;
        }
        setIsActionInProgress(true);
        if (!binCode) {
            const errorMessage = "Please Select Bin"
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
            });
            return;
        }
        if (!qty) {
            const errorMessage = "Please Enter Quantity"
            setSnackBar((prevSnackBar) => {
                return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
            });
            return;
        }
        const data = {
            "sku": sku,
            "binCode": binCode,
            "order_id": `${orderId}`,
            "locationCode": locationCode,
            "quantity": qty,
            "mode": allocationMode,
            "isChildLineItem" : isChildLineItem,
        }
        createOrderAllocation(data)
    }

    const handleAllocationUnAllocationForAll = async (allocationMode, processedData) => {
        setLoading(true);
        const processedLineItems = processedData
            ?.map((toBeProcessedLineItem) => {
                const { sku, locationCode, isChildLineItem, orderId, binCode, qty } = toBeProcessedLineItem;
                const data = {
                    "sku": sku,
                    "binCode": binCode,
                    "order_id": `${orderId}`,
                    "locationCode": locationCode,
                    "quantity": qty,
                    "mode": allocationMode,
                    "isChildLineItem" : isChildLineItem,
                }
                return data;
            })

            await axiosInstance
                .post(`/orderAllocation/createOrderAllocationInBulk`, {data: { lineItems: processedLineItems }})
                .then((res) => {
                    fetchOrder();
                    const successMessage = `Order ${allocationMode === "allocate" ? "Allocated" : "Un-Allocated"} Successfully`
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    setIsActionInProgress(false);
                    setLoading(false);
                })
                .catch((error) => {
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                    setIsActionInProgress(false);
                    setLoading(false);
                })
    
    }

    return (
        <Fragment>
            <div>
                <div style={{ display:'flex',justifyContent:'space-between',flexWrap:'wrap',padding:12 }}>
                    <div style={{ width:'100%',height:'fit-content' }}>
                        {toBeAllocatedData?.length !== 0 && (
                            <>
                                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10 }}>
                                    <h6>Allocate</h6>
                                    <Button variant='contained' onClick={
                                        () => handleAllocationUnAllocationForAll('allocate', toBeAllocatedData)
                                    }>Allocate All</Button>
                                </div>

                                <div style={{marginBottom:20,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                                    <div style={{display:'flex',padding:'10px 0px',backgroundColor:'#243750',color:'#fff',borderRadius:"4px 4px 0px 0px"}}>
                                        <h6 style={{ width:'6%',padding:"0px 10px",marginBottom:0 }} className={Classes.orderAllocateDetailSNoColumn}>
                                            S.No
                                        </h6>
                                        <h6 style={{ width:'25%',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Sku
                                        </h6>
                                        <h6 style={{ padding:'0px 5px',width:'10%',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Location
                                        </h6>
                                        <h6 style={{ width:'11%',padding:"0px 10px",textAlign:'center',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Order Qty
                                        </h6>
                                        <h6 style={{ padding:'0px 10px',width:'26%',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Bin
                                        </h6>
                                        <h6 style={{ width:'11%',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Fulfill Qty
                                        </h6>
                                        <h6 style={{ width:'11%',marginBottom:0,textAlign:'center' }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Allocate
                                        </h6>
                                        <h6 style={{ width:'80%',marginBottom:0 }} className={Classes.showColumnInOrderAllocationTab}>
                                            Details
                                        </h6>
                                    </div>

                                    {(toBeAllocatedData)?.map((toBeAllocatedLineItem, index) => (
                                        <div 
                                            key={index} 
                                            style={{display:'flex',padding:'10px 0px',borderBottom:'1px solid lightgray',fontSize: 14, flexWrap:'wrap' }}
                                        >
                                            <p style={{ padding:"0px 10px",width:'6%',margin:'auto 0px' }} className={Classes.orderAllocateDetailSNoColumn}>{index + 1}</p>
                                            <div className={Classes.orderAllocationDetailsValues}>
                                                <p style={{ width:'27%',margin:'auto 0px',fontWeight:600 }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn} style={{width:68}}>SKU :</span> {toBeAllocatedLineItem?.sku}
                                                </p>
                                                <p style={{ padding:"0px 5px",width:'10%',margin:'auto 0px' }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn} style={{width:68}}>Location :</span> {toBeAllocatedLineItem?.locationCode}
                                                </p>
                                                <p style={{ padding:"0px 10px",textAlign:'center',width:'11%',margin:'auto 0px' }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn}>Order Qty :</span> {toBeAllocatedLineItem?.availableQty}
                                                </p>
                                                <div style={{ padding:"0px 10px",width:'28%',margin:'auto 0px', display:'flex' }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn} style={{width:95}}>Bin : </span>
                                                    <Autocomplete
                                                        freeSolo
                                                        disablePortal
                                                        id={`allocateBin-${toBeAllocatedLineItem?.lineItemId}`}
                                                        name={`allocateBin-${toBeAllocatedLineItem?.lineItemId}`}
                                                        getOptionLabel={(option) => option}
                                                        sx={{"& .MuiAutocomplete-input": {padding:'0px 4px 0px 8px'}, width:'100%'}}
                                                        ListboxProps={{ sx: { fontSize: 13 }, padding: '0px' }}
                                                        value={toBeAllocatedLineItem?.binAndAvlQty}
                                                        options={getBinsToAllocateFromForSku({
                                                            sku: toBeAllocatedLineItem?.sku,
                                                            deliveryNumber: toBeAllocatedLineItem?.deliveryNumber,
                                                            lineItemId: toBeAllocatedLineItem?.lineItemId,
                                                        })}
                                                        onBlur={(event)=>{
                                                            setToBeAllocatedData((prevToBeAllocatedData) => {
                                                                return prevToBeAllocatedData.map((prevToBeAllocatedLineItem) => {
                                                                    if (
                                                                        prevToBeAllocatedLineItem?.lineItemId === toBeAllocatedLineItem?.lineItemId 
                                                                        && prevToBeAllocatedLineItem?.sku === toBeAllocatedLineItem?.sku
                                                                        && prevToBeAllocatedLineItem?.binCode === toBeAllocatedLineItem?.binCode
                                                                    )
                                                                    {
                                                                        return {
                                                                            ...prevToBeAllocatedLineItem,
                                                                            binAndAvlQty: event?.target?.value,
                                                                            binCode: event?.target?.value?.split(',')[0],
                                                                        }
                                                                    }
                                                                    return prevToBeAllocatedLineItem;
                                                                })
                                                            })
                                                        }}
                                                        size="small"
                                                        renderInput={(params) =>
                                                            <TextField {...params} label={"Bin"} required={true}/>
                                                        }
                                                    />
                                                </div>
                                                <div style={{width:'13%',margin:'auto 0px', display:'flex' }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn} style={{width:85}}>Fulfill : </span>
                                                    <TextField
                                                        required={true}
                                                        id={`allocateQty-${toBeAllocatedLineItem?.lineItemId}`}
                                                        name={`allocateQty-${toBeAllocatedLineItem?.lineItemId}`}
                                                        label='QTY'
                                                        key={toBeAllocatedLineItem?.availableQty}
                                                        defaultValue={toBeAllocatedLineItem?.availableQty}
                                                        autoComplete='off'
                                                        type='number'
                                                        size='small'
                                                        error={
                                                            toBeAllocatedLineItem?.qty === 0 ||
                                                            toBeAllocatedLineItem?.qty > toBeAllocatedLineItem?.availableQty
                                                        }
                                                        helperText={
                                                            toBeAllocatedLineItem?.qty === 0 ? "No Quantity to Allocate" :
                                                            toBeAllocatedLineItem?.qty > toBeAllocatedLineItem?.availableQty ? "Quantity Exceeds Order Quantity" : ""
                                                        }
                                                        FormHelperTextProps={{ 
                                                            style: {    
                                                                textAlign: 'center',
                                                                width: "100%",
                                                                marginLeft: 0,
                                                                marginRight: 0, 
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            setToBeAllocatedData((prevToBeAllocatedData) => {
                                                                return prevToBeAllocatedData.map((prevToBeAllocatedLineItem) => {
                                                                    if (
                                                                        prevToBeAllocatedLineItem?.lineItemId === toBeAllocatedLineItem?.lineItemId 
                                                                        && prevToBeAllocatedLineItem?.sku === toBeAllocatedLineItem?.sku
                                                                        && prevToBeAllocatedLineItem?.binCode === toBeAllocatedLineItem?.binCode
                                                                    )
                                                                    {
                                                                        return {
                                                                            ...prevToBeAllocatedLineItem,
                                                                            qty: +event?.target?.value,
                                                                        }
                                                                    }
                                                                    return prevToBeAllocatedLineItem;
                                                                })
                                                            })
                                                        }}
                                                    />
                                                </div>

                                                <div style={{width:'11%',textAlign:'center',margin:'auto 0px'}} className={Classes.ResponsiveColumn}>
                                                    <Button
                                                        variant="contained"
                                                        id={`button-${toBeAllocatedLineItem?.sku}`}
                                                        onClick={(event) => allocateBins?.some(bin => bin?.sku === toBeAllocatedLineItem?.sku) &&
                                                            handleAllocationUnAllocation({
                                                                processedLineItem: toBeAllocatedLineItem,
                                                                allocationMode: 'allocate',
                                                            })}
                                                        disabled={
                                                            (allocateBins?.some(bin => bin?.sku === toBeAllocatedLineItem?.sku) ? false : true) 
                                                            && isActionInProgress
                                                            || toBeAllocatedLineItem?.qty === 0 
                                                            || toBeAllocatedLineItem?.qty > toBeAllocatedLineItem?.availableQty
                                                        }
                                                        style={{
                                                            textTransform: 'capitalize',
                                                            padding: '2px 6px',
                                                            fontSize: 13,
                                                            cursor: allocateBins?.some(bin => bin?.sku === toBeAllocatedLineItem?.sku) ? 'pointer' : 'default'
                                                        }}
                                                    >
                                                        Allocate
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {toBeUnAllocatedData?.length !== 0 && (
                            <>
                                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10 }}>
                                    <h6>Un-Allocate</h6>
                                    <Button variant='contained' onClick={
                                        () => handleAllocationUnAllocationForAll('unallocate', toBeUnAllocatedData)
                                    }>Un-Allocate All</Button>
                                </div>

                                <div style={{boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                                    <div style={{display:'flex',padding:'10px 0px',backgroundColor:'#243750',color:'#fff',borderRadius:"4px 4px 0px 0px", justifyContent:'space-between'}}>
                                        <h6 style={{ padding:"0px 10px",marginBottom:0 }} className={Classes.orderAllocateDetailSNoColumn}>
                                            S.No
                                        </h6>
                                        <h6 style={{ width:'25%',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Sku
                                        </h6>
                                        <h6 style={{ padding:'0px 5px',width:'10%',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Location
                                        </h6>
                                        <h6 style={{ width:'11%',padding:"0px 10px",textAlign:'center',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Order Qty
                                        </h6>
                                        <h6 style={{ padding:'0px 10px',width:'26%',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Bin
                                        </h6>
                                        <h6 style={{ width:'11%',marginBottom:0 }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Un-Fulfill Qty
                                        </h6>
                                        <h6 style={{ width:'11%',marginBottom:0,textAlign:'center' }} className={Classes.hideColumnInOrderAllocationTab}>
                                            Un-Allocate
                                        </h6>
                                        <h6 style={{ width:'80%',marginBottom:0 }} className={Classes.showColumnInOrderAllocationTab}>
                                            Details
                                        </h6>
                                    </div>

                                    {toBeUnAllocatedData?.map((toBeUnAllocatedLineItem, index) => (
                                        <div 
                                            key={index} 
                                            style={{display:'flex',padding:'10px 0px',borderBottom:'1px solid lightgray',fontSize:14, flexWrap:'wrap' }}
                                        >
                                            <p style={{ padding:"0px 10px",margin:'auto 0px' }} className={Classes.orderAllocateDetailSNoColumn}>{index + 1}</p>
                                            <div className={Classes.orderAllocationDetailsValues}>
                                                <p style={{ width:'27%',margin:'auto 0px',fontWeight:600 }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn} style={{width:68}}>SKU :</span> {toBeUnAllocatedLineItem?.sku}
                                                </p>
                                                <p style={{ padding:"0px 5px",width:'10%',margin:'auto 0px' }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn} style={{width:68}}>Location :</span> {toBeUnAllocatedLineItem?.locationCode}
                                                </p>
                                                <p style={{ padding:"0px 10px",textAlign:'center',width:'11%',margin:'auto 0px' }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn}>Order Qty :</span> {toBeUnAllocatedLineItem?.allocatedQty}
                                                </p>

                                                <div style={{ padding:"0px 10px",width:'28%',margin:'auto 0px', display:'flex' }} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn} style={{width:95}}>Bin : </span>
                                                    <Autocomplete
                                                        freeSolo
                                                        disablePortal
                                                        disabled={true}
                                                        id={`unAllocateBin-${toBeUnAllocatedLineItem?.lineItemId}`}
                                                        name={`unAllocateBin-${toBeUnAllocatedLineItem?.lineItemId}`}
                                                        getOptionLabel={(option) => option}
                                                        sx={{"& .MuiAutocomplete-input": {padding:'0px 4px 0px 8px'}, width:'100%' }}
                                                        ListboxProps={{ sx: { fontSize: 13 }, padding: '0px' }}
                                                        value={toBeUnAllocatedLineItem?.binAndCmtQty}
                                                        options={unAllocateBins?.map(bin => bin?.binAndCmtQty)}
                                                        size="small"
                                                        renderInput={(params) =>
                                                            <TextField {...params} label={"Bin"} required={true} />
                                                        }
                                                    />
                                                </div>

                                                <div style={{width:'13%',margin:'auto 0px', display:'flex'}} className={Classes.ResponsiveColumn}>
                                                    <span className={Classes.showResponsiveColumn} style={{width:85}}>Un-Fulfill : </span>
                                                    <TextField
                                                        required={true}
                                                        id={`unAllocateQty-${toBeUnAllocatedLineItem?.lineItemId}`}
                                                        name={`unAllocateQty-${toBeUnAllocatedLineItem?.lineItemId}`}
                                                        label='QTY'
                                                        key={toBeUnAllocatedLineItem?.allocatedQty}
                                                        defaultValue={toBeUnAllocatedLineItem?.allocatedQty}
                                                        autoComplete='off'
                                                        type='number'
                                                        size='small'
                                                        error={
                                                            toBeUnAllocatedLineItem?.qty === 0 ||
                                                            toBeUnAllocatedLineItem?.qty > toBeUnAllocatedLineItem?.allocatedQty
                                                        }
                                                        helperText={
                                                            toBeUnAllocatedLineItem?.qty === 0 ? "No Quantity to Un-Allocate" :
                                                            toBeUnAllocatedLineItem?.qty > toBeUnAllocatedLineItem?.allocatedQty ? "Quantity Exceeds Allocated Quantity" : ""
                                                        }
                                                        FormHelperTextProps={{ 
                                                            style: {     
                                                                textAlign: 'center',
                                                                width: "100%",
                                                                marginLeft: 0,
                                                                marginRight: 0, 
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            setToBeUnAllocatedData((prevToBeUnAllocatedData) => {
                                                                return prevToBeUnAllocatedData.map((prevToBeUnAllocatedLineItem) => {
                                                                    if (
                                                                        prevToBeUnAllocatedLineItem?.lineItemId === toBeUnAllocatedLineItem?.lineItemId 
                                                                        && prevToBeUnAllocatedLineItem?.sku === toBeUnAllocatedLineItem?.sku
                                                                        && prevToBeUnAllocatedLineItem?.deliveryNumber === toBeUnAllocatedLineItem?.deliveryNumber
                                                                        && prevToBeUnAllocatedLineItem?.binCode === toBeUnAllocatedLineItem?.binCode
                                                                    ){
                                                                        return {
                                                                            ...prevToBeUnAllocatedLineItem,
                                                                            qty: +event?.target?.value,
                                                                        }
                                                                    }
                                                                    return prevToBeUnAllocatedLineItem;
                                                                })
                                                            })
                                                        }}
                                                    />
                                                </div>

                                                <div style={{width:'11%',textAlign:'center',margin:'auto 0px'}} className={Classes.ResponsiveColumn}>
                                                    <Button
                                                        variant="contained"
                                                        id={`button-${toBeUnAllocatedLineItem?.sku}`}
                                                        onClick={(event) => unAllocateBins?.some(bin => bin?.sku === toBeUnAllocatedLineItem?.sku) &&
                                                            handleAllocationUnAllocation(
                                                                {
                                                                    processedLineItem: toBeUnAllocatedLineItem,
                                                                    allocationMode: "unallocate",
                                                                }
                                                            )}
                                                        disabled={
                                                            (unAllocateBins?.some(bin => bin?.sku === toBeUnAllocatedLineItem?.sku) ? false : true) 
                                                            && isActionInProgress
                                                            || toBeUnAllocatedLineItem?.qty === 0 
                                                            || toBeUnAllocatedLineItem?.qty > toBeUnAllocatedLineItem?.allocatedQty
                                                        }
                                                        style={{
                                                            textTransform: 'capitalize',
                                                            padding: '2px 6px',
                                                            fontSize: 13,
                                                            cursor: unAllocateBins?.some(bin => bin?.sku === toBeUnAllocatedLineItem?.sku) ? 'pointer' : 'default'
                                                        }}
                                                    >
                                                        Un-Allocate
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {loading ? <Spinner /> : null}
            </div>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}

export default OrderAllocation;