import { Fragment, useState, useEffect } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import '../createOrders/css/styles.css';

const CustomerModal = (props)=> {
    const [loading,setLoading] = useState(false);
    const [salesTeam,setSalesTeam] = useState([]);
    const [boardingRoles,setOnBoardingRoles] = useState('Retailer');
    const [states,setStates] = useState([]);
    const [cities,setCities] = useState([]);
    const [areas,setAreas] = useState([]);

    const { 
        setDetail,
        errorMessage,
        setErrorMessage,
        gstNO,
        setGstNO,
        num,
        setNum,
        setDisplayName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        businessName,
        setBusinessName,
        popUpType,
        fetchCustomers,
        salesUser,
        setSalesUser,
        preSalesUser,
        setPreSalesUser,
        area,
        setArea,
        city,
        setCity,
        email,
        setEmail,
        modeOfCommunication,
        setModeOfCommunication,
        selectedState,
        setSelectedState,
        setReRender,
        reRender
    } = props;

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    const handleBusinessName = (event) => {
        setBusinessName(event.target.value)
    }

    useEffect(() => {
        const businessDetail = businessName !== '' ? businessName : `${firstName} ${lastName}`;
        setDisplayName(businessDetail ? businessDetail : '');
    }, [firstName, lastName, businessName]);

    useEffect(() => {
        axiosInstance
            .get('/admin/salesTeam')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data?.users) {
                    arr.push({label:ele?.name,Id:ele?._id})
                }
                setSalesTeam(arr);
            })
    },[]);

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setStates(response.data.data);
            })
    },[])

    const handleNumChange = event => {
        const limit = 10;
        setNum(event.target.value.slice(0, limit));
    };

    const handleSubmit = (event)=> {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('btn')
        button.innerHTML = 'CREATING...';
        const roles = {
            "Retailer": "retailer",
            "Interior": "interior",
            "Oem" : "oem",
            // "Special Retailer": "special_retailer"
        }

        const data = {
            email: event.target.email.value,
            phone:`+91${event.target.phone.value}`,
            firstName: event.target.firstName.value,
            lastName: event.target.lastName.value ? event.target.lastName.value : undefined,
            gstNumbers: event.target.gst.value ? event.target.gst.value : undefined,
            customerRole: event.target.onBoardingRoles.value ? roles[event.target.onBoardingRoles.value] : undefined,
            businessName: event.target.businessName.value ? event.target.businessName.value : undefined,
            city: event.target.city.value ? event.target.city.value: undefined,
            area: event.target.area.value ? event.target.area.value : undefined,
            salesUser: salesUser ? salesUser : undefined,
            preSalesUser: preSalesUser ? preSalesUser : undefined,
            storeFrontImage: event?.target?.storeFrontPhoto?.files?.length !==0 ? event?.target?.storeFrontPhoto?.files[0] : undefined,
            modeOfCommunication: modeOfCommunication ? modeOfCommunication : undefined
        }

        if (event.target.onBoardingRoles.value === 'Retailer') {
            data.proofOfVisitImage = event?.target?.proofOfVisitPhoto?.files?.length !==0 ? event?.target?.proofOfVisitPhoto?.files[0] : undefined;
        }

        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        axiosInstance
            .post('/fieldSalesVisit/create', formData)
            .then((res) =>{
                const form = document.getElementById('form');
                form.reset();
                props.onHideModal();
                if (!popUpType) {
                    setDetail([res.data.data]);
                } else {
                    fetchCustomers();
                }
                setLoading(false);
                setErrorMessage('');
                setSalesUser('');
                setPreSalesUser('');
                setEmail('');
                setCity('');
                setArea('');
                button.innerHTML = 'CREATED';
            }).catch((err) => {
                button.innerHTML = 'CREATE';
                setLoading(false);

                if(err.response.data.hasOwnProperty('keyPattern')){
                    const existData = Object.keys(err.response.data.keyPattern)
 
                    if(existData[0] === 'email'){
                        setErrorMessage('This email already exists, Please enter another email')
                    }else if( existData[0] === 'phone'){
                        setErrorMessage('This number already exists, Please enter another number')
                    }   
                }else{
                    if(err.response.data.hasOwnProperty('message')){
                        if(err.response.data.message === 'Error creating user on Shopify'){
                            setErrorMessage('Please try to provide different input, If Issue Still Persist Then Contact Tech Team')   
                        }else{
                            setErrorMessage(err.response.data.message)
                        }
                    }
                }
            })
            if (reRender && setReRender) {
                setReRender(!reRender)
            }
    }

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            }else{
                document.getElementById('btn').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }

    const handleValidGst = (event) =>{
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if(event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }

    const onBoardingRoles = [{label:'Retailer'},{label:'OEMs'},{label:'Interior'}];

    const handleEmail = (event) => {
        if (event.target.value) {
            setEmail(event.target.value)
        }
    }

    useEffect(()=> {
        if (selectedState) {
            axiosInstance
            .get(`/pincode/getAllCities?state=${selectedState}`)
            .then((response) => {
                setCities(response.data.data);
            })
        }
    },[selectedState]);

    useEffect(()=> {
        if (city) {
            axiosInstance
            .get(`/others/areas/${city}`)
            .then((response) => {
                const areas = [];
                for (let areaName of response.data.data) {
                    areas.push({id:areaName?._id,label:areaName?.area})
                }
                setAreas(areas);
            })
        }
    },[city]);

    return (
        <Fragment>
            {props.onShowModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'7%',bottom:'9%'}}
                >
                    <div className={Classes.BoxContent} style={{padding:'16px 25px'}}>
                        <p>Create Field Sales</p>
                    </div>
                    
                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form'
                    >
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>
                        <div style={{padding:20}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'49%',marginRight:24}}>
                                    <Autocomplete
                                        required
                                        freeSolo
                                        disablePortal
                                        id="onBoardingRoles"
                                        name="onBoardingRoles"
                                        value={boardingRoles || ''}
                                        options={onBoardingRoles}
                                        sx={{width: '100%',"& label": {top: boardingRoles ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setOnBoardingRoles(newValue.label);
                                        }}
                                        // isOptionEqualToValue={(option, value) => option === value}
                                        renderInput={(params) => <TextField {...params} label="On-Boarding Roles"/>}
                                    />
                                </div>

                                <div style={{width:'49%'}}>
                                    <TextField
                                        id='businessName'
                                        name="businessName"
                                        label="Business Name"
                                        placeholder='Enter Business Name'
                                        onChange={handleBusinessName}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top:businessName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>
                            </div>
                    
                            <div style={{display:'flex',marginTop:20}}>
                                <div style={{ width:'33%',marginRight:24 }}>
                                    <TextField
                                        required
                                        id='firstName'
                                        name="firstName"
                                        label="First Name"
                                        placeholder='First Name'
                                        autoComplete='off'
                                        onChange={handleFirstNameChange}
                                        sx={{ width: '100%',"& label": {top:firstName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'33%',marginRight:24 }}>
                                    <TextField
                                        id='lastName'
                                        name="lastName"
                                        label="Last Name"
                                        placeholder='Last Name'
                                        autoComplete='off'
                                        onChange={handleLastNameChange}
                                        sx={{ width: '100%',"& label": {top: lastName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'54%' }}>
                                    <TextField
                                        id='displayName'
                                        name="displayName"
                                        label="Display Name"
                                        placeholder='Display Name'
                                        value={businessName ? businessName : firstName ? firstName + " " + lastName: ''}
                                        autoComplete='off'
                                        disabled={true}
                                        sx={{ width: '100%',"& label": {top: firstName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{ width:'32%' }}>
                                    <TextField 
                                        required
                                        id='phoneNumber'
                                        name="phone"
                                        type="number"
                                        label="Phone Number"
                                        maxLength={10}
                                        onChange={handleNumChange}
                                        onBlur={(e) => handleMinLength(e)}
                                        placeholder='Enter Your 10 Digit Phone Number'
                                        value={num}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: num ? "0%" : "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{width:'32%'}}>
                                    <TextField 
                                        required
                                        id='email'
                                        name="email"
                                        label="Email"
                                        onBlur={handleEmail}
                                        placeholder='Enter Email'
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: email ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <div>
                                        <TextField 
                                            id='gstNumber'
                                            name="gst"
                                            label="GST Number"
                                            placeholder='Enter valid Gst Number'
                                            inputProps={{ maxLength:15,minLength:15 }}
                                            onBlur={handleValidGst}
                                            autoComplete='off'
                                            sx={{ width: '100%',"& label": {top: gstNO ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </div>

                                    <div 
                                        id='isValidGst'
                                        className={Classes.IsValidGst}
                                    >
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>

                            <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                <p>Please enter a valid 10 digit mobile number</p>
                            </div>


                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{width:'32%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="states"
                                        name='states'
                                        options={states}
                                        sx={{width: '100%',"& label": {top: selectedState ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setSelectedState(newValue.id);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="States" required/>}
                                    />
                                </div>

                                <div style={{width:'32%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="city"
                                        name='city'
                                        options={cities}
                                        disabled={!selectedState ? true: false}
                                        sx={{width: '100%',"& label": {top: city ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setCity(newValue.id ? newValue.id : event.target.value);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="City"/>}
                                    />
                                </div>

                                {/* {boardingRoles && boardingRoles === 'Retailer' && ( */}
                                    <div style={{width:'33%'}}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            id="area"
                                            name='area'
                                            options={areas}
                                            disabled={!city ? true: false}
                                            sx={{width: '100%',"& label": {top: area ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                            onBlur={(event, newValue) => {setArea(newValue ? newValue.id : event.target.value )}}
                                            renderInput={(params) => <TextField {...params} label="Area"/>}
                                        />
                                    </div>
                                {/* )} */}
                            </div>

                            <div 
                                className={Classes.ContentBox} 
                                style={{marginTop:25}}
                            >
                                <div style={{width:'32%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="salesUser"
                                        options={salesTeam}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{width: '100%',"& label": {top: salesUser ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setSalesUser(newValue.Id);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Sales User"/>}
                                    />
                                </div>

                                <div style={{width:'32%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="preSalesUser"
                                        options={salesTeam}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{width: '100%',"& label": {top: preSalesUser ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setPreSalesUser(newValue.Id);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Pre Sales User"/>}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="modeOfCommunication"
                                        name="modeOfCommunication"
                                        options={[{label:'Field Visit'},{label:'On-Call'}]}
                                        sx={{width: '100%',"& label": {top: modeOfCommunication ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setModeOfCommunication(newValue.label);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Mode Of Communication"/>}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox} style={{marginTop:20}}>
                                {boardingRoles && boardingRoles === 'Retailer' && (
                                    <div style={{width:'49%'}}>
                                        <p style={{fontSize: 14,marginBottom: 8}}>Upload Photo of Storefront</p>
                                        <input
                                            type="file"
                                            id="updateInput"
                                            name='storeFrontPhoto'
                                            accept="image/*"
                                            className="form-control" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
                                        ></input>
                                    </div>
                                )}

                                <div style={{width:'49%'}}>
                                    <p style={{fontSize: 14,marginBottom: 8}}>Upload Photo (Proof of Visit)</p>
                                    <input
                                        type="file"
                                        id="updateInput"
                                        name='proofOfVisitPhoto'
                                        accept="image/*"
                                        className="form-control" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
                                    ></input>
                                </div>
                            </div>
                        </div>


                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'8%',
                                position:'fixed',
                                boxShadow:'none',
                                borderTop:0
                            }}
                        >
                            <button
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={props.onHideModal}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                CREATE
                            </button>
                        </div>
                        
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default CustomerModal;