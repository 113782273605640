import { useState, Fragment } from 'react';
import Chip from '@mui/material/Chip';
import Table from "../../../components/table/table";
import { setPurchaseOrders } from '../../../store/purchaseOrdersSlice';
import { useSelector } from 'react-redux';
import InvoiceDialog from '../inwardPurchaseOrder/invoiceDialog';
import ChallanDialog from '../inwardPurchaseOrder/challanDialog';

const PurchaseOrders = (props) => {
	const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
	const [challanDialogOpen, setChallanDialogOpen] = useState(false);
	const [poRowData, setPoRowData] = useState(null);
	const [reRender, setReRender] = useState(false);
	const purchaseOrders = useSelector((state) => state.purchaseOrders);

	const columnVisibilityModel = {
		locationName: false
	}

	const handleInvoiceDialogOpen = (row) => {
        setPoRowData(row);
        setInvoiceDialogOpen(true);
    };

    const handleInvoiceDialogClose = () => {
        setInvoiceDialogOpen(false);
        setPoRowData(null);
    };

    const handleChallanDialogOpen = (row) => {
        setPoRowData(row);
        setChallanDialogOpen(true);
    };

    const handleChallanDialogClose = () => {
        setChallanDialogOpen(false);
        setPoRowData(null);
    };

	const reRenderPo = () => {
		setReRender(!reRender);
	}
	 
	const InvoiceUrlRenderCell = (row) => {
		return (
			<Chip
				style={{ backgroundColor: '#ffea88' }}
				label="View"
				onClick={(e) => {
					e.stopPropagation();
					handleInvoiceDialogOpen(row)
				}}
			/>
		)
	}

	const ChallanUrlRenderCell = (row) => {
		return (
			<Chip
				style={{ backgroundColor: '#ffea88' }}
				label="View"
				onClick={(e) => {
					e.stopPropagation();
					handleChallanDialogOpen(row)
				}}
			/>
		)
	}
	
	let columns = [
		{ 
			field: 'purchaseOrderName', 
			headerName: 'ORDER', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'createdAt', 
			// filterable: false, 
			headerName: 'DATE', 
			flex: 1.8, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			// type: 'enum'
		},
		{ 
			field: 'vendorName', 
			headerName: 'VENDOR', 
			flex: 1.5, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'vendorName',
			tableName: 'PoVendorDetails'
		},
		{ 
			field: 'locationCode', 
			headerName: 'Location Code', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'locationCode',
			tableName: 'PoLocationDetails' 
		},
		{ 
			field: 'locationName', 
			headerName: 'Location Name', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'locationName',
			tableName: 'PoLocationDetails'
		},
		{
			field: 'paymentStatus',
			headerName: 'PAY STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			flex: 1.5,
			enumValue: ['Unpaid', 'Paid'],
			renderCell: (params) => (
				params.value === "Unpaid" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
				)
			)
		},
		{
			field: 'poStatus',
			headerName: 'PO STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			flex: 1.5,
			type: 'enum',
			columnName: 'poStatus',
			tableName: 'PurchaseOrders',
			renderCell: (params) => (
				<Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
			)
		},
		{
			field: 'status',
			headerName: 'SO STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			flex: 1.5,
			type: 'enum',
			columnName: 'status',
			tableName: 'PurchaseOrders',
			renderCell: (params) => (
				params.value === "Completed" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor:params.value === 'Delivered'? '#128038':'#ffea88', color:params.value === 'Delivered' ?'#fff' :'#000' }} label={params.value} />
				)
			)
		},
		{ 
			field: 'totalAmount', 
			filterable: false, 
			valueFormatter: (params) => `₹${params.value}`, 
			headerName: 'TOTAL', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'createdBy', 
			headerName: 'CREATED BY', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'createdBy',
			tableName: 'PurchaseOrders',
		},
		{
			field: 'invoiceUrl',
			headerName: 'INVOICE',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			flex: 1,
			renderCell: (params) => ( <InvoiceUrlRenderCell {...params?.row} /> ),
			customRenderCell: InvoiceUrlRenderCell
		},
		{
			field: 'challanUrl',
			headerName: 'CHALLAN',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			flex: 1,
			renderCell: (params) => ( <ChallanUrlRenderCell {...params?.row} /> ),
			customRenderCell: ChallanUrlRenderCell
		}
	];

	return (
		<Fragment>
			<div>
				<Table
					columns={columns}
					dataPosition={"purchaseOrders"}
					endpoint={`/purchaseOrders/purchaseOrders`}
					rowId={'purchaseOrderName'}
					checkBoxSelection={false}
					version="version-1"
					service={'purchaseOrders'}
					tabName={'Purchase Orders'}
					navigateOnRowClickEndpoint={`/purchaseOrders`}
					setData={setPurchaseOrders}
					data={purchaseOrders}
					mobileResponsive={true}
					columnVisibilityModel={columnVisibilityModel}
					exportEndpoint={'/exports/fetchPurchaseOrders'}
					exportFileName={`Purchase Orders - ${new Date()}`}
					searchKey={'text'}
					clickable={true}
				/>
			</div>

			<div>
				<InvoiceDialog
					invoiceDialogOpen={invoiceDialogOpen}
					handleInvoiceDialogClose={handleInvoiceDialogClose}
					invoiceRowData={poRowData}
					setReRender={reRenderPo}
				/>
				<ChallanDialog
					challanDialogOpen={challanDialogOpen}
					handleChallanDialogClose={handleChallanDialogClose}
					challanRowData={poRowData}
					setReRender={reRenderPo}
				/>
			</div>
		</Fragment>
	);
};

export default PurchaseOrders;
