import React, { useCallback, useState } from "react";
import Classes from "../pages/home/settings/dashboard.module.css";
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Chip, Tooltip } from "@mui/material";
import axiosInstance from "../utility/axios-instance";
import _ from "lodash";
import { useSelector } from "react-redux";
import SnackBar from "./SnackBar";

const SettingsItem = ({ onClick, iconSrc, iconAlt, title, subTitle }) => {
  const [ec2Status, setEc2Status] = useState("unknown");
  const auth = useSelector((state) => state.auth);
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

  const fetchEc2Status = useCallback(_.debounce(() => {
    axiosInstance.get('/aws/getEc2Info')
      .then(response => {
        setEc2Status(response?.data?.data?.state);
        setSnackBar({ display: true, type: "success", message: "EC2 status refreshed!"})
      })
      .catch(error => {
        const errorMessage = error?.response?.data?.message || "There was an error fetching the EC2 status!";
        setSnackBar({ display: true, type: "error", message: errorMessage})
        console.error("There was an error fetching the EC2 status!", error);
      });
  }, 2000), []);

  const updateEc2Status = (status) => {
    if (window.confirm(`Are you sure you want to ${status} the EC2 instance?`)) {
      axiosInstance.post('/aws/updateEc2Status', {
        status: status
      })
        .then(response => {
          setSnackBar({ display: true, type: "success", message: "EC2 status updated!"})
        })
        .catch(error => {
          const errorMessage = error?.response?.data?.message || "There was an error updating the EC2 status!";
          setSnackBar({ display: true, type: "error", message: errorMessage})
          console.error("There was an error updating the EC2 status!", error);
        });
    }
  }
  return (
    <div className={Classes.Item} onClick={onClick}>
      <SnackBar snackBar={snackBar}  setSnackBar={setSnackBar} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "100%",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {React.isValidElement(iconSrc) ? (
            iconSrc
          ) : (
            <img
              className={`${Classes.BtnImage}`}
              style={{ width: "auto", height: "4rem" }}
              src={iconSrc}
              alt={iconAlt}
            />
          )}
        </div>
        {title === "AWS EC2" && auth?.user?.role === "super-admin" ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <span style={{ color: "#333" }}>EC2 Status</span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Chip
                  label={ec2Status}
                  size="small"
                  clickable
                  onClick={fetchEc2Status}
                />
                <Tooltip title="Refresh EC2 Status" placement="top">
                  <IconButton
                    onClick={fetchEc2Status}
                    size="small"
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
            >
              <Tooltip title="Start EC2" placement="top">
                <IconButton
                  onClick={() => updateEc2Status("start")}
                  disabled={ec2Status === "running" || ec2Status === "pending" ||  ec2Status === "stopping" ? true : false}
                  style={{ 
                    color:  ec2Status === "running" || ec2Status === "pending" ||  ec2Status === "stopping"
                    ? "#6c757d"
                    : "#4CAF50"
                  }}
                  size="small"
                >
                  <PlayArrowIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Stop EC2" placement="top">
                <IconButton
                  onClick={() => updateEc2Status("stop")}
                  style={{ 
                    color:  ec2Status === "stopped" || ec2Status === "stopping"
                      ? "#6c757d"
                      : "#F44336"
                  }}
                  size="small"
                  disabled={ec2Status === "stopped" || ec2Status === "stopping" ? true : false}
                >
                  <StopIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reboot EC2" placement="top">
                <IconButton
                  onClick={() => updateEc2Status("restart")}
                  style={{ 
                    color: ec2Status === "stopped" || ec2Status === "stopping"
                      ? "#6c757d"
                      : "#FFC107"
                  }}
                  size="small"
                  disabled={ec2Status === "stopped" || ec2Status === "stopping" ? true : false}
                >
                  <RestartAltIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ) : (
          <h4
            style={{
              fontSize: "1.25rem",
              width: "66.666%",
            }}
          >
            {title}
            <p
              style={{
                fontSize: "11px",
                marginTop: "10px",
              }}
            >
              {subTitle}
            </p>
          </h4>
        )}
      </div>
    </div>
  );
};

export default SettingsItem;