import React, { Fragment, useState, useEffect, forwardRef, SyntheticEvent } from "react";
import Classes from "../../createOrders/css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete, Switch, FormControlLabel, Chip, Avatar, Snackbar, Alert as MuiAlert } from '@mui/material';
import AutoCompleteValueComponent from '../../../../components/autoCompleteValue';
import Spinner from '../../../../components/spinner';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { CheckRoleBasedPermission, permission, services } from "../../../../utility/checkRoleBasedPermission";
import {
  AutoCompleteOptions,
  ContactPopUpProps,
  CustomerRoles,
  CustomerUpdatePayload,
  CustomerUpdateResponse,
  SnackBarState,
} from "@/types";
import { useAppSelector } from "../../../../hooks/hooks";
import { AxiosResponse } from "axios";

const Alert = forwardRef(function Alert(props: any, ref: React.Ref<HTMLDivElement>) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactPopUp: React.FC<ContactPopUpProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [salesTeam, setSalesTeam] = useState<AutoCompleteOptions>([]);
    const [salesUser, setSalesUser] = useState<string>('');
    const [preSalesUser, setPreSalesUser] = useState<string>('');
    const [states, setStates] = useState<string[]>([]);
    const [selectedState, setSelectedState] = useState<string>('');
    const [snackBar, setSnackBar] = useState<SnackBarState>({ display: false, type: "success", message: "default message!" });
    const auth = useAppSelector((state) => state.auth);
    const navigate = useNavigate();
    const role = auth?.user?.role;
    const {
        contactModal,
        hideContactPop,
        showCustomerProfile,
        setCustomerProfile,
        displayName,
    } = props;

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => { return { ...prevSnackBar, display: false } });
    };

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setStates(response.data.data);
            });
    }, []);

    useEffect(() => {
        axiosInstance
            .get('/admin/salesTeam')
            .then((res) => {
                const arr = res.data.data?.users.map((ele: any) => ({ label: ele?.name, Id: ele?._id }));
                setSalesTeam(arr);
            });
    }, []);

    const handleSubmit = (event: SyntheticEvent) => {
        setLoading(true);
        event.preventDefault();
        const target = event.target as typeof event.target & {
            first: { value: string };
            lastName: { value: string };
            email: { value: string };
            role: { value: string };
            customerType: { value: string };
            businessName: { value: string };
            phone: { value: string };
            isManuallyVerified: { checked: boolean };
        };

        const roles: { [key: string]: CustomerRoles | "remove" } = {
            "Retailer": "retailer",
            "Special Retailer": "special_retailer",
            "Interior": "interior",
            "Oem": "oem",
            "End User": "end_user",
            "No Role": "remove"
        };
        const data = {
            firstName: target.first.value,
            lastName: target.lastName.value || undefined,
            email: target.email.value || undefined,
            customerRole: roles[target.role.value] ?? 'remove',
            customerType: target.customerType.value,
            businessName: target.businessName.value || undefined,
            salesUser: salesTeam.find((salesPerson)=>{return salesPerson?.label == salesUser})?.Id || undefined,
            preSalesUser: salesTeam.find((salesPerson)=>{return salesPerson?.label == preSalesUser})?.Id || undefined,
            state: states.find((state)=>{
                return state  === selectedState
            }) || undefined,
            phone: target.phone.value ? `+91${target.phone.value}` : undefined,
            ...(target?.isManuallyVerified?.checked &&
            {
                isManuallyVerified: target?.isManuallyVerified?.checked || undefined
            }),
        };

        axiosInstance
          .post<
            CustomerUpdateResponse,
            AxiosResponse<CustomerUpdateResponse, CustomerUpdatePayload>,
            CustomerUpdatePayload
          >(`/user/admin/${showCustomerProfile?.id}`, data )
          .then((res) => {
            hideContactPop();
            setLoading(false);
            const updatedCustomerData = res.data.data;
            setCustomerProfile(updatedCustomerData);
            setSnackBar({
              display: true,
              message: "Customer Update Successfully",
              type: "success",
            });
          })
          .catch((error) => {
            const errorMessage = error?.response?.data?.message;
            setSnackBar({
              display: true,
              message: errorMessage,
              type: "error",
            });
            setLoading(false);
          });
    };

    const capitalName = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const roles = ["Retailer", "Special Retailer", "Interior", "Oem", "End User", "No Role"];

    const handleMinLength = (event: React.FocusEvent<HTMLInputElement>) => {
        const phoneno = /^\d{10}$/;
        if (event.target.value.match(phoneno)) {
            document.getElementById('phoneErrMsg')!.style.display = 'none';
            (document.getElementById('addCustomer') as HTMLButtonElement)!.disabled = false;
        } else {
            (document.getElementById('addCustomer') as HTMLButtonElement)!.disabled = true;
            document.getElementById('phoneErrMsg')!.style.display = 'block';
        }
    };

    return (
        <Fragment>
            {contactModal && (
                <div className={[Classes.modal, Classes.contactPopUp].join(" ")}>
                    <div className={Classes.BoxContent}>
                        <p>Update Contact Details</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id="form">
                        <div style={{ padding: "30px 20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ width: "30%" }}>
                                    <TextField
                                        required
                                        id='first'
                                        name='first'
                                        label='First Name'
                                        placeholder='Enter First Name'
                                        key={showCustomerProfile?.first_name}
                                        defaultValue={showCustomerProfile?.first_name}
                                        autoComplete='off'
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ width: "30%" }}>
                                    <TextField
                                        id='lastName'
                                        name='lastName'
                                        label='Last Name'
                                        placeholder='Enter Last Name'
                                        key={showCustomerProfile?.last_name}
                                        defaultValue={showCustomerProfile?.last_name}
                                        autoComplete='off'
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ width: "38%" }}>
                                    <TextField
                                        id='displayName'
                                        name='displayName'
                                        label='Display Name'
                                        placeholder='Enter Display Name'
                                        key={displayName}
                                        defaultValue={displayName}
                                        disabled
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <TextField
                                        id='email'
                                        name='email'
                                        label='Email'
                                        placeholder='Enter Email'
                                        key={showCustomerProfile?.email}
                                        defaultValue={showCustomerProfile?.email}
                                        sx={{ width: '100%' }}
                                    />
                                </div>

                                <div style={{ width: "32%" }}>
                                    <TextField
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        type='number'
                                        placeholder='Enter Your Phone No.'
                                        key={showCustomerProfile?.phone?.slice(3)}
                                        defaultValue={showCustomerProfile?.phone?.slice(3)}
                                        onInput={(e) => { (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 10) }}
                                        inputProps={{ maxLength: 10 }}
                                        onBlur={handleMinLength}
                                        autoComplete='off'
                                        disabled={role ? (['super-admin', 'admin'].includes(role) ? false : true ): true}
                                        sx={{ width: '100%' }}
                                        required
                                    />

                                    <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                        <p>Please enter a valid 10 digit no.</p>
                                    </div>
                                </div>

                                <div style={{ width: "33%" }}>
                                    <TextField
                                        id='businessName'
                                        name='businessName'
                                        label='Business Name'
                                        placeholder='Enter Business Name'
                                        key={showCustomerProfile?.business_name}
                                        defaultValue={showCustomerProfile?.business_name}
                                        autoComplete='off'
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <AutoCompleteValueComponent
                                        fieldName={'Customer Type'}
                                        name={'customerType'}
                                        options={["B2B", "B2C"]}
                                        value={showCustomerProfile?.customer_type}
                                    />
                                </div>

                                <div style={{ width: "32%" }}>
                                    <AutoCompleteValueComponent
                                        fieldName={'Customer Role'}
                                        name={'role'}
                                        options={roles}
                                        value={showCustomerProfile?.customer_role ? capitalName(showCustomerProfile?.customer_role) : ''}
                                    />
                                </div>

                                <div style={{ width: "33%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="salesUser"
                                        options={salesTeam?.map((sales_user)=>{ return sales_user?.label})}
                                        key={showCustomerProfile?.field_sales_user ? showCustomerProfile?.field_sales_user_name : ''}
                                        defaultValue={showCustomerProfile?.field_sales_user ? showCustomerProfile?.field_sales_user_name : ''}
                                        sx={{ width: '100%' }}
                                        onChange={(event, newValue) => { setSalesUser(newValue || '') }}
                                        renderInput={(params) => <TextField {...params} label="Sales User" />}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="preSalesUser"
                                        options={salesTeam?.map((sales_user)=>{ return sales_user?.label})}
                                        key={showCustomerProfile?.pre_sales_user ? showCustomerProfile?.pre_sales_user_name : ''}
                                        defaultValue={showCustomerProfile?.pre_sales_user ? showCustomerProfile?.pre_sales_user_name : ''}
                                        sx={{ width: '100%' }}
                                        onChange={(event, newValue) => { setPreSalesUser(newValue || '') }}
                                        renderInput={(params) => <TextField {...params} label="Pre-Sales User" />}
                                    />
                                </div>

                                <div style={{ width: '33%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="state"
                                        options={states}
                                        key={showCustomerProfile?.state ? showCustomerProfile?.state : ''}
                                        defaultValue={showCustomerProfile?.state ? showCustomerProfile?.state : ''}
                                        ListboxProps={{ sx: { fontSize: 14 } }}
                                        sx={{ width: '100%' }}
                                        onChange={(event, newValue) => { setSelectedState(newValue || '') }}
                                        renderInput={(params) => <TextField {...params} label="States" />}
                                    />
                                </div>

                                <div style={{ width: "32%" }}>
                                    <TextField
                                        id='customerCode'
                                        name='customerCode'
                                        label='Customer Code'
                                        placeholder="Your code will be generate after selecting state"
                                        key={showCustomerProfile?.customer_code}
                                        defaultValue={showCustomerProfile?.customer_code}
                                        disabled
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    {
                                        showCustomerProfile?.manually_verified_by
                                            ? <div>
                                                <div>
                                                    Manually Verified
                                                    <VerifiedUserIcon />
                                                </div>
                                                <div>
                                                    <Chip
                                                        label={`By ${showCustomerProfile?.manually_verified_by_name}`}
                                                        avatar={
                                                            <Avatar>{
                                                                showCustomerProfile?.manually_verified_by_name?.slice(0, 1).toUpperCase()
                                                            }
                                                            </Avatar>
                                                        }
                                                        component="a"
                                                        onClick={() => {
                                                            CheckRoleBasedPermission(auth?.user, services.users, permission.read) &&
                                                                navigate(`/users/${showCustomerProfile?.manually_verified_by}`)
                                                        }}
                                                        clickable
                                                    />
                                                </div>
                                            </div>
                                            : <FormControlLabel
                                                control={
                                                    <Switch
                                                        name='isManuallyVerified'
                                                        inputProps={{
                                                            'aria-label': 'Color switch demo'
                                                        }}
                                                        onClick={(e) => {
                                                            if (
                                                                (e.target as HTMLInputElement).checked
                                                            ) {
                                                                const isConfirmed = window.confirm('Are you sure you want to manually verify this customer?')
                                                                if (isConfirmed) {
                                                                    (e.target as HTMLInputElement).checked = true;
                                                                } else {
                                                                    (e.target as HTMLInputElement).checked = false;
                                                                }
                                                            } else {
                                                                (e.target as HTMLInputElement).checked = false;
                                                            }
                                                        }}
                                                        value={showCustomerProfile?.manually_verified_by ? true : false}
                                                    />
                                                }
                                                label="Manually Verify"
                                            />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{ bottom: '13%' }}>
                            <button
                                type="button"
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideContactPop}
                            >
                                CANCEL
                            </button>

                            <button
                                id='addCustomer'
                                type="submit"
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>
                    </form>
                </div>
            )}
            <Snackbar open={snackBar?.display} autoHideDuration={1500} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            {loading ? <Spinner /> : null}
        </Fragment>
    );
};

export default ContactPopUp;
