import { useEffect, useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from '../../../utility/axios-instance';
import Table from 'react-bootstrap/Table';
import { Paper, InputAdornment, TextField, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import resetState from '../../../store/actions';
import { BsFillDashCircleFill } from "react-icons/bs";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PublishIcon from '@mui/icons-material/Publish';

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const CreateNewUser = (props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [states, setStates] = useState([]);
	const [locationCodes, setLocationCode] = useState([]);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [locations, setLocations] = useState([]);
	const [userRole, setUserRole] = useState('');
	const [franchise, setFranchise] = useState(null);
	const [franchises, setFranchises] = useState([]);

	const {
		isPinnedTab,
        setIsPinnedTab
	} = props;

	// add permission to the services
	const handlePermissionCheck = (event, service) => {
		const name = event.target.name;
		const isChecked = event.target.checked;

		let newServices = services.map(item => {
			if (item.service === service) {
				isChecked && item.permissions.push(name);
				!isChecked && (item.permissions = item.permissions.filter(perm => perm !== name))
			}
			return item
		})
		setServices(newServices)
	}

	const [rolesOption, setRolesOption] = useState([]);
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const createRole = async (roleName) => {
		const payload = {
			roleName: roleName
		}
		await axios
			.post(`/roles/createRole`, payload)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	const [newRole, setNewRole] = useState('');
	const handleAddRole = async (e) => {
		e.preventDefault();
		if (newRole !== '') {
			await createRole(newRole);
		}
		setNewRole('');
	};

	const createService = async (serviceName) => {
		const payload = {
			serviceName: serviceName
		}
		await axios
			.post(`/admin/createService`, payload)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	const fetchServices = async () => {
		await axios
			.get(`/admin/fetchServices`)
			.then((res) => {
				const fetchedServices = res?.data?.data
				setServicesOption([...fetchedServices])
				return res.data
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	const fetchRoles = async () => {
		await axios
			.get('/roles/')
			.then((res) => {
				const fetchedRoles = res?.data?.data
				setRolesOption([...fetchedRoles]);
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	// select services or create new service
	const [servicesOption, setServicesOption] = useState([]);
	const [selectedService, setSelectedService] = useState('');
	const [newService, setNewService] = useState('');
	const [services, setServices] = useState([]);

	useEffect(() => {
		const fetchedServices = fetchServices()
		const fetchedRoles = fetchRoles()
	}, [newService, newRole])

	// after selecting role
	const handleSelectRole = (e, value) => {
		let role = value;
		setUserRole(role);
		axios
			.get(`/roles/${role}`)
			.then((res) => {
				setServices(res.data.data.permissions);
			})
			.catch((err) => {
				setError(err.response.data.message);
				if (error.response.status === 401) {
					setTimeout(() => {
						resetState(dispatch);
					}, 1000);
				}
			})

	}

	//add new services
	const handleAddService = async (e) => {
		e.preventDefault();


		if (newService !== '' && !servicesOption.includes(newService)) {
			let opts = {
				service: newService,
				permissions: []
			}
			setServices([...services, opts])
			await createService(newService)
		}

		if (servicesOption.includes(newService)) {
			setError(`${newService} service already exist!`)
		}

		setNewService('')
	};

	const handleSelectedServices = (e, value) => {
		let selectedService = value;
		let isServiceExist = services.find(item => item.service === selectedService)
		let opts = {
			service: selectedService,
			permissions: []
		}
		!isServiceExist && setServices([...services, opts])
	}

	const handleRemoveService = (service) => {
		let newServices = services.filter(item => item.service !== service);
		setServices(newServices);
	}
	// onSubmit handler

	const createUser = async (e) => {
		e.preventDefault();
		setLoading(true);
		const { username, name, role, password, phone, location } = e.target.elements;
		let locationArr = locations.map(item => item.locationCode)
		let checkAnyPermissionIsEmpty = services.find(item => item.permissions.length === 0);
		if (
			checkAnyPermissionIsEmpty || (services.length === 0 && !role.value.includes('admin', 'super-admin'))
		) {
			const errorMessage = "Permission empty not allowed, Please select anyone"
			setSnackBar((prevSnackBar) => {
				return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
			});
			setLoading(false)
		}
		else {
			axios
				.post('/admin/new', {
					username: username.value,
					name: name.value,
					role: role.value,
					phone: `+91${phone?.value}`,
					services: services,
					password: password.value,
					defaultLocation: location.value,
					locationCodes: locationArr.length !== 0 ? locationArr : undefined,
					franchise: franchise?.id
				})
				.then((res) => {
					const successMessage = 'User created successfully'
					setSnackBar((prevSnackBar) => {
						return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
					});
					setTimeout(() => {
						navigate(-1);
					}, 1000);
				})
				.catch((err) => {
					const errorMessage = err?.response?.data?.message
					setSnackBar((prevSnackBar) => {
						return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
					});
					if (err.response.status === 401) {
						setTimeout(() => {
							resetState(dispatch);
						}, 1000);
					}
				})
				.finally(() => {
					setLoading(false);
				})
		}
	};
	const handleSnackBarClose = () => {
		setSnackBar((prevSnackBar) => {
			return { ...prevSnackBar, display: false }
		});
	};

	useEffect(() => {
		axios
			.get('/pincode/getAllStates')
			.then((response) => {
				setStates(response?.data?.data)
			})

		axios
			.get(`/location/locations`)
			.then((response) => {
				setLocationCode(response?.data?.data)
			})

		axios
			.get(`/pos/fetchFranchises`)
			.then((response) => {
				setFranchises(response?.data?.data?.fetchedFranchises)
			})
	}, [])

	const handleNumChange = event => {
		const limit = 10;
		setPhoneNumber(event.target.value.slice(0, limit));
	};

	const handleMinLength = (event) => {
		if (event.target.value) {
			const phoneno = /^\d{10}$/;
			if (!event.target.value.match(phoneno)) {
				alert("Please enter 10 digit phone number")
				setPhoneNumber('');
			}
		}
	}

	return (
		<div 
			className="d-flex flex-column" 
			style={{marginTop:10,borderRadius:8,boxShadow: "0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)",padding:20}}
		>
			<p style={{ background: "linear-gradient(to right, #ff6200, white)",paddingLeft: 20,borderRadius: 26,fontSize: 15,padding: "5px 15px",color: "#fff",margin:0,marginTop:20 }}>Create New User</p>
			<div className="d-flex justify-content-center align-items-center flex-grow-1 mt-3">
				<form
					className="d-flex flex-column"
					style={{ gridGap: '1rem', width: '100%',marginTop:10 }}
					onSubmit={createUser}
				>
					<Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
						<Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
							{snackBar?.message}
						</Alert>
					</Snackbar>
					<div style={{ display: 'flex', gap: '5px' }} >
						<TextField
							id="username"
							required
							name="username"
							type="text"
							label="Username"
							placeholder='Enter Username'
							autoComplete='off'
							size='small'
							sx={{ width: '50%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PersonIcon />
									</InputAdornment>
								),
							}}
						/>
						<TextField
							required
							id="name"
							name="name"
							type="text"
							label="Name"
							placeholder='Enter Name'
							size='small'
							autoComplete='off'
							sx={{ width: '50%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
						/>
					</div>

					<div style={{ display: 'flex', gap: '5px',marginTop:10 }} >
						<Autocomplete
							disablePortal
							disableClearable
							id="location" name="location"
							options={states}
							sx={{ width: '50%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							size='small'
							renderInput={(params) => <TextField {...params} label="State" />}
						/>
						
								<Autocomplete
									disablePortal
									disableClearable
									id="locationCode" name="locationCode"
									multiple
									onChange={(e, value) => setLocations(value)}
									options={locationCodes}
									getOptionLabel={(location) => `${location.locationCode} - ${location.locationName}`}
									sx={{ width: '50%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
									size='small'
									renderInput={(params) => <TextField  {...params} label="Location Code" />}
								/>

					</div>

					<div style={{ display: 'flex', gap: '10px',marginTop:10 }}>
						<Autocomplete
							disablePortal
							disableClearable
							id="role" 
							name="role" 
							onChange={handleSelectRole}
							options={rolesOption}
							sx={{ width: '65%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							size='small'
							renderInput={(params) => <TextField {...params} label="Role" required />}
						/>
						<div style={{ display: 'flex', width: '35%' }}>
							<TextField
								sx={{ width: '100%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
								size='small'
								label="Add New Role" type="text" value={newRole} onChange={(e) => setNewRole(e.target.value)} InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleAddRole} >
												<AddCircleIcon />
											</IconButton>
										</InputAdornment>
									),
								}} />
						</div>
					</div>

					<div style={{ display: 'flex', gap: '10px',marginTop:10 }}>
						<Autocomplete
							disablePortal
							disableClearable
							id="services" 
							name="services" 
							onChange={handleSelectedServices}
							options={servicesOption.filter((item) => { if (item) return item; return false })}
							sx={{ width: '65%', '& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							size='small'
							renderInput={(params) => <TextField {...params} label="Services"  />}
						/>
						<div style={{ display: 'flex', width: '35%' }}>
							<TextField label="Add New Service" type="text"
								sx={{ width: '100%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
								size='small'
								value={newService} onChange={(e) => setNewService(e.target.value)} InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleAddService} >
												<AddCircleIcon />
											</IconButton>
										</InputAdornment>
									),
								}} />
						</div>
					</div>

					{services.length !== 0 ?
						<Table striped bordered hover>
							<thead style={{ backgroundColor: '#243750', color: 'white', fontSize: '16px' }}>
								<tr>
									<th>Services</th>
									<th>Permissions</th>
									<th>Remove</th>
								</tr>
							</thead>
							<tbody>
								{services.map((service, index) => (
									<tr key={index}>
										<td>{service?.service?.toUpperCase()}</td>
										<td>
											<label style={{ marginRight: 25 }}>
												<input
													style={space}
													type="checkbox"
													name="create"
													checked={service.permissions.includes("create")}
													onChange={(e) => handlePermissionCheck(e, service.service)}
												/>
												Create
											</label>
											<label style={{ marginRight: 25 }}>
												<input
													style={space}
													type="checkbox"
													name="read"
													checked={service.permissions.includes("read")}
													onChange={(e) => handlePermissionCheck(e, service.service)}
												/>
												Read
											</label>
											<label style={{ marginRight: 25 }}>
												<input
													style={space}
													type="checkbox"
													name="update"
													checked={service.permissions.includes("update")}
													onChange={(e) => handlePermissionCheck(e, service.service)}
												/>
												Update
											</label>
											<label style={{ marginRight: 25 }}>
												<input
													style={space}
													type="checkbox"
													name="delete"
													checked={service.permissions.includes("delete")}
													onChange={(e) => handlePermissionCheck(e, service.service)}
												/>
												Delete
											</label>
										</td>
										<td><BsFillDashCircleFill
											style={{ color: 'red', cursor: 'pointer' }}
											onClick={(e) => handleRemoveService(service.service)}
										/></td>
									</tr>
								))}
							</tbody>
						</Table>
						: null}

					<div style={{ display: 'flex', width: '100%',marginTop:10 }} >
						<TextField
							sx={{ width: '100%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							size='small'
							label="Password"
							type={showPassword ? 'text' : 'password'}
							id="password"
							password="password"
							placeholder="Password"
							required
							minLength={6}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleClickShowPassword}>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}} />
					</div>

					<p style={{ background: "linear-gradient(to right, #ff6200, white)",paddingLeft: 20,borderRadius: 26,fontSize: 15,padding: "5px 15px",color: "#fff",margin:0,marginTop:20 }}>Contact</p>

					<div style={{ display: 'flex', width: '100%',marginTop:10 }}>
						<TextField 
							required
							id='phoneNumber'
							name="phone"
							type="number"
							label="Phone Number"
							maxLength={10}
							onBlur={(e) => handleMinLength(e)}
							onChange={handleNumChange}
							placeholder='Enter Your 10 Digit Phone Number'
							value={phoneNumber ? phoneNumber : ''}
							autoComplete='off'
							sx={{ width: '50%','& .MuiOutlinedInput-root': {borderRadius:'26px'} }}
							size='small'
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PhoneIcon />
									</InputAdornment>
								)
							}}
						/>
					</div>
					<div style={{ display: 'flex',margin: '0px 5px',justifyContent:'flex-end',marginTop:20 }}>
						<Button
							type="submit"
							variant="contained"
							style={{ backgroundColor: '#ff6200' }}
							// sx={{ width: '50%' }}
							// startIcon={<PublishIcon />}
						>
							{loading ? (
								<span
									className="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
								></span>
							) : (
								'Submit'
							)}
						</Button>
					</div>
				</form>
			</div>

		</div>
	);
};

export default CreateNewUser;


const space = {
	margin: 'auto 5px'
}
const serStyle = {
	padding: '5px 10px',
	margin: '5px',
	backgroundColor: '#ff6200',
	borderRadius: '8px'
}

