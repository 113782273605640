import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { TextField, Autocomplete, Typography } from '@mui/material';
import Table from '../../../components/table/table';
import Button from '@mui/material/Button';
import Dialog from '../../../components/dialog';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import CreateBinPopUp from './createBinPopup';
import { ImportFileComponent } from '../../../components/importFileComponent';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import FormDialog from '../../../components/FormDialog/FormDialog';
import Classes from '../customers/css/customer.module.css';
import { useSelector } from 'react-redux';
import { setBins } from '../../../store/binsSlice';

const Bins = () => {

    const DefaultRenderCell = (row) => {
        return (
            row?.default ? (
                <BsCheckCircleFill color="green" />
            ) : (
                <BsXCircleFill color="red" />
            )
        )
    }

    const UpdateRenderCell = (row) => {
        return (
            <Permission service={serviceName} permission={"update"}>
                <Button 
                    size='small' 
                    color='success' 
                    variant="contained" 
                    onClick={(e) => openUpdateDialog(row)}
                >Update</Button>
            </Permission>
        )
    }

    const DeleteRenderCell = (row) => {
        return (
            <Permission service={serviceName} permission={"delete"}>
                <FormDialog
                    dialogTitle="Confirm Delete Bin"
                    dialogActionType="confirmation"
                    openDialogButtonName="Delete"
                    submitDialogButtonName="Confirm"
                    onConfirmation={() => { handleDeleteBin(row.bin_id, row?.locationCode) }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        This action would permanently Delete '{row?.code}' Bin ?
                    </Typography>
                </FormDialog>
            </Permission>
        )
    }

    const columns = [
        {
            field: 'code',
            headerName: 'Bin Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'type',
            headerName: 'Bin Type',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qty',
            headerName: 'Quantity',
            type: 'number',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'default',
            headerName: 'Default',
            flex: 1,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => ( <DefaultRenderCell {...params?.row} />),
            customRenderCell: DefaultRenderCell
        },
        {
            field: 'update',
            headerName: 'Update',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <UpdateRenderCell {...params?.row} />),
            customRenderCell: UpdateRenderCell
        },
        {
            field: 'delete',
            headerName: 'Delete',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <DeleteRenderCell {...params?.row} /> ),
            customRenderCell: DeleteRenderCell
        },
    ];
    const [locations, setLocations] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [locationCode, setLocationCode] = useState('');
    const [reRender, setReRender] = useState(false);
    const [update, setUpdate] = useState(false);
    const [binCode, setBinCode] = useState('');
    const [binType, setBinType] = useState('');
    const [binQty, setBinQty] = useState('');
    const [binStatus, setBinStatus] = useState('');
    const [binId, setBinId] = useState(null);
    const [binDefault, setBinDefault] = useState('');
    const serviceName = services?.bin;

    const bins = useSelector((state) => state.bins);

    const showCreatePopup = () => {
        setShowPopup(true)
    }

    const hideCreatePopup = () => {
        setShowPopup(false)
    }

    const openUpdateDialog = (bin) => {
        setBinId(bin?.bin_id);
        setBinCode(bin?.code);
        setBinType(bin?.type);
        setBinQty(bin?.qty);
        setBinStatus(bin?.status);
        setLocationCode(bin?.locationCode);
        setBinDefault(bin?.default.toString());
        setUpdate(true);
    }

    const closeUpdateDialog = () => {
        setBinCode('');
        setBinType('');
        setBinQty('');
        setBinStatus('');
        setLocationCode('');
        setBinDefault('');
        setUpdate(false);
    }

    const fetchLocation = () => {
        axiosInstance.get('/location/locations?text=')
            .then(res => {
                const locations = [];
                if (res.data.data.length !== 0) {
                    for (let location of res?.data?.data) {
                        locations.push(location?.locationCode)
                    }
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        fetchLocation()
    }, [])

    // const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const updateFields = [
        <TextField
            label={'Bin Code'}
            placeholder={'Enter Bin Code'}
            required={true}
            id='code'
            name={'code'}
            defaultValue={binCode}
            autoComplete='off'
            sx={{ width: '100%' }}
        />,

        <Autocomplete
            freeSolo
            disablePortal
            id="status"
            name="status"
            options={["Active", "In-Active", "Dis-Continued"]}
            sx={{ width: '100%' }}
            defaultValue={binStatus}
            renderInput={(params) =>
                <TextField {...params} label={"Status"} required={true} />
            }
        />,
        <Autocomplete
            freeSolo
            disablePortal
            id="type"
            name="type"
            options={["Saleable", "Rejected", "QC", "Packing", "Returned", "Staging"]}
            sx={{ width: '100%' }}
            renderInput={(params) =>
                <TextField {...params} label={"Bin Type"} required={true} />
            }
            defaultValue={binType}
        />,
        <Autocomplete
            freeSolo
            disablePortal
            id="location"
            name="location"
            options={locations}
            sx={{ width: '100%' }}
            renderInput={(params) =>
                <TextField {...params} label={"Locations"} required={true} onChange={(event) => setLocationCode(event.target.value)} />
            }
            defaultValue={locationCode}
        />,
        <Autocomplete
            disablePortal
            id="default"
            name="default"
            options={['true', 'false']}
            sx={{ width: '100%' }}
            renderInput={(params) =>
                <TextField {...params} label={"Default"} />
            }
            defaultValue={binDefault}
        />
    ]


    const handleDeleteBin = (binId, locCode) => {
        axiosInstance
            .delete(`/inventory/deleteBin/${binId}?locationCode=${locCode}`)
            .then(result => {
                setReRender(!reRender)
            })
            .catch(error => console.log('error', error))
    }


    return (
        <div>
            <div style={{ display:'flex',justifyContent:'space-between',marginBottom:15,flexWrap:'wrap',gap:25 }}>
                <div>
                    <Permission service={serviceName} permission={"create"}>
                        <Button
                            variant="contained"
                            onClick={showCreatePopup}
                            style={{ textTransform: 'capitalize',marginRight:10 }}
                        >
                            Create Bin
                        </Button>
                    </Permission>
                </div>

                <Permission service={serviceName} permission={"create"}>
                    <div style={{marginTop:-25}}>
                        <p>Bin Create/ Update via CSV will override existing values</p>
                        <ImportFileComponent
                            endpoint="/inventory/createBinCsv"
                            serviceName={services?.inventory}
                            type="bin"
                            templateDownloadEndpoint={"/templates/binCsv"}
                            setReRender={setReRender}
                        />
                    </div>
                </Permission>

            </div>

            <Table
                columns={columns}
                dataPosition={"fetchedBins"}
                endpoint={`/inventory/fetchBins`}
                rowId={'bin_id'}
                checkBoxSelection={false}
                reRender={reRender}
                service={'bins'}
                tabName={'Bins'}
                setData={setBins}
                data={bins}
                mobileResponsive={true}
                exportEndpoint={'/exports/fetchBins'}
                exportFileName={`Bins Report- ${new Date()}`}
            />

            <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
                <CreateBinPopUp
                    showPopup={showPopup}
                    hideCreatePopup={hideCreatePopup}
                    locations={locations}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={update ? Classes.UpdateSalesDialog : undefined}>
                <Dialog
                    open={update}
                    closeDialog={closeUpdateDialog}
                    headerName={'Update Bin'}
                    fields={updateFields}
                    endPoint={`/inventory/updateBin/${binId}?locationCode=${locationCode}`}
                    setReRender={setReRender}
                    type={'update'}
                    reRender={reRender}
                />
            </div>
        </div>
    )
}

export default Bins